import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  addOrderForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  showLoader: boolean = true;
  param2;
  pdfurl;
  data: any = [];
  users: any = [];
  modalRef: BsModalRef;
  loading: boolean = false;
  emptyData: boolean = false;
  response;
  p: number = 1;
  total: number;
  search: string = "";
  @ViewChild('myDiv3') myDiv3: ElementRef;
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  get r() { return this.addOrderForm.controls; }

  ngOnInit() {

    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      console.log('in null');
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addOrderForm = this.formBuilder.group({
      user_id: ['', Validators.required],
      order_total: ['', Validators.required],
      payment_type: ['', Validators.required],
      order_status: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.ordersList(1);
    this.getallusers();
  }
  ordersList(page: number) {
    this.showLoader = true;
    this.authenticationService.ordersList().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
        if (response['orderList'] !== {}) {
          this.data = response['orderList'].data;
          this.total = response['orderList'].total;
          this.response = response['orderList'];
          this.p = page;
          console.log(this.data);

        }
        //}
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }
  getallusers() {
    this.authenticationService.getallusers().subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['userList'] !== {}) {
          this.users = response['userList'];
          console.log(this.users);

        }
        //}
      }, error => {
        console.log(error);
      });
  }

  deleteOrder(id) {
    if (confirm("Are you sure you want to delete Order entry")) {
      this.authenticationService.deleteOrder(id).subscribe(
        response => {
          //this.getUsers();
          if (response['status'] == 200) {
            console.log('success');
            this.ordersList(1);
            setTimeout(() => this.toastr.success('Order deleted successfully!'))
          }
        }, error => {
          console.log('errorrrrr');

          setTimeout(() => this.toastr.success('Something went wrong'))
        });
    }
  }
  logout() {
    console.log('inlogout');
    this.nav.hide();
    this.authenticationService.logout();
  }

  addOrder() {
    this.submitted = true;
    if (this.addOrderForm.invalid) {
      return false;
    }
    var data = this.addOrderForm.value;
    //return false;
    var myPostObject = {
      user_id: data.user_id,
      order_total: data.order_total,
      payment_type: data.payment_type,
      order_status: data.order_status,
      description: data.description
    }
    this.authenticationService.addOrder(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //console.log(data);
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Order added successfully!'))
          this.addOrderForm.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.ordersList(1);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['orderList'] !== {}) {
          this.data = response['orderList'].data;
          this.total = response['orderList'].total;
          this.p = page;
          console.log(this.data);

        }
      }, error => {
        console.log(error);
      });
  }

  /************************************On change *********************************/

  onChange(event, index, status, id) {
    this.showLoader = true;
    //this.data[index].hide_search = event == true?'1':'0';
    let StatusData = { 'id': id, 'status1': status, 'status': event ? 'complete' : 'cart' };
    this.authenticationService.updateOrderStatus(StatusData).subscribe(
      response => {
        this.showLoader = false;
        setTimeout(() => this.toastr.success('Chip Status updated successfully'))
      }, error => {
        console.log(error);
      });
  }

  /**************************************End *******************************************/

  openpdf(orderid) {
    this.showLoader = true;
    this.authenticationService.openPdfInvoice(orderid).subscribe(
      response => {
        if (response !== {}) {
          this.pdfurl = response['data'][0].fileurl;
          console.log(response['data'][0].fileurl);
          setTimeout(() => {
            let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
            el3.click();
            this.showLoader = false;
            console.log('inside');

          }, 1000);
        } else {
          this.showLoader = false;
          //this.emptydocs = true;
        }
      }, error => {
        console.log(error);
      });
  }



  updateFilter() {
    if (this.search.trim() == "") {
      this.ordersList(1);

    } else {
      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchorder";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          this.showLoader = false;
          if (response['orderList'] !== {}) {
            this.data = response['orderList'].data;
            this.total = response['orderList'].total;
            this.response = response['orderList'];
            this.p = 0;

          }
        }, error => {
          console.log(error);
        });
    }
  }
}
