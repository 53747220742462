import { Component, OnInit,TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-sitesettings',
  templateUrl: './sitesettings.component.html',
  styleUrls: ['./sitesettings.component.scss']
})
export class SitesettingsComponent implements OnInit {
  
  sitesettingsForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  data :any = [];
  modalRef: BsModalRef;
  constructor(private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ backdrop: 'static', keyboard: false });
}

  get r() { return this.sitesettingsForm.controls; }
  
  ngOnInit() {
    
    this.param2= localStorage.getItem("currentUser");
    
    if(this.param2 == null){
      console.log('in null');
      this.router.navigate(['/login']);
    }else{
      this.nav.show();
    }
    
    this.sitesettingsForm= this.formBuilder.group({
      order_email: ['', Validators.required],
      vat: ['', Validators.required],
      basic_price: ['', Validators.required],
      ownership_change_price: ['', Validators.required],
      reg_price: ['', Validators.required],
      gold_price: ['', Validators.required],
      cert_price: ['', Validators.required],

      late_fee: ['', Validators.required],
      contact_email: ['', Validators.required],
      date_format: ['', Validators.required],
      
    });
    this.sitesettinglist();
  }
  sitesettinglist() { 
    this.authenticationService.sitesettinglist().subscribe(
      response => {
        if (response['status'] == 200) { 
          if (response['siteSettingList'] !== {}) {
            this.data = response['siteSettingList'];
            this.sitesettingsForm = this.formBuilder.group({
              order_email: [this.data[0].order_email, Validators.required],
              vat: [this.data[0].vat, Validators.required],
              basic_price: [this.data[0].basic_price, Validators.required],
              ownership_change_price: [this.data[0].ownership_change_price, Validators.required],
              reg_price: [this.data[0].reg_price, Validators.required],
              late_fee: [this.data[0].late_fee, Validators.required],

              cert_price: [this.data[0].cert_price, Validators.required],
              gold_price: [this.data[0].gold_price, Validators.required],
              contact_email: [this.data[0].contact_email, Validators.required],
              date_format: [this.data[0].date_time_format, Validators.required],
            });
            
          }
        }
      }, error => {
        console.log(error);
      });
  }

  logout(){
    console.log('inlogout');
    this.nav.hide();
    this.authenticationService.logout();
  } 
  
  saveData(){
    this.submitted = true;
    if (this.sitesettingsForm.invalid) {
      return false;
    }
    var data = this.sitesettingsForm.value;
    console.log(data);
    var myPostObject = {
      
      order_email: data.order_email,
      vat: data.vat,
      gold_price: data.gold_price,
      cert_price: data.cert_price,
      basic_price: data.basic_price,
      ownership_change_price: data.ownership_change_price,
      reg_price: data.reg_price,
      late_fee: data.late_fee,
      contact_email: data.contact_email,
      date_time_format:data.date_format,
     // last_chip_no:data.description,
    

    } 
    console.log(myPostObject);
      
  this.authenticationService.updateSiteSettings(myPostObject)
  .pipe(first())
  .subscribe(
      data => {        
          //console.log(data);
          //this.saveNotification.reset
          localStorage.setItem('date_time_format', JSON.stringify(myPostObject.date_time_format));
          setTimeout(() => this.toastr.success('Site Settings successfully!'))
          // this.sitesettingsForm.reset();
          // this.submitted = false;
      },
      error => {
        setTimeout(() => this.toastr.error('Something went wrong!'))
        //   this.error = error;
        //   this.loading = false;
      });    
  }  


}

