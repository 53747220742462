import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const now = new Date();
@Component({
  selector: 'app-discountcodes',
  templateUrl: './discountcodes.component.html',
  styleUrls: ['./discountcodes.component.scss']
})
export class DiscountcodesComponent implements OnInit {
  addDiscount: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  emptyData: boolean = false;
  param2;
  data: any = [];
  modalRef: BsModalRef;
  pagetitle;
  expiry_date;
  dueDate_array;
  loading: boolean = false;
  checkAction: string = 'add';
  showLoader: boolean = true;
  adddiscount: string = 'Add Discount';

  discounttoupdate;

  response;
  p: number = 1;
  total: number;

  dateFormat;
  search: string = "";
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  get r() { return this.addDiscount.controls; }
  createdDate: NgbDateStruct = {
    year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()
  };
  ngOnInit() {


    this.pagetitle = 'Add Discount code';
    this.param2 = localStorage.getItem("currentUser");
    this.dateFormat = localStorage.getItem("date_time_format");
    this.dateFormat = this.dateFormat.replace(/^"(.*)"$/, '$1');
    if (this.param2 == null) {
      console.log('in null');
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addDiscount = this.formBuilder.group({
      code: ['', Validators.required],
      value: ['', Validators.required],
      currency: ['', Validators.required],
      type: ['', Validators.required],
      expiry_date: ['', Validators.required],
      single_use: ['', Validators.required],
    });
    this.discountlist(1);
  }

  /**********************Edit Code ******************************************/

  getSingleDiscount(id) {
    this.pagetitle = "Update Discount"
    //console.log(id);
    this.authenticationService.getSingleDiscount(id).subscribe(
      response => {

        console.log(response['discont']);
        if (response['discont'] !== {}) {
          this.checkAction = 'update';
          console.log(response['discont']);
          let sdata = response['discont'];
          console.log(sdata);
          this.adddiscount = 'Update Discount';
          this.dueDate_array = sdata.expiry_date.split('-');
          let dyear = this.dueDate_array[0];
          let dmonth = this.dueDate_array[1];
          let ddate = this.dueDate_array[2];
          this.expiry_date = { year: parseInt(dyear), month: parseInt(dmonth), day: parseInt(ddate) };
          this.addDiscount = this.formBuilder.group({
            code: [sdata.code, Validators.required],
            value: [sdata.value, Validators.required],
            currency: [sdata.currency, Validators.required],
            type: [sdata.type, Validators.required],



            expiry_date: [this.expiry_date, Validators.required],
            single_use: [sdata.single_use, Validators.required],

          });

          this.discounttoupdate = id;
          //this.shippinglist(1);
          //setTimeout(() => this.toastr.success('shipping fetched successfully!'))
        }
      }, error => {
        setTimeout(() => this.toastr.success('Something went wrong'))
      });
  }



  /****************************Edit Code End Here *******************************/





  discountlist(page: number) {
    this.showLoader = true;
    this.authenticationService.discountlist().subscribe(
      response => {
        this.showLoader = false;
        if (response['discountList'] !== {}) {
          this.data = response['discountList'].data;
          this.total = response['discountList'].total;
          this.response = response['discountList'];
          this.p = page;
          console.log(this.data);
        }
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }
  deleteDiscount(id) {
    if (confirm("Are you sure you want to delete discount code")) {
      this.authenticationService.deleteDiscount(id).subscribe(
        response => {
          //this.getUsers();
          if (response['status'] == 200) {
            console.log('success');
            this.discountlist(1);
            setTimeout(() => this.toastr.success('discount code deleted successfully!'))
          }
        }, error => {
          console.log('errorrrrr');

          setTimeout(() => this.toastr.success('Something went wrong'))
        });
    }
  }
  addnewDiscount() {
    console.log("hunny" + this.checkAction);
    this.submitted = true;
    if (this.addDiscount.invalid) {
      return false;
    }
    var data = this.addDiscount.value;
    let anouncementDate = data.expiry_date.year + '-' + data.expiry_date.month + '-' + data.expiry_date.day;
    var myPostObject = {
      code: data.code,
      value: data.value,
      type: data.type,
      expiry_date: anouncementDate,
      single_use: data.single_use,
      is_deleted: data.is_deleted,
      currency: data.currency,
    }
    if (this.checkAction == 'add') {
      this.authenticationService.addDiscountCode(myPostObject)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            //this.saveNotification.reset
            setTimeout(() => this.toastr.success('Discount added successfully!'))
            this.addDiscount.reset();
            this.submitted = false;
            this.discountlist(1);
            this.modalRef.hide();
            this.router.navigate(['/admin/discountcodes']);

          },
          error => {
            setTimeout(() => this.toastr.error('Something went wrong!'))
            //   this.error = error;
            //   this.loading = false;
          });
    } else {
      this.authenticationService.updateDiscount(myPostObject, this.discounttoupdate)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('Discount updated successfully!'))
            this.addDiscount.reset();
            this.submitted = false;
            this.discountlist(1);
            this.modalRef.hide();
          },
          error => {
            setTimeout(() => this.toastr.error('Something went wrong!'))
          });


    }
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['discountList'] !== {}) {
          this.data = response['discountList'].data;
          this.total = response['discountList'].total;
          this.p = page;
          console.log(this.data);

        }
      }, error => {
        console.log(error);
      });
  }
  closePopup(modalRef) {
    this.addDiscount.reset();
    this.submitted = false;
    modalRef.hide();
  }
  updateFilter() {
    if (this.search.trim() == "") {
      this.discountlist(1);

    } else {
      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchdiscount";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          this.showLoader = false;
          if (response['discountList'] !== {}) {
            this.data = response['discountList'].data;
            this.total = response['discountList'].total;
            this.response = response['discountList'];
            this.p = 0;

          }
        }, error => {
          console.log(error);
        });
    }
  }
}

