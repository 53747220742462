import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AuthenticationService } from '../services/authentication.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  //styleUrls: ['./register.component.scss']
  styleUrls: ['../../assets/css/style.css', './register.component.scss']
})
export class RegisterComponent implements OnInit {
  countries: any = [];
  states: any = [];
  cities: any = [];
  modalRef: BsModalRef;
  fileToUpload;
  submitted: boolean = false;
  registerForm: FormGroup;
  isvetinary: boolean = false;
  welfare: boolean = false;
  implanter: boolean = false;
  breeder: boolean = false;
  userapprovertype: string = 'User';
  checkboxes;
  @ViewChild('myDiv3') myDiv3: ElementRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute) { }

  get r() { return this.registerForm.controls; }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required]],
      CPassword: [''],
      Country: ['', Validators.required],
      county: [''],
      city: [''],
      postcode: ['', Validators.required],
      street1: ['', Validators.required],
      street2: [''],
      phone: ['', Validators.required],
      bypass_code: [''],
      is_vet: [''],
      vet_name: [''],
      vet_practice_code: [''],
      approver_type: [''],
      implanter_code: [''],
      implanter_trained_with: [''],
      implanter_certificate: [''],
      council_name: [''],
      license_number: [''],
      charity_number: [''],
      charity_name: [''],


    });
    this.getCountries();
  }
  clickEvent() {

    this.isvetinary = !this.isvetinary;
    if (this.isvetinary) {
      this.userapprovertype = 'vet';
    }

  }
  clickEvent2() {
    this.implanter = !this.implanter;
    if (this.implanter) {
      this.userapprovertype = 'Implanter';
    }
  }

  clickEvent7() {
    this.userapprovertype = 'User';
  }
  clickEvent3() {
    this.breeder = !this.breeder;
    if (this.breeder) {
      this.userapprovertype = 'breeder';
    }
  }
  clickEvent4() {
    this.welfare = !this.welfare;
    if (this.welfare) {
      this.userapprovertype = 'welfare';
    }
  }
  getCountries() {
    this.authenticationService.getCountries().subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];


        }
      }, error => {
        console.log(error);
      });
  }
  getStates(id) {
    this.authenticationService.getStates(id).subscribe(
      response => {
        if (response['stateList'] !== {}) {
          this.states = response['stateList'];
          console.log(this.states[0].id);
          this.registerForm.patchValue({ 'county': this.states[0].id });
        }
      }, error => {
        console.log(error);
      });
  }
  getCities(id) {
    this.authenticationService.getCities(id).subscribe(
      response => {
        if (response['CitiesList'] !== {}) {
          this.cities = response['CitiesList'];
        }
      }, error => {
        console.log(error);
      });
  }
  onSelect(files: FileList) {
    console.log('in here');

    //this.attachmentname = event.target['files'][0]['name'];
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);

  }
  onRegister() {

    //console.log(this.registerForm.value);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return false;
    }
    var data = this.registerForm.value;

    let input = new FormData();
    input.append('firstname', data.firstname);
    input.append('lastname', data.lastname);
    input.append('email', data.Email);
    input.append('password', data.Password);
    input.append('CPassword', data.Password);
    input.append('country', data.Country);
    input.append('county', data.county);
    input.append('city', data.city);
    input.append('phone', data.phone);

    input.append('postcode', data.postcode);
    input.append('street1', data.street1);
    input.append('street2', data.street2);
    input.append('is_vet', data.is_vet);
    input.append('login_name', data.Email);
    input.append('bypass_code', data.bypass_code);
    input.append('vet_name', data.vet_name);
    input.append('vet_practice_code', data.vet_practice_code);
    input.append('implanter_code', data.implanter_code);
    input.append('approve_type', this.userapprovertype);
    input.append('implanter_trained_with', data.implanter_trained_with);
    input.append('implanter_certificate', this.fileToUpload);

    input.append('council_name', data.council_name);
    input.append('license_number', data.license_number);

    input.append('charity_number', data.charity_number);
    input.append('charity_name', data.charity_name);


    let outThis = this;
    this.authenticationService.registeruser(input)
      .pipe(first())
      .subscribe(
        result => {

          if (this.userapprovertype == 'User') {
            this.login(data.Email, data.Password);
            this.authenticationService.loggedIn();
            this.authenticationService.toggle();
            this.authenticationService.dataFromService = true;
            setTimeout(() => this.toastr.success('Registration successfully'), 1000);
            let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
            el3.click();
            // return true; 



            this.router.navigate(['/home/myaccount']);
          } else {
            this.registerForm.reset();
            this.submitted = false;

            this.authenticationService.dataFromService = true;
            if (this.userapprovertype == 'breeder') {
              setTimeout(() => this.toastr.success('Registration successfull!'), 1000);
            } else {
              setTimeout(() => this.toastr.success('Registration successfully! Need To Approved By Administrator'), 1000);
            }


          }
        },
        error => {
          console.log(error);
          setTimeout(() => this.toastr.error(error.error.msg))
        });


  }
  registerPet() {
    this.modalRef.hide();
    localStorage.setItem('new_account', "1");
    this.router.navigate(['/home/newpet']);


  }
  purchaseProduct() {
    this.modalRef.hide();
    this.router.navigate(['/home/products']);
  }
  login(email, password) {
    console.log(email);
    console.log(password);
    this.authenticationService.login(email, password, 'users')
      .pipe(first())
      .subscribe(
        data => {

          this.authenticationService.dataFromService = true;
          setTimeout(() => this.toastr.success('Login Successfull')
          );
          this.frontCartOrderList();
          //this.router.navigate(['admin']);
        },
        error => {
          console.log('fail');
          setTimeout(() => this.toastr.error('Invalid Credentials', 'Oops!'));
          // this.error = error;
          // this.loading = false;
        });
  }
  frontCartOrderList() {
    this.authenticationService.frontCartOrderList().subscribe(
      response => {
        //this.showLoader = false;
        if (response['frontOrderList'] !== {}) {
        }
      }, error => {
        console.log(error);
      });
  }
  disableOthers() {
    $('input.example').not(this).prop('checked', false);
  }

}
