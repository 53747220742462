import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { log } from 'util';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-singleproduct',
  templateUrl: './singleproduct.component.html',
  styleUrls: ['../../assets/css/style.css', './singleproduct.component.scss']
})

export class SingleproductComponent implements OnInit {
  product_name;
  product_description;
  product_price;
  clicked: boolean = false;
  selectedLevel: any = [];
  product_currency;
  pdata: any = [];
  cur_id;
  constructor(private cookieService: CookieService, private authenticationService: AuthenticationService) { }
  imageUrls: any = [];
  // imageUrls: any = [
  //   { url: 'https://cdn.vox-cdn.com/uploads/chorus_image/image/56748793/dbohn_170625_1801_0018.0.0.jpg', caption: 'The first slide', href: '#config' },
  //   { url: 'https://cdn.vox-cdn.com/uploads/chorus_image/image/56789263/akrales_170919_1976_0104.0.jpg', caption: 'Apple TV', href: 'https://www.apple.com/' },
  //   { url: 'http://protectedpet-backend.yellowbulb.co.uk/uploads/15/products/1554708204.jpg', backgroundSize: 'contain', backgroundPosition: 'center' }
  // ];
  height: string = '400px';
  minHeight: string;
  arrowSize: string = '30px';
  showArrows: boolean = true;
  disableSwiping: boolean = false;
  autoPlay: boolean = true;
  autoPlayInterval: number = 3333;
  stopAutoPlayOnSlide: boolean = true;
  debug: boolean = false;
  backgroundSize: string = 'cover';
  backgroundPosition: string = 'center center';
  backgroundRepeat: string = 'no-repeat';
  showDots: boolean = true;
  dotColor: string = '#FFF';
  showCaptions: boolean = true;
  captionColor: string = '#FFF';
  captionBackground: string = 'rgba(0, 0, 0, .35)';
  lazyLoad: boolean = false;
  hideOnNoSlides: boolean = false;
  width: string = '100%';
  fullscreen: boolean = false;
  usertype;
  ngOnInit() {
    this.usertype = localStorage.getItem('user_type');
    // adding an image url dynamically.
    if (this.GetParam('productid') != 0) {
      console.log('uri---' + this.GetParam('productid'));

      let productid = this.GetParam('productid');
      this.getSingleProduct(productid);
      // this.imageUrls = ["http://protectedpet-backend.yellowbulb.co.uk/uploa…ith logo.jpg-22b6df9e3766c7926cfb8ad51e96d578.jpg", "http://protectedpet-backend.yellowbulb.co.uk/uploa…ith logo.jpg-22b6df9e3766c7926cfb8ad51e96d578.jpg", "http://protectedpet-backend.yellowbulb.co.uk/uploa…ith logo.jpg-22b6df9e3766c7926cfb8ad51e96d578.jpg", "http://protectedpet-backend.yellowbulb.co.uk/uploa…ith logo.jpg-22b6df9e3766c7926cfb8ad51e96d578.jpg"];
    }

  }
  GetParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }
  getSingleProduct(productid) {
    let cur_id = null
    let cookiecurrency = localStorage.getItem('temp_currency_id');
    if (cookiecurrency != null) {
      cur_id = cookiecurrency;
    } else {
      cur_id = null;
    }
    this.authenticationService.getSingleProductData(productid, cur_id).subscribe(
      response => {
        if (response['frontproductData'] !== {}) {
          this.pdata = response['frontproductData'][0];
          console.log(this.pdata);
          this.selectedLevel[productid] = 1;
          if (this.pdata.images.length > 0) {
            console.log(this.pdata.images[0].image_url);

            for (let i = 0; i <= this.pdata.images.length; i++) {
              let pusheditems: { [url: string]: any; } = this.pdata.images[0].image_url;
              var replaced = pusheditems.split(' ').join('%20');
              this.imageUrls.push(replaced);
            }
          }
          console.log(this.imageUrls);

          // this.product_name = pdata.name;
          // this.product_description = pdata.description;
          // this.product_price = pdata.price ;
          //console.log(response['product']);          
        }
      }, error => {
        console.log(error);
      });
  }

  addtocart(item) {
    let pid = item.id;
    let pquantity = 1;

    if (this.selectedLevel[pid] != null) {
      pquantity = this.selectedLevel[pid];
    }
    console.log(pquantity);

    this.clicked = true;
    var newItem = {
      'product_id': pid,
      'name': item.name,
      'quantity': pquantity,
      'price': item.price,
      'image': item.images[0].image_url,
      'currency': item.currency,
    };
    if ($('#btn-' + item.id).text() == "ADDED") {
      return false;
    }
    $('#btn-' + item.id).text("ADDED");
    this.authenticationService.items.push(newItem);
    localStorage.setItem('items', JSON.stringify(this.authenticationService.items));
    this.authenticationService.changeMessage(this.authenticationService.items.length);


    //$(".shopping-cart").effect( "shake", {times:4}, 1000 );
    var div = document.getElementsByClassName('shopping-cart');
    var interval = 100;
    var distance = 10;
    var times = 4;

    $(div).css('position', 'relative');

    for (var iter = 0; iter < (times + 1); iter++) {
      $(div).animate({
        left: ((iter % 2 == 0 ? distance : distance * -1))
      }, interval);
    }
    $(div).animate({ left: 0 }, interval);


  }

  logoutuser() {
    console.log('in logout');

    this.authenticationService.logout().subscribe(
      response => {
        console.log(response);

      }, error => {
        console.log(error);
      });
  }
}
