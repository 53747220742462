import { Component, OnInit, TemplateRef, ElementRef, ViewChild, HostListener } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { log } from 'util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-change-owner',
  templateUrl: './change-owner.component.html',
  // styleUrls: ['./change-owner.component.scss']
  styleUrls: ['../../assets/css/style.css']
})
export class ChangeOwnerComponent implements OnInit {
  pdfurl;
  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getPdfName();
  }
  getPdfName() {
    this.authenticationService.getChangeOwnerPdf().subscribe(
      response => {
        if (response['pdf'] !== {}) {
          // let myurl = response['pdf'].split('.pdf');
          // this.pdfurl = myurl[0]+'.pdf';
          this.pdfurl = response['pdf'];

        }
      }, error => {
        setTimeout(() => this.toastr.error(error.error.messsage, 'Oops!'));

      });
  }

}
