import { Component } from '@angular/core';
import {Router} from "@angular/router";
import { NavbarService } from './services/navbar.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.scss']
  styleUrls: ['../assets/css/style.css','../assets/css/responsive.css','./app.component.scss']
})
export class AppComponent {
  param2;
  loginpage:boolean=false;
  title = 'Protected Pet';
  showLoader:boolean=true;
  constructor(
    //showLoader=true,
    public nav: NavbarService,
    private router: Router) {this.showLoader = true; }

  ngAfterContentInit() {
      this.showLoader = false;
  }  
  ngOnInit() { 
    
    this.param2= localStorage.getItem("currentUser");
    if(this.param2 == null){
      this.loginpage = true;
    }
    
  }  
}
