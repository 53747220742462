import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  // styleUrls: ['./homepage.component.scss']
  styleUrls: ['../../assets/css/style.css', '../../assets/css/responsive.css', './homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  clicked: boolean = false;
  param;
  chipno: string = '';
  bypasscode: string = '';
  modalRef: BsModalRef;
  testmodal;
  myVar;
  value;
  submitted: boolean = false;
  reportfoundForm: FormGroup;
  reportdetails;
  petname: string = '';
  petcolor: string = '';
  pdfurl;
  petbreed: string = '';
  petsex: string = '';
  pet_type: string = '';
  owner_name: string = '';
  owner_email: string = '';
  owner_address: string = '';
  owner_phone: string = '';
  pet_t: string = '';
  apiurl;
  loggedin: boolean = false;
  apiname;
  apiphone;
  showLoader: boolean = true;
  bypasscode_matched: boolean = false;
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('myDiv2') myDiv2: ElementRef;
  @ViewChild('myDiv3') myDiv3: ElementRef;
  @ViewChild('chipinputbox') chipinputbox: ElementRef;
  constructor(private modalService: BsModalService, private formBuilder: FormBuilder, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });

  }
  get r() { return this.reportfoundForm.controls; }

  ngAfterViewInit() {
    this.showLoader = false;
    let outerThis = this;
    // console.log("i am here");
    // console.log(this.router.events);
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(e => {
        console.log(e);
      });
    this.authenticationService.listen().subscribe((m: any) => {
      outerThis.chipinputbox.nativeElement.focus()

      // this.onFilterClick(m);
    })
  }


  ngOnInit() {
    this.getPdfName();
    this.reportfoundForm = this.formBuilder.group({
      details: ['', Validators.required],
    });
    this.param = localStorage.getItem("currentUser");
    if (this.param != null) {
      // console.log("i m loged inwwa");

      this.authenticationService.dataFromService = true;
    } else {
      // console.log("not logged inwaaaaa");

    }
  }
  findchip() {
    this.clicked = true;

    this.authenticationService.chipnumber(this.chipno).subscribe(
      response => {
        if (response['data'][0] !== {}) {

          if (response['data'][0].chip_location == 'local') {

            this.value = response['data'];
            let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
            el.click();

            //this.bypasscode = '';
            // this.chipno = '';
          } else {
            this.bypasscode = '';
            this.chipno = '';
            this.apiurl = response['data'];

            console.log(this.apiurl[0].chipnumber);
            let el2: HTMLElement = this.myDiv2.nativeElement as HTMLElement;
            el2.click();
          }
          this.clicked = false;


        }
      }, error => {
        let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
        el3.click();
        this.clicked = false;

      });


  }

  getPdfName() {
    this.authenticationService.getChangeOwnerPdf().subscribe(
      response => {
        if (response['pdf'] !== {}) {
          //let myurl = response['pdf'].split('.pdf');
          //this.pdfurl = myurl[0]+'.pdf';
          this.pdfurl = response['pdf'];

        }
      }, error => {
        setTimeout(() => this.toastr.error(error.error.messsage, 'Oops!'));

      });
  }

  findchipwithbypasscode() {
    console.log(this.chipno + '--' + this.bypasscode);

    this.clicked = true;

    var myPostObject = {
      chip_number: this.chipno
    }
    this.authenticationService.chipnumberwithbypass(this.chipno, this.bypasscode)
      .pipe(first())
      .subscribe(
        (data: any) => {
          // this.clicked = false;
          // let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
          // el.click();
          let pdata = data.Chips;
          //console.log('pp', pdata);
          if (typeof (data.status2) == 'object') {
            this.clicked = false;
            this.bypasscode_matched = true;
            this.petname = data.Chips.name;
            this.petcolor = pdata.Pet.colour;
            this.petbreed = pdata.Pet.breed;
            this.petsex = pdata.Pet.sex;
            this.pet_type = pdata.Pet.pet_type;
            this.owner_name = pdata.User.firstname + ' ' + pdata.User.lastname;
            this.owner_email = pdata.User.email;
            this.owner_address = pdata.userAddress.street1 + ' ' + pdata.userAddress.city;;
            this.owner_phone = pdata.UserExtras.phone;
            console.log(this.petname);
          } else {
            this.toastr.info(data.message, '', { timeOut: 5000 });
          }



          //setTimeout(() => this.toastr.success('Chip Found successfully!'));
        },
        error => {

          this.toastr.info(error.error.message, '', { timeOut: 5000 });
        });
  }

  add_details() {
    // console.log('aithe aa v');

    this.submitted = true;
    if (this.reportfoundForm.invalid) {
      setTimeout(() => this.toastr.error('Please ensure the required data is entered'));
      return false;
    }
    var data = this.reportfoundForm.value;
    //return false;
    var myPostObject = {
      details: data.details,
      chip_number: this.chipno,

    }
    console.log(myPostObject);

    this.authenticationService.reportlost(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //console.log(data);
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Thank you. Our team have been notified and are working on helping this pet.'));
          this.reportfoundForm.reset();
          this.submitted = false;
        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))

        });
  }

  register() {
    this.modalRef.hide();

    this.router.navigate(['home/register']);



    console.log('Pass');
  }


  onReport() {
    this.clicked = true;
    // if(this.reportdetails == ''){
    //   this.reportfoundForm.invalid;
    // }
    if (this.reportfoundForm.invalid) {
      return false;
      console.log('err');

    }
    var data = this.reportfoundForm.value;
    var myPostObject = {
      chip_number: data.details
    }
    this.authenticationService.chipnumberwithbypass(this.chipno, this.bypasscode)
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => this.toastr.success('Thank you. Our team have been notified and are working on helping this pet.'));
        },
        error => {
          setTimeout(() => this.toastr.error('Please ensure the required data is entered'));
        });
  }

  closepopup() {
    this.modalRef.hide();
    this.chipno = "";
    this.bypasscode = "";
    this.bypasscode_matched = false;
  }
}
