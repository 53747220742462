import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-adminlayout',
  templateUrl: './adminlayout.component.html',
  styleUrls: ['./adminlayout.component.scss']
})
export class AdminlayoutComponent implements OnInit {

  param2;
  user_type;
  loginpage: boolean = false;

  title = 'Protected Pet';
  constructor(private cookieService: CookieService, private authenticationService: AuthenticationService, public nav: NavbarService, private router: Router) { }

  ngOnInit() {
    this.nav.show();
    this.param2 = localStorage.getItem("currentUser");
    this.user_type = localStorage.getItem("user_type");

    this.param2 = localStorage.getItem("currentUser");//localStorage.getItem("currentUser");
    this.user_type = localStorage.getItem("user_type");//localStorage.getItem("user_type");
    if (this.user_type != null) {
      this.user_type = this.user_type.replace(/^"(.*)"$/, '$1');
    }

    if (this.user_type != 'admins') {
      this.loginpage = true;
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    }

  }

}
