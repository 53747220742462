import { Component, OnInit,TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-petstats',
  templateUrl: './petstats.component.html',
  styleUrls: ['./petstats.component.scss']
})

export class PetstatsComponent implements OnInit {
  
  addPetForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  showLoader: boolean = true;
  param2;
  data :any = [];
  modalRef: BsModalRef;
  search:string = "";
  constructor(private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ backdrop: 'static', keyboard: false });
}

  get r() { return this.addPetForm.controls; }
  
  ngOnInit() {
    
    this.param2= localStorage.getItem("currentUser");
    
    if(this.param2 == null){
      console.log('in null');
      this.router.navigate(['/login']);
    }else{
      this.nav.show();
    }

    this.addPetForm = this.formBuilder.group({
      petname: ['', Validators.required],
    });   
    this.getPetStats();
  }
  getPetStats() { 
    this.showLoader = true;
    this.authenticationService.getPetStats().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
          if (response['data'] !== {}) {
            this.data = response['data'];
            // let keys = [];
            // for (let key in response['data']) {
            //   keys.push({key: key, value:  response['data'][key]});
            // }
            
          }
        //}
      }, error => {
        console.log(error);
      });
  }
  deletePetType(id){
    if (confirm("Are you sure you want to delete Pet Type")) {
    this.authenticationService.deletePetType(id).subscribe(
      response => {
        //this.getUsers();
        if (response['status'] == 200) { 
          console.log('success');
          this.getPetStats();
          setTimeout(() => this.toastr.success('Pet deleted successfully!'))
        }
      }, error => {
        console.log('errorrrrr');
        
        setTimeout(() => this.toastr.success('Something went wrong'))
      });    
    }
  }
  logout(){
    console.log('inlogout');
    this.nav.hide();
    this.authenticationService.logout();
  } 
  
  addPet(){
    this.submitted = true;
    if (this.addPetForm.invalid) {
      return false;
    }
    var data = this.addPetForm.value;
    //return false;
    var myPostObject = {
      name: data.petname,

    }   
  this.authenticationService.addPetname(myPostObject)
  .pipe(first())
  .subscribe(
      data => {        
          //console.log(data);
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Pet type added successfully!'))
          this.addPetForm.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.getPetStats();
          //this.router.navigate(['/dashboard']);

      },
      error => {
        setTimeout(() => this.toastr.error('Something went wrong!'))
        //   this.error = error;
        //   this.loading = false;
      });
} 
updateFilter(){
  if(this.search.trim() == ""){
    this.getPetStats();
    
  }else{
    // this.showLoader = true;
    // this.data = [];
    // this.total=0;
    // this.p=0;
    // let api = "searchPetType";
    // this.authenticationService.getSearchData(api,this.search).subscribe(
    //   response => {
    //     this.showLoader = false;
    //     if (response['petTypeList'] !== {}) {
    //       this.data = response['petTypeList'].data;
    //       this.total=response['petTypeList'].total;
    //       this.response=response['petTypeList'];
    //       this.p=0;  
          
    //     }
    //   }, error => {
    //     console.log(error);
    //   }); 
  }
} 


}

