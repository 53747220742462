import { Component, OnInit,TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss']
})
export class ProductCategoryComponent implements OnInit {
  addProductCat: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  showLoader: boolean = true;
  param2;
  loading:boolean=false;
  data :any = [];
  modalRef: BsModalRef;
  attachmentname: string;
  fileToUpload;
  response;
  p: number = 1;
  total: number;   
  search:string = "";
  isProductCategory:boolean = false;
  constructor(private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ backdrop: 'static', keyboard: false });
}
  get r() { return this.addProductCat.controls; }
  
  ngOnInit() {
    
    this.param2= localStorage.getItem("currentUser");
    
    if(this.param2 == null){
      this.router.navigate(['/login']);
    }else{
      this.nav.show();
    }

    this.addProductCat = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      // image_url: ['', Validators.required],
      image_url: [''],
      id:['']
    });   
    this.getProductCat(1);
  }
  getProductCat(page:number) { 
    this.showLoader = true;
    this.authenticationService.getProductCat().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
          if (response['productCatList'] !== {}) {
            this.data = response['productCatList'].data;
            this.total=response['productCatList'].total;
            this.response=response['productCatList'];
            this.p=page;
            console.log(this.data);  
            
          }
        //}
      }, error => {
        console.log(error);
      });
  }
  deleteCategory(id){
    this.authenticationService.deleteCat(id).subscribe(
      response => {
        //this.getUsers();
        if (response['status'] == 200) { 
          console.log('success');
          this.getProductCat(1);
          setTimeout(() => this.toastr.success('category deleted successfully!'))
        }
      }, error => {
        console.log('errorrrrr');
        
        setTimeout(() => this.toastr.success('Something went wrong'))
      });  
  }
  deleteCat(id,products){
    if (confirm("Are you sure you want to delete category")) {
      if(products != 0)
      {
        if (confirm("There Have Store Products.Are You sure want to delete")) {
          this.deleteCategory(id);
        }
      }else{
        this.deleteCategory(id);
      }
      
    }
  }
  logout(){
    console.log('inlogout');
    this.nav.hide();
    this.authenticationService.logout();
  } 
  onSelect(files: FileList) {
    console.log('in here');
    
    //this.attachmentname = event.target['files'][0]['name'];
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);

  }
  addCat(files: FileList){
    this.submitted = true;
    if (this.addProductCat.invalid) {
      return false;
    }
    var data = this.addProductCat.value;
    //return false;
    //this.attachmentname = event.target['files'][0]['name'];
    //this.fileToUpload = files.item(0);
    // this.attachmentname = event.target['files'][0]['name'];
    // this.fileToUpload = files.item(0);

    let input = new FormData();
    input.append('name', data.name);    
    input.append('description', data.description);    
    input.append('image_url', this.fileToUpload);    
    // var myPostObject = {
    //   name: data.name,
    //   description: data.description,
    //   image_url: data.image_url,
    // }   
  this.authenticationService.addCategory(input)
  .pipe(first())
  .subscribe(
      data => {        
          //console.log(data);
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Category added successfully!'))
          this.addProductCat.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.getProductCat(1);
          //this.router.navigate(['/dashboard']);

      },
      error => {
        setTimeout(() => this.toastr.error('Something went wrong!'))
        //   this.error = error;
        //   this.loading = false;
      });
}  
getPage(page: number) {
  this.loading = true;
  let url = this.response.first_page_url.split("page=");
  url= url[0]+"&page="+page;   
  this.authenticationService.getalluserspagination(url).subscribe(
    response => {
        if (response['productCatList'] !== {}) {
          this.data = response['productCatList'].data;
          this.total=response['productCatList'].total;
          this.p=page;
          console.log(this.data);
          
        }
    }, error => {
      console.log(error);
    });    
}   
updateFilter() {
  if (this.search.trim() == "") {
    this.getProductCat(1);

  } else {
    this.showLoader = true;
    this.data = [];
    this.total = 0;
    this.p = 0;
    let api = "searchcategories";
    this.authenticationService.searchByQueryString(api, this.search).subscribe(
      response => {
        this.showLoader = false;
        if (response['productCatList'] !== {}) { 
          this.data = response['productCatList'].data;
          this.total = response['productCatList'].total;
          this.response = response['productCatList'];
          this.p = 0;

        }
      }, error => {
        console.log(error);
      });
  }
}
closePopup(modalRef){
  this.addProductCat.reset();
  this.submitted = false;
  modalRef.hide();
}
editProductCategory(id,template: TemplateRef<any>){
  this.isProductCategory = true;
  let outerThis = this;
  this.authenticationService.getCatById(id).subscribe(
    response => {
      if (response['productCat'] !== {}) { 
        let categoryDetail = response['productCat'];
        outerThis.addProductCat.patchValue({id:categoryDetail['id'],name: categoryDetail['name'],description:categoryDetail['description'] });
        outerThis.modalRef = outerThis.modalService.show(template,{ backdrop: 'static', keyboard: false });
      }
    }, error => {      
      setTimeout(() => this.toastr.success(error.error.msg))
    });  
  // this.addProductCat.patchValue({name: categoryDetail['name'],description:categoryDetail['id'] });
  // this.modalRef = this.modalService.show(template,{ backdrop: 'static', keyboard: false });
}
editCat(files: FileList){
  this.submitted = true;
  if (this.addProductCat.invalid) {
    return false;
  }
  var data = this.addProductCat.value;

  let input = new FormData();
  input.append('id', data.id);  
  input.append('name', data.name);    
  input.append('description', data.description);    
  input.append('image_url', this.fileToUpload); 
  let id = data.id;
  console.log(id);
  this.authenticationService.updateCategory(id,input)
  .pipe(first())
  .subscribe(
      data => {        
          setTimeout(() => this.toastr.success('Category edit successfully!'))
          this.addProductCat.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.getProductCat(1);

      },
      error => {
        setTimeout(() => this.toastr.error(error.error.msg))
      });
  }  
}
