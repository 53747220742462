import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute,Router,RouterStateSnapshot} from "@angular/router";
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { map,catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  param1: string = '';
  submitted = false;
  resetsubmitted = false;
  forgotPasswordForm: FormGroup;  
  resetPasswordForm: FormGroup;  
  viewResetPassword :boolean = true;
  data: any = []; 
  keyword :string = '';
  showEmailForm:boolean= true;
  showResetPassword:boolean= false;
  getuserid;
  constructor(private routes : Router,private toastr: ToastrService,private BrowserAnimationsModule: BrowserAnimationsModule,private http: HttpClient,private formBuilder: FormBuilder,private authenticationService: AuthenticationService,private fb: FormBuilder, private router: ActivatedRoute, private route: Router) {
    this.router.queryParams.subscribe(params => {
      this.param1 = params['userId'];
  });    
  }
  get r() { return this.forgotPasswordForm.controls; }
  get f() { return this.resetPasswordForm.controls; }

  ngOnInit() {

    this.forgotPasswordForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]],
   });    

    this.resetPasswordForm = this.formBuilder.group({
      Password: ['', [Validators.required, Validators.minLength(6)]],
      Confirm_password:['', [Validators.required, Validators.minLength(6)]],
    });       

      if(this.param1 != null){
        //console.log('here i am'+this.param1);
        
        this.viewResetPassword = false;
      }else{
        let action = localStorage.getItem("formaction");
        if(action == 'resetPassword'){
          this.viewResetPassword = true;
        }
      }
      if(this.GetParam('code') != ""){

        this.showEmailForm= false;
        this.showResetPassword= true;        
      }
      if(this.GetParam('userid') != ""){
          this.getuserid = this.GetParam('userid');
      }        
  }
  GetParam(name){
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if(!results){
      return 0;
    }
    return results[1] || 0;
  }
  sendresetLink(){
    this.submitted = true;
    var data = this.forgotPasswordForm.value;
    console.log(data);

    if (this.forgotPasswordForm.invalid) {
      console.log('error');
        return;
    }

    var data = this.forgotPasswordForm.value;

  this.authenticationService.sendResetPasswordLink(data.Email)
  .pipe(first())
  .subscribe(
      data => {        
        setTimeout(() => this.toastr.success('Password reset Link sent! Check Email'))
        this.forgotPasswordForm.reset();
        this.submitted = false;
        
      },
      error => {
        if(error.error.message){
          setTimeout(() => this.toastr.error(error.error.message))
        }else{
          setTimeout(() => this.toastr.error('Something went wrong')
          );          
        }
        
      });    
  }

  resetPassword(){
    this.resetsubmitted = true;
    var data = this.resetPasswordForm.value;

    if (this.resetPasswordForm.invalid) {
      console.log('error');
        return;
    }

    var data = this.resetPasswordForm.value;
    var userObject = {
      Password:data.Password,
      confirmPassword:data.Confirm_password,
      code:this.GetParam('code')
    }   
    this.authenticationService.updatePassword(userObject,this.getuserid)
    .pipe(first())
    .subscribe(
        data => {        
          setTimeout(() => this.toastr.success('Password changed successfully'));
          this.resetPasswordForm.reset();
          this.resetsubmitted = false;
          this.logOut();          
          
        },
        error => {
          setTimeout(() => this.toastr.error("Something went wrong , Please request password again!"))
        });       
  }

  logOut(){
    this.authenticationService.logout();
  }
  backtoLogin(){
    this.route.navigate(['/']);       
  }
}
