import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchArray'
})
export class SearchArrayPipe implements PipeTransform {

  transform(items: any[], cols: string[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      let serRes = false;
      cols.forEach((colname) => {
        if (it[colname].toLowerCase().includes(searchText)) {
          serRes = true;
        }
      });
      return serRes;
    });


  }
  // transform(items: any[], searchText: string): any[] {
  //   if (!items) return [];
  //   if (!searchText) return items;
  //   searchText = searchText.toLowerCase();
  //   return items.filter(it => {
  //     return it.toLowerCase().includes(searchText);
  //   });
  // }
}
