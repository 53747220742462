import { Component, OnInit, TemplateRef, ElementRef, ViewChild, HostListener, Renderer } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { log } from 'util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['../../assets/css/style.css', './cart.component.scss']
})
export class CartComponent implements OnInit {
  @ViewChild('myDiv4') myDiv4: ElementRef;
  @ViewChild('myDiv') myDiv: ElementRef;
  items: any = [];
  modalRef: BsModalRef;
  showcart: boolean = true;
  deliverydiv: boolean = false;
  billingdiv: boolean = false;
  checkoutdiv: boolean = false;
  dodisable: boolean = false;
  orderForm: FormGroup;
  submitted: boolean = false;
  thankyou: boolean = false;
  couponApplied: boolean = false;
  myaction;
  newItem: any = [];
  sitedata = [];
  loading: boolean = false;
  showfinal: boolean = false;
  showPdis: boolean = false;
  newcost;
  showMdis: boolean = false;
  param;
  discountcode;
  user;
  gold_price;
  basic_price;
  discountValue: string;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  emptyData: boolean = false;
  loginForm: FormGroup;
  handler: any;
  shippingcost = 0;
  Price;
  FinalPrice;
  currencyType;
  currencyCode;
  currencyID;
  usercurr;
  emptycart: boolean = true;
  showdelivery: boolean = true;
  useremail;
  ukshipping: boolean = true;
  internationalshipping: boolean = false;
  isgoldplan: boolean = false;
  countries = [];
  list = [];
  currenciesList = [];
  shipping;
  ischipfree: any = 0;
  tempdata;
  user_account;
  myVar;
  isOn;
  petsData: any;
  isPopup: boolean = false;
  get f() { return this.loginForm.controls; }
  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private renderer: Renderer,
    private route: ActivatedRoute) { }
  get r() { return this.orderForm.controls; }
  @ViewChild('checklabel') checklabel: ElementRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }

  ngOnInit() {
    let payment = localStorage.getItem('show_payment_popup');
    console.log('payment--', payment);

    this.getuserProfile();
    this.sitesettinglist();
    this.getShipping();
    this.getCountries();

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.orderForm = this.formBuilder.group({
      postcode: ['', Validators.required],
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      address3: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      phone: ['', Validators.required],
      //notes: ['', Validators.required],

    });
    //

    //console.log('email wa==='+useremail);


    this.items = JSON.parse(localStorage.getItem('items'));
    console.log(this.items);
    this.user_account = localStorage.getItem("user_account");

    if (this.items == null || this.items == "") {
      console.log("Cart is empty");
      this.emptyData = true;
      if (payment == '1') {
        this.isPopup = true;
        // let el3: HTMLElement = this.myDiv4.nativeElement as HTMLElement;
        // el3.click();
      }
    } else {
      this.useremail = localStorage.getItem('user_email');
      this.emptyData = false;
      this.countPrice();
      this.currencyType = this.items[0].symbol;
      this.currencyCode = this.items[0].currencyCode;
      this.currencyID = this.items[0].currencyID;

      this.items.forEach(element => {
        if (element.name == 'Gold Plan' || element.name == 'Basic Plan') {
          this.showdelivery = false;
          if (element.name == 'Gold Plan') {
            this.isgoldplan = true;
          }
        } else {
          if (payment == '1') {
            this.isPopup = true;
            // let el3: HTMLElement = this.myDiv4.nativeElement as HTMLElement;
            // el3.click();
          }
        }
      });
    }
    // console.log('vince', this.items);
    //console.log('my currency is ===='+this.currencyType);

    this.authenticationService.cartstatus.subscribe(message => this.emptyData = message)
    //emptyData
    this.tempdata = this.Price;
    this.FinalPrice = this.Price;
  }
  getuserProfile() {
    let userid = localStorage.getItem('userid');
    this.authenticationService.getuser(userid).subscribe(
      response => {
        console.log('myUser', response['data']);
        //if (response['status'] == 200) { 
        if (response['data'] !== {}) {
          this.petsData = response['data'].pets;
          let el3: HTMLElement = this.myDiv4.nativeElement as HTMLElement;
          el3.click();
          if (this.isgoldplan) {
            this.petsData.forEach(element => {
              element.price = this.gold_price;
              //  this.ischipfree = element.chips.is_free;
            });
          }
          else {
            this.petsData.forEach(element => {
              element.price = this.basic_price;
              // this.ischipfree = element.chips.is_free;
            });
          }

          if (this.petsData.length > 0) {
            this.ischipfree = this.petsData[0].chips.is_free;
          }

          // if (response['data'].pets.length > 0) {
          //   this.gold_price = this.gold_price * response['data'].pets.length;
          // }
          let firstname = response['data'].firstname;
          let lastname = response['data'].lastname;
          let email = response['data'].email;
          let postcode = response['data'].user_addresses.postcode;
          let address1 = response['data'].user_addresses.street1;
          let address2 = response['data'].user_addresses.street2;
          //let city = response['data'].user_addresses.city;
          let city = response['data'].user_addresses.city;
          let country = response['data'].user_addresses.user_country.name;
          let phone = response['data'].user_extras.phone;
          this.orderForm = this.formBuilder.group({
            postcode: [postcode, Validators.required],
            email: [email, Validators.required],
            firstname: [firstname, Validators.required],
            lastname: [lastname, Validators.required],
            address1: [address1, Validators.required],
            address2: [address2, Validators.required],
            address3: [address2, Validators.required],
            city: [city, Validators.required],
            country: [country, Validators.required],
            phone: [phone, Validators.required],
            notes: [''],

          });
        }
        //}
      }, error => {
        // setTimeout(() => this.toastr.error(error.error.messsage, 'Oops!'));

      });
  }
  delivery() {
    // check if user is logged in
    this.param = localStorage.getItem("currentUser");
    let checkuser = localStorage.getItem("user_type");

    if (checkuser != null)
      checkuser = checkuser.replace(/^"(.*)"$/, '$1');

    if (checkuser != 'users') {
      console.log('PLease LOGIN');
      let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
      el.click();
    } else {
      this.showcart = false;
      this.deliverydiv = true;
    }

  }
  billing() {
    console.log('shipping=' + this.shipping + '--showdelivry=' + this.showdelivery);
    if (this.shipping == 'undefined' && this.showdelivery == false) {
      setTimeout(() => this.toastr.error('Please select shipping method!'))
    } else {
      //this.FinalPrice = this.FinalPrice + this.shipping;
      this.showcart = false;
      this.deliverydiv = false;
      this.billingdiv = true;
    }
  }
  updatePrice(cost) {
    this.shippingcost = cost;
    this.tempdata = this.tempdata;
    // this.newcost = +this.tempdata + +cost;//this.tempdata+cost;
    this.newcost = +this.tempdata + +cost;//this.tempdata+cost;
    console.log(this.newcost);

    //this.newcost =this.newcost.toFixed(2);
    this.Price = this.newcost;
    this.FinalPrice = this.Price;
    console.log(this.Price);

  }
  onSubmit() {
    this.submitted = true;
    if (this.orderForm.invalid) {
      console.log('invalid');
      return false;
    }
    this.loading = true;
    var data = this.orderForm.value;
    console.log(data);
    this.items = JSON.parse(localStorage.getItem('items'));
    var myPostObject = {
      firstname: data.firstname,
      lastname: data.lastname,
      billing_postcode: data.postcode,
      email: data.email,
      billing_address1: data.address1,
      billing_address2: data.address2,
      //billing_address3: data.address3,
      billing_city: data.city,
      billing_country: data.country,
      telephone: data.phone,
      special_instructions: data.notes,
      order_amount: this.Price,
      status: 'cart',
      productItems: this.items
    }

    console.log('myPostObject', myPostObject);
    this.authenticationService.frontorder(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          // console.log("order added is "+data.order.id);
          localStorage.setItem('temp_order_id', data.order.id);
          this.loading = false;
          this.showcart = false;
          this.deliverydiv = false;
          this.billingdiv = false;
          this.checkoutdiv = true;
        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
        });
  }
  checkout() {
    this.showcart = false;
    this.deliverydiv = false;
    this.billingdiv = false;
    this.checkoutdiv = true;
  }

  cartridirect(page) {
    console.log(page);
    this.router.navigate(['/home/cart'], { queryParams: { action: page }, queryParamsHandling: 'merge' });
  }


  loginfunc() {
    this.invalidLogin = false;
    this.loginsubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    var data = this.loginForm.value;
    this.authenticationService.login(this.f.email.value, this.f.password.value, 'users')
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          setTimeout(() => this.toastr.success('Logged In Successfully'));

          this.modalRef.hide();
          this.authenticationService.loggedIn();
          //this.loggedin = true;
          this.router.navigate(['home/cart']);
          this.getuserProfile();
        },
        error => {
          console.log('fail');
          setTimeout(() => this.toastr.error('Invalid Credentials', 'Oops!'));
          //this.invalidLogin = true;
          // this.error = error;
          // this.loading = false;
        });
  }
  openCheckout() {
    var localthis = this;
    this.items = JSON.parse(localStorage.getItem('items'));
    this.handler = (<any>window).StripeCheckout.configure({
      image: './../assets/img/logo.png',
      key: environment.stripeKey,
      locale: 'auto',
      token: function (token: any) {
        console.log(token);
        localthis.updateOrder(token);
        if (localthis.isgoldplan) {
          localthis.upgradePlan(token);
        }

        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
      }
    });

    this.handler.open({
      name: 'Protected Pet (c)',
      email: this.useremail,
      description: this.items.length + ' Products',
      currency: this.currencyCode,
      amount: (this.Price ? this.Price : this.Price) * 100
    });

  }



  @HostListener('window:popstate')
  onPopstate() {
    this.handler.close();
  }
  upgradePlan(token) {
    //this.spinner.show();
    var orderid = localStorage.getItem('temp_order_id');
    var tokenobject = {
      token: token,
      id: orderid
    }
    console.log('test', tokenobject);
    this.authenticationService.upgradeSubscription(tokenobject).subscribe(response => {
      console.log(response);
      //this.spinner.hide();
      this.user_account = 1;
      localStorage.setItem('user_account', '1');
      setTimeout(() => this.toastr.success('Plan upgraded successfully!'))
      this.modalRef.hide();
    }, error => {
      this.modalRef.hide();
    });
  }
  updateOrder(token) {
    //console.log(token);

    var myPostObject = {
      status: 'complete',
      transaction_id: token.id,
      paid: '1',
      currency_id: this.currencyID,
      order_amount: this.FinalPrice ? this.FinalPrice : this.Price,
      discount_price: this.discountValue ? this.discountValue : 0,
      shipping_cost: this.shippingcost,

    }
    console.log(myPostObject);
    var orderid = localStorage.getItem('temp_order_id');
    this.authenticationService.updateOrder(myPostObject, orderid)
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => this.toastr.success('Order successfully placed!'));

          this.authenticationService.items.length = 0;
          localStorage.setItem('items', JSON.stringify(this.authenticationService.items));
          // this.authenticationService.changeMessage(this.authenticationService.items.length);
          this.loading = false;
          this.modalRef.hide();
          this.checkoutdiv = false;
          this.thankyou = true;
          this.authenticationService.changeMessage(0);
          this.authenticationService.unpaidSuccess();
        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
        });
  }

  increase_quantity(i, temp_package) {
    console.log(temp_package);
    this.items = JSON.parse(localStorage.getItem('items'));
    //console.log( this.items[i]);

    this.items[i].quantity = temp_package;

    this.authenticationService.items = this.items;
    localStorage.setItem('items', JSON.stringify(this.authenticationService.items));
    //temp_package.quantity++
    this.countPrice();
  }

  decrease_quantity(temp_package) {
    if (temp_package.quantity == 0) {
      return alert("can't be in minus")
    }
    temp_package.quantity--
    this.Price -= temp_package.price
    this.tempdata -= temp_package.price
  }
  countPrice() {
    this.Price = 0;
    this.tempdata = 0;
    this.items = JSON.parse(localStorage.getItem('items'));
    console.log(this.items);

    for (let p of this.items) {
      this.Price += p.price * p.quantity
      this.tempdata += p.price * p.quantity
    }

  }

  applyDiscount() {
    // check if user is logged in
    this.param = localStorage.getItem("currentUser");
    let checkuser = localStorage.getItem("user_type");
    //console.log(checkuser+'==='+this.param); return false;
    if (checkuser != null)
      checkuser = checkuser.replace(/^"(.*)"$/, '$1');

    if (checkuser != 'users') {
      console.log('PLease LOGIN');
      let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
      el.click();
    } else {
      this.authenticationService.applyDiscountCode(this.discountcode).subscribe(
        response => {
          //if (response['status'] == 200) { 
          if (response['frontDiscountList'] !== {}) {
            this.dodisable = true;
            let value = response['frontDiscountList'].value;
            let distype = response['frontDiscountList'].type;
            setTimeout(() => this.toastr.success('Discount Code applied successfully!'));
            this.tempdata = Number(this.Price).toFixed(2);
            this.showfinal = true;
            //this.FinalPrice = this.Price;
            console.log(distype);

            if (distype == 'p') {
              this.showPdis = true;
              this.discountValue = value + ' % off';
              //console.log('here--'+distype);
              let newamout = value / 100 * this.Price;
              this.FinalPrice = this.Price - newamout;
              this.tempdata = this.Price - newamout;
              this.tempdata = Number(this.tempdata).toFixed(2);
              this.FinalPrice = Number(this.tempdata).toFixed(2);
              //this.Price = this.FinalPrice;
            } else {
              this.showMdis = true;
              this.discountValue = value;
              this.FinalPrice = this.Price - value;
              this.tempdata = this.Price - value;
              this.tempdata = Number(this.tempdata).toFixed(2);
              this.FinalPrice = Number(this.tempdata).toFixed(2);
              //this.Price = this.FinalPrice;
            }
            this.couponApplied = true;

          }
          //}
        }, error => {
          setTimeout(() => this.toastr.error(error.error.messsage));
          console.log(error.error.messsage);
        });
    }
    //console.log(this.discountcode);    

  }

  remove(d, i) {
    console.log(d + '-------' + i);

    this.items = JSON.parse(localStorage.getItem('items'));
    //return false;
    const index = this.items.indexOf(d);
    // console.log('indx is ' + index);
    this.items.splice(i, 1);
    this.authenticationService.items = this.items;
    // if(i==0){
    //   this.emptyData = true;   
    //   this.authenticationService.items.length = 0;
    // }
    localStorage.setItem('items', JSON.stringify(this.authenticationService.items));
    // console.log(this.items);
    this.countPrice();
    this.authenticationService.changeMessage(this.authenticationService.items.length);

    this.items = JSON.parse(localStorage.getItem('items'));
    // this.items = JSON.parse(localStorage.getItem('items'));
    // console.log(this.items);
    this.Price = 0;
    this.tempdata = 0;
    for (let p of this.items) {
      this.Price += p.price * p.quantity
      this.tempdata += p.price * p.quantity
    }
    console.log(this.discountcode);
    //this.applyDiscount();
    if (this.items.length == 0) {
      this.emptyData = true;
      this.authenticationService.items.length = 0;
    }
    this.authenticationService.removeItemfromOrder(d).subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];
          console.log(this.countries);

        }
      }, error => {
        console.log(error);
      });
  }
  getCountries() {
    this.authenticationService.getCountries().subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];
          console.log(this.countries);

        }
      }, error => {
        console.log(error);
      });
  }

  setDeliveryCharges(event) {
    if (event != '230') {
      this.ukshipping = false;
      this.internationalshipping = true;
    } else {
      this.ukshipping = true;
      this.internationalshipping = false;

    }
  }

  getShipping() {
    this.authenticationService.shippinglist().subscribe(
      response => {
        if (response['shippinglist'] !== {}) {
          this.currenciesList = response['shippinglist']['data'];
          console.log(this.currenciesList);

        }
      }, error => {
        console.log(error);
      });
  }

  toggleclass($event: any) {

    this.renderer.setElementClass(event.target, "active", false);
  }

  sitesettinglist() {
    this.authenticationService.sitesettinglist().subscribe(
      response => {
        if (response['status'] == 200) {
          if (response['siteSettingList'] !== {}) {
            this.sitedata = response['siteSettingList'];
            console.log(this.sitedata[0].basic_price);
            this.gold_price = Number(this.sitedata[0].gold_price);
            this.basic_price = this.sitedata[0].basic_price;

          }
        }
      }, error => {
        console.log(error);
      });
  }


  addtocart(plan) {
    console.log(plan);
    this.dodisable = true;
    this.modalRef.hide();
    let currency = {
      "code": "GBP",
      "country": "Britain (United Kingdom)",
      "created_at": "2019-03-07 04:45:57",
      "currency": "Pounds",
      "id": 63,
      "status": "1",
      "symbol": "£",
      "updated_at": "-0001-11-30 00:00:00"
    }
    this.petsData.forEach(element => {
      if (plan == 'basic') {
        // console.log('hereeeee--basic');
        this.newItem = {
          'product_id': 991,
          'name': 'Basic Plan',
          'quantity': '1',
          'price': this.petsData.length > 0 ? this.basic_price * this.petsData.length : this.basic_price,
          'image': '',
          'currency': currency,
          'pet_id': element.id
        };
        this.authenticationService.items.push(this.newItem);
      }
      if (plan == 'gold') {
        // console.log('hereeeee--gold');

        this.newItem = {
          'product_id': 992,
          'name': 'Gold Plan',
          'quantity': '1',
          'price': this.gold_price,
          'image': '',
          'currency': currency,
          'pet_id': element.id
        };
        this.authenticationService.items.push(this.newItem);
        // console.log(this.newItem);
      }


    });

    localStorage.setItem('items', JSON.stringify(this.authenticationService.items));
    this.items = JSON.parse(localStorage.getItem('items'));
    this.authenticationService.changeMessage(this.authenticationService.items.length);
    console.log('aa', this.newItem);
    console.log('vv', this.items);

    this.modalRef.hide();


    this.getcart();
  }

  getcart() {
    this.items = JSON.parse(localStorage.getItem('items'));
    console.log(this.items);
    this.user_account = localStorage.getItem("user_account");
    console.log('is it gold?------->' + this.user_account);
    if (this.items == null || this.items == "") {
      console.log("Cart is empty");
      this.emptyData = true;
    } else {
      this.useremail = localStorage.getItem('user_email');
      this.emptyData = false;
      this.countPrice();
      this.currencyType = this.items[0].symbol;
      this.currencyCode = this.items[0].currencyCode;
      this.currencyID = this.items[0].currencyID;

      this.items.forEach(element => {
        if (element.name == 'Gold Plan' || element.name == 'Basic Plan') {
          this.showdelivery = false;
          if (element.name == 'Gold Plan') {
            this.isgoldplan = true;
          }
        }
      });
    }
  }
}
