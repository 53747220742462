import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-petlist',
  templateUrl: './petlist.component.html',
  styleUrls: ['./petlist.component.scss']
})
export class PetlistComponent implements OnInit {

  addPetForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  showLoader: boolean = true;
  //data :any = [];
  modalRef: BsModalRef;
  response;
  loading: boolean = false;
  //data :any = [];
  @Input('data') data: string[] = [];
  users: Observable<string[]>;
  p: number = 1;
  total: number;
  userFilter: any = { name: '' };
  search: string = "";
  dateFormat;
  constructor(private cookieService: CookieService, private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }

  get r() { return this.addPetForm.controls; }

  ngOnInit() {
    this.dateFormat = localStorage.getItem("date_time_format");
    this.dateFormat = this.dateFormat.replace(/^"(.*)"$/, '$1');
    console.log(this.dateFormat);

    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addPetForm = this.formBuilder.group({
      petname: ['', Validators.required],
    });
    this.getPetlist(1);
  }
  getPetlist(page: number) {
    this.showLoader = true;
    this.authenticationService.getPetlist().subscribe(
      response => {
        this.showLoader = false;
        if (response['petList'] !== {}) {
          this.data = response['petList'].data;
          this.total = response['petList'].total;
          this.response = response['petList'];
          this.p = page;

        }
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }

  Edituser(id) {
    this.router.navigate(['admin/adduser'], { queryParams: { user_id: id } });
  }
  deletePet(id) {
    if (confirm("Are you sure you want to delete")) {
      this.authenticationService.deletePet(id).subscribe(
        response => {
          //this.getPetlist();
          if (response['status'] == 200) {
            this.showLoader = true;
            this.data = [];
            this.total = 0;
            this.p = 0;
            this.getPetlist(1);
            setTimeout(() => this.toastr.success('Pet deleted successfully!'))
          }
        }, error => {
          setTimeout(() => this.toastr.success(error.error.msg))
        });
    }
  }
  logout() {
    console.log('inlogout');
    this.nav.hide();
    this.authenticationService.logout();
  }

  addPet() {
    this.submitted = true;
    if (this.addPetForm.invalid) {
      return false;
    }
    var data = this.addPetForm.value;
    //return false;
    var myPostObject = {
      name: data.petname,

    }
    this.authenticationService.addPetname(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Pet type added successfully!'))
          this.addPetForm.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.getPetlist(1);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['petList'] !== {}) {
          this.data = response['petList'].data;
          this.total = response['petList'].total;
          this.p = page;

        }
      }, error => {
        console.log(error);
      });
  }

  editPet(id) {
    localStorage.setItem('edit_pet_id', id);
    this.router.navigate(['/admin/editpet']);
  }
  updateFilter() {
    if (this.search.trim() == "") {
      this.getPetlist(1);

    } else {
      console.log('in the f search');

      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchpets";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          this.showLoader = false;
          if (response['petList'] !== {}) {
            this.data = response['petList'].data;
            this.total = response['petList'].total;
            this.response = response['petList'];
            this.p = 0;

          }
        }, error => {
          console.log(error);
        });
    }
  }

}


