import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const now = new Date();
@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
  addDiscount: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  emptyData: boolean = false;
  param2;
  data: any = [];
  modalRef: BsModalRef;
  loading: boolean = false;
  showLoader: boolean = true;
  response;
  checkAction: string = 'add';
  p: number = 1;
  total: number;
  addshipping: string = 'Add Shipping';
  shippingtoupdate;
  search: string = "";
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  get r() { return this.addDiscount.controls; }
  createdDate: NgbDateStruct = {
    year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()
  };
  ngOnInit() {

    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addDiscount = this.formBuilder.group({
      type: ['', Validators.required],
      title: ['', Validators.required],
      cost: ['', Validators.compose([
        Validators.required,
        Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')
      ])],
      free_over: ['', [Validators.required, Validators.pattern('[0-9]+')]],
    });
    this.shippinglist(1);
  }

  getSingleShipping(id) {
    this.authenticationService.getSingleShipping(id).subscribe(
      response => {
        if (response['data'] !== {}) {
          this.checkAction = 'update';
          let sdata = response['data'];
          this.addshipping = 'Update Shippipng';
          this.addDiscount.patchValue({
            type: sdata.type,
            title: sdata.title,
            cost: sdata.cost,
            free_over: sdata.free_over,
          });

          this.shippingtoupdate = id;
        }
      }, error => {
        setTimeout(() => this.toastr.success(error.error.msg))
      });
  }

  shippinglist(page: number) {
    this.showLoader = true;
    this.authenticationService.shippinglist().subscribe(
      response => {
        this.showLoader = false;
        if (response['shippinglist'] !== {}) {
          this.data = response['shippinglist'].data;
          this.total = response['shippinglist'].total;
          this.p = page;
          this.response = response['shippinglist'].data;

        }
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }
  deleteDiscount(id) {
    if (confirm("Are you sure you want to delete shipping")) {
      this.authenticationService.deleteShipping(id).subscribe(
        response => {
          //this.getUsers();
          if (response['status'] == 200) {
            this.shippinglist(1);
            setTimeout(() => this.toastr.success('shipping deleted successfully!'))
          }
        }, error => {
          setTimeout(() => this.toastr.success(error.error.msg))
        });
    }
  }
  addnewShipping() {
    this.submitted = true;
    if (this.addDiscount.invalid) {
      return false;
    }
    var data = this.addDiscount.value;
    var myPostObject = {
      title: data.title,
      cost: data.cost,
      free_over: data.freeover,
    }
    if (this.checkAction == 'add') {
      this.authenticationService.addShipping(data)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('Shipping added successfully!'))
            this.addDiscount.reset();
            this.submitted = false;
            this.shippinglist(1);
            this.modalRef.hide();
          },
          error => {
            setTimeout(() => this.toastr.success(error.error.msg))
          });
    } else {
      this.authenticationService.updateShipping(data, this.shippingtoupdate)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('Shipping updated successfully!'))
            this.addDiscount.reset();
            this.submitted = false;
            this.shippinglist(1);
            this.modalRef.hide();
          },
          error => {
            setTimeout(() => this.toastr.success(error.error.msg))
          });
    }

  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['shippinglist'] !== {}) {
          this.data = response['shippinglist'].data;
          this.total = response['shippinglist'].total;
          this.p = page;
          this.response = response['shippinglist'];

        }
      }, error => {
        setTimeout(() => this.toastr.success(error.error.msg))
      });
  }
  closePopup(modalRef) {
    this.addDiscount.reset();
    this.submitted = false;
    modalRef.hide();
  }
  updateFilter() {
    if (this.search.trim() == "") {
      this.shippinglist(1);

    } else {
      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchshipping";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          this.showLoader = false;
          if (response['shippinglist'] !== {}) {
            this.data = response['shippinglist'].data;
            this.total = response['shippinglist'].total;
            this.response = response['shippinglist'];
            this.p = 0;

          }
        }, error => {
          setTimeout(() => this.toastr.success(error.error.msg))
        });
    }
  }
}

