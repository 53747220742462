import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { AdduserComponent } from './adduser/adduser.component';
import { PetTypesComponent } from './pet-types/pet-types.component';
import { ModalModule, BsModalRef } from 'ngx-bootstrap';
import { VendorsComponent } from './vendors/vendors.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { ProductsComponent } from './products/products.component';
import { ViewusersComponent } from './viewusers/viewusers.component';
import { ChipsetsComponent } from './chipsets/chipsets.component';
import { LostfoundComponent } from './lostfound/lostfound.component';
import { OrdersComponent } from './orders/orders.component';
import { ApproveusersComponent } from './approveusers/approveusers.component';
import { PetstatsComponent } from './petstats/petstats.component';
import { ChangerequestComponent } from './changerequest/changerequest.component';
import { PetlistComponent } from './petlist/petlist.component';
import { DiscountcodesComponent } from './discountcodes/discountcodes.component';
import { SitesettingsComponent } from './sitesettings/sitesettings.component';
import { EditpetComponent } from './editpet/editpet.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AdminlayoutComponent } from './adminlayout/adminlayout.component';
import { HomelayoutComponent } from './homelayout/homelayout.component';
import { HomeheaderComponent } from './homeheader/homeheader.component';
import { HomefooterComponent } from './homefooter/homefooter.component';
import { ViewproductsComponent } from './viewproducts/viewproducts.component';
import { AboutMicroComponent } from './about-micro/about-micro.component';
import { ChangeOwnerComponent } from './change-owner/change-owner.component';
import { ContactusComponent } from './contactus/contactus.component';
import { FaqComponent } from './faq/faq.component';
import { RegisterComponent } from './register/register.component';
import { AddpetComponent } from './addpet/addpet.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { AccountdetailsComponent } from './accountdetails/accountdetails.component';
import { MypetsComponent } from './mypets/mypets.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BenefitsComponent } from './benefits/benefits.component';
import { MymicrochipsComponent } from './mymicrochips/mymicrochips.component';
import { OrderhistoryComponent } from './orderhistory/orderhistory.component';
import { MydocumentsComponent } from './mydocuments/mydocuments.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { TransferhistoryComponent } from './transferhistory/transferhistory.component';
import { CartComponent } from './cart/cart.component';
import { AddproductComponent } from './addproduct/addproduct.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NewpetComponent } from './newpet/newpet.component';
import { AddchipsetComponent } from './addchipset/addchipset.component';
import { CookieService } from 'ngx-cookie-service';

import { SingleproductComponent } from './singleproduct/singleproduct.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { CKEditorModule } from 'ng2-ckeditor';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ShippingComponent } from './shipping/shipping.component';
import { OwlModule } from 'ngx-owl-carousel';
import { ChipsComponent } from './chips/chips.component';
import { AddChipsComponent } from './add-chips/add-chips.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LoaderComponent } from './loader/loader.component';
import { AddfaqComponent } from './addfaq/addfaq.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { SearchArrayPipe } from './pipes/search-array.pipe';
import { TermsComponent } from './terms/terms.component';
import { LimitToPipePipe } from './pipes/limit-to-pipe.pipe';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    AdduserComponent,
    PetTypesComponent,
    VendorsComponent,
    ProductCategoryComponent,
    ProductsComponent,
    ViewusersComponent,
    ChipsetsComponent,
    LostfoundComponent,
    OrdersComponent,
    ApproveusersComponent,
    PetstatsComponent,
    ChangerequestComponent,
    PetlistComponent,
    DiscountcodesComponent,
    SitesettingsComponent,
    EditpetComponent,
    CreateOrderComponent,
    HomepageComponent,
    AdminlayoutComponent,
    HomelayoutComponent,
    HomeheaderComponent,
    HomefooterComponent,
    ViewproductsComponent,
    AboutMicroComponent,
    ChangeOwnerComponent,
    ContactusComponent,
    FaqComponent,
    RegisterComponent,
    AddpetComponent,
    MyaccountComponent,
    AccountdetailsComponent,
    MypetsComponent,
    BenefitsComponent,
    MymicrochipsComponent,
    OrderhistoryComponent,
    MydocumentsComponent,
    ChangepasswordComponent,
    TransferhistoryComponent,
    CartComponent,
    AddproductComponent,
    NewpetComponent,
    AddchipsetComponent,
    SingleproductComponent,
    ForgotPasswordComponent,
    ShippingComponent,
    ChipsComponent,
    AddChipsComponent,
    LoaderComponent,
    AddfaqComponent,
    SearchArrayPipe,
    TermsComponent,
    LimitToPipePipe,


  ],
  imports: [
    OwlModule,
    SlideshowModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    CKEditorModule,
    UiSwitchModule,
    SelectDropDownModule
  ],
  exports: [
    LoaderComponent
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }