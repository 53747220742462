import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { AdduserComponent } from './adduser/adduser.component';
import { PetTypesComponent } from './pet-types/pet-types.component';
import { VendorsComponent } from './vendors/vendors.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { ProductsComponent } from './products/products.component';
import { ViewusersComponent } from './viewusers/viewusers.component';
import { ChipsetsComponent } from './chipsets/chipsets.component';
import { LostfoundComponent } from './lostfound/lostfound.component';
import { OrdersComponent } from './orders/orders.component';
import { ApproveusersComponent } from './approveusers/approveusers.component';
import { PetstatsComponent } from './petstats/petstats.component';
import { ChangerequestComponent } from './changerequest/changerequest.component';
import { PetlistComponent } from './petlist/petlist.component';
import { DiscountcodesComponent } from './discountcodes/discountcodes.component';
import { SitesettingsComponent } from './sitesettings/sitesettings.component';
import { EditpetComponent } from './editpet/editpet.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AdminlayoutComponent } from './adminlayout/adminlayout.component';
import { HomelayoutComponent } from './homelayout/homelayout.component';
import { ViewproductsComponent } from './viewproducts/viewproducts.component';
import { AboutMicroComponent } from './about-micro/about-micro.component';
import { ChangeOwnerComponent } from './change-owner/change-owner.component';
import { ContactusComponent } from './contactus/contactus.component';
import { FaqComponent } from './faq/faq.component';
import { RegisterComponent } from './register/register.component';
import { AddpetComponent } from './addpet/addpet.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { AccountdetailsComponent } from './accountdetails/accountdetails.component';
import { MypetsComponent } from './mypets/mypets.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { MymicrochipsComponent } from './mymicrochips/mymicrochips.component';
import { OrderhistoryComponent } from './orderhistory/orderhistory.component';
import { MydocumentsComponent } from './mydocuments/mydocuments.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { TransferhistoryComponent } from './transferhistory/transferhistory.component';
import { CartComponent } from './cart/cart.component';
import { AddproductComponent } from './addproduct/addproduct.component';
import { NewpetComponent } from './newpet/newpet.component';
import { AddchipsetComponent } from './addchipset/addchipset.component';
import { SingleproductComponent } from './singleproduct/singleproduct.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ShippingComponent } from './shipping/shipping.component';
import { ChipsComponent } from './chips/chips.component';
import { AddChipsComponent } from './add-chips/add-chips.component';
import { LoaderComponent } from './loader/loader.component';
import { AddfaqComponent } from './addfaq/addfaq.component';
import { TermsComponent } from './terms/terms.component';
const routes: Routes = [

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  // { path: 'loader', component: LoaderComponent},
  // { path: 'admin', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'admin', component: AdminlayoutComponent,
    children: [

      { path: 'adduser', component: AdduserComponent },
      { path: 'pettypes', component: PetTypesComponent },
      { path: 'vendors', component: VendorsComponent },
      { path: 'category', component: ProductCategoryComponent },
      { path: 'viewproducts', component: ProductsComponent },
      { path: 'viewusers', component: ViewusersComponent },
      { path: 'chipsets', component: ChipsetsComponent },
      { path: 'add_chip', component: AddChipsComponent },
      { path: 'chips', component: ChipsComponent },
      { path: 'lostfound', component: LostfoundComponent },
      { path: 'orders', component: OrdersComponent },
      { path: 'approveusers', component: ApproveusersComponent },
      { path: 'petstats', component: PetstatsComponent },
      { path: 'change_request', component: ChangerequestComponent },
      { path: 'petlist', component: PetlistComponent },
      { path: 'discountcodes', component: DiscountcodesComponent },
      { path: 'sitesettings', component: SitesettingsComponent },
      { path: 'editpet', component: EditpetComponent },
      { path: 'createOrder', component: CreateOrderComponent },
      { path: 'addpet', component: AddpetComponent },
      { path: 'add_product', component: AddproductComponent },
      { path: 'add_chipset', component: AddchipsetComponent },
      { path: 'shipping', component: ShippingComponent },
      { path: 'addfaq', component: AddfaqComponent },


    ]
  },
  // { path: 'home', component: HomepageComponent},
  { path: 'login', component: LoginComponent },


  {
    path: 'home', component: HomelayoutComponent,
    children: [
      { path: '', component: HomepageComponent },
      { path: 'products', component: ViewproductsComponent },
      { path: 'about-microchipping', component: AboutMicroComponent },
      { path: 'change-owner', component: ChangeOwnerComponent },
      { path: 'contactus', component: ContactusComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'myaccount', component: MyaccountComponent },
      { path: 'mydetails', component: AccountdetailsComponent },
      { path: 'mypets', component: MypetsComponent },
      { path: 'benefits', component: BenefitsComponent },
      { path: 'mymicrochips', component: MymicrochipsComponent },
      { path: 'orderhistory', component: OrderhistoryComponent },
      { path: 'mydocuments', component: MydocumentsComponent },
      { path: 'change_password', component: ChangepasswordComponent },
      { path: 'transfer_history', component: TransferhistoryComponent },
      { path: 'cart', component: CartComponent },
      { path: 'newpet', component: NewpetComponent },
      { path: 'viewproduct', component: SingleproductComponent },
      { path: 'forgotPassword', component: ForgotPasswordComponent }


    ]
  },
];
// RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes),
  RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
