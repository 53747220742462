import { Component, OnInit,TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const now = new Date();
@Component({
	selector: 'app-addfaq',
	templateUrl: './addfaq.component.html',
	styleUrls: ['./addfaq.component.scss']
})
export class AddfaqComponent implements OnInit {
	addFaq: FormGroup;
	submitted: boolean = false;
	usersubmitted: boolean = false;
	loginsubmitted: boolean = false;
	invalidLogin: boolean = false;
	emptyData:boolean=false;
	param2;
	data :any = [];
	modalRef: BsModalRef;
	pagetitle;
	expiry_date;
	dueDate_array;
	loading:boolean=false;
	checkAction:string='add';
	showLoader:boolean=true;
	addfaq: string = 'Add Discount';
	faqtoupdate;
	response;
	p: number = 1;
	total: number;   

	dateFormat; 
	search:string = "";
	constructor(private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template,{ backdrop: 'static', keyboard: false });
	}
	get r() { return this.addFaq.controls; }
	createdDate: NgbDateStruct = {
		year: now.getFullYear(), month: now.getMonth() +1, day: now.getDate()
	};    
	ngOnInit() {
		this.pagetitle= 'Create Faq';
		this.param2= localStorage.getItem("currentUser");
		this.dateFormat = localStorage.getItem("date_time_format");
		this.dateFormat = this.dateFormat.replace(/^"(.*)"$/, '$1');
		if(this.param2 == null){
			console.log('in null');
			this.router.navigate(['/login']);
		}else{
			this.nav.show();
		}

		this.addFaq = this.formBuilder.group({
			title: ['', Validators.required],
			question: ['', Validators.required],
			answere: ['', Validators.required],
			position: ['', Validators.required],
			video_link: ['' ],
			image: [''],
			active : ['',Validators.required]
		});   
		this.faqlist(1);
	}

	faqlist(page:number) { 
		this.showLoader=true;
		this.authenticationService.faqlist().subscribe(
			response => {
				this.showLoader=false;
				if (response['faqList'] !== {}) {
					this.data = response['faqList'];
					this.total=response['faqList'].total;
					this.response=response['faqList'];
					this.p=page;
					console.log(this.data);    
				}
			}, error => {
				console.log(error);
			});
	}
	deleteFaq(id){
		if (confirm("Are you sure you want to delete faq")) {
			this.authenticationService.deleteFaq(id).subscribe(
				response => {
					//this.getUsers();
					if (response['status'] == 200) { 
						console.log('success');
						this.faqlist(1);
						setTimeout(() => this.toastr.success('Faq deleted successfully!'))
					}
				}, error => {
					console.log('errorrrrr');

					setTimeout(() => this.toastr.success('Something went wrong'))
				});    
		}
	}
	addnewFaq(){


		this.submitted = true;
		if (this.addFaq.invalid) {
			return false;
		}
		var data = this.addFaq.value;

		console.log(data);

		var myPostObject = {
			title:data.title,
			question:data.question,
			answere:data.answere,
			video_link:data.video_link,
			position:data.position,
			active:data.active,

		}   
		if(this.checkAction == 'add'){
			this.authenticationService.addFaq(myPostObject)
			.pipe(first())
			.subscribe(
				data => {        
					console.log(data);
					//this.saveNotification.reset
					setTimeout(() => this.toastr.success('Faq added successfully!'))
					this.addFaq.reset();
					this.submitted = false;
					this.faqlist(1);
					this.modalRef.hide();

					//this.router.navigate(['/admin/discountcodes']);

				},
				error => {
					setTimeout(() => this.toastr.error('Something went wrong!'))
					//   this.error = error;
					//   this.loading = false;
				}); 
		}else{

			this.authenticationService.updateFaq(myPostObject,this.faqtoupdate)
			.pipe(first())
			.subscribe(
				data => {        
					setTimeout(() => this.toastr.success('Discount updated successfully!'))
					this.addFaq.reset();
					this.submitted = false;
					this.faqlist(1);
					this.modalRef.hide();
				},
				error => {
					setTimeout(() => this.toastr.error('Something went wrong!'))
				}); 


		}   
	}

	getSingleFaq(id){
		this.pagetitle = "Update Discount"
		//console.log(id);
		this.authenticationService.getSingleFaq(id).subscribe(
			response => {

				console.log(response['faq']);
				if (response['faq'] !== {}) {
					this.checkAction = 'update';
					console.log(response['faq']);
					let sdata = response['faq'];
					console.log(sdata);
					this.addfaq = 'Update Discount';


					this.addFaq = this.formBuilder.group({
						title: [sdata.title, Validators.required],
						question: [sdata.question, Validators.required],
						answere: [sdata.answere, Validators.required],
						video_link: [sdata.video_link],
						position : [sdata.position],
						active : [sdata.active, Validators.required],

					});           

					this.faqtoupdate = id;
					//this.shippinglist(1);
					//setTimeout(() => this.toastr.success('shipping fetched successfully!'))
				}
			}, error => {
				setTimeout(() => this.toastr.success('Something went wrong'))
			});    
	}

	getPage(page: number) {
		this.loading = true;
		let url = this.response.first_page_url.split("page=");
		url= url[0]+"&page="+page;   
		this.authenticationService.getalluserspagination(url).subscribe(
			response => {
				if (response['discountList'] !== {}) {
					this.data = response['discountList'].data;
					this.total=response['discountList'].total;
					this.p=page;
					console.log(this.data);

				}
			}, error => {
				console.log(error);
			});    
	}  
	closePopup(modalRef){
		this.addFaq.reset();
		this.submitted = false;
		modalRef.hide();
	} 
	updateFilter(){
		if(this.search.trim() == ""){
			this.faqlist(1);

		}else{
			this.showLoader = true;
			this.data = [];
			this.total=0;
			this.p=0;
			let api = "searchdiscount";
			this.authenticationService.searchByQueryString(api,this.search).subscribe(
				response => {
					this.showLoader = false;
					if (response['discountList'] !== {}) {
						this.data = response['discountList'].data;
						this.total=response['discountList'].total;
						this.response=response['discountList'];
						this.p=0;  

					}
				}, error => {
					console.log(error);
				}); 
		}
	}
}

