import { Component, OnInit,TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['../../assets/css/style.css','../../assets/css/responsive.css']
})
export class MyaccountComponent implements OnInit {
  param;
  user;
  loggedin:boolean = false;

  constructor(private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }

  ngOnInit() {
    this.param= localStorage.getItem("currentUser");
    this.user= localStorage.getItem("user_type");
    if(this.user != '"users"'){
      this.authenticationService.loggedOut();
      this.router.navigate(['']);
    }
  }
 
  logout(){

    setTimeout(() => this.toastr.success('Logged Out Successfully')
            );
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user_type');
    localStorage.removeItem('userid');
    this.authenticationService.loggedOut();
    this.loggedin = false;

    
  }
}
