import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-editpet',
  templateUrl: './editpet.component.html',
  styleUrls: ['./editpet.component.scss']
})
export class EditpetComponent implements OnInit {

  editPetForm: FormGroup;
  editPetOwnerForm: FormGroup;
  editChipOwnerForm: FormGroup;
  adminnotesForm: FormGroup;
  holidayAddressForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  submittedholiday: boolean = false;
  showLoader: boolean = true;
  submittednotes: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  history;
  date_injectedDate;
  emptyhistory;
  countries: any = [];
  states: any = [];
  cities: any = [];
  submittedOwner: boolean = false;
  isDataLoaded: boolean = false;
  currentuser_id;
  @ViewChild('myDiv3') myDiv3: ElementRef;
  pdfurl;
  config = {
    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 20
  };

  data: any = [];
  users: any = [];
  userEmails: any = [];
  pet_types: any = [];
  dueDate_array;
  date_injected_array;
  modalRef: BsModalRef;
  petid;
  anDate;
  dobdate;
  userData: any = [];
  selectedDatasource;
  singleSelect: any = [];
  multiSelect: any = [];
  stringArray: any = [];
  objectsArray: any = [];
  options: any = [];
  chip_no;
  current_email;
  //options2 = [];
  constructor(private cookieService: CookieService, config: NgbTabsetConfig, private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }

  get r() { return this.editPetForm.controls; }
  get o() { return this.editPetOwnerForm.controls; }
  get c() { return this.editChipOwnerForm.controls; }
  get h() { return this.holidayAddressForm.controls; }
  stringOptions = [
    'Burns Dalton', 'Mcintyre Lawson', 'Amie Franklin', 'Jocelyn Horton', 'Fischer Erickson', 'Medina Underwood', 'Goldie Barber'
  ];
  searchChange($event) {
    console.log($event.value);
    this.currentuser_id = $event.value.id;
    this.current_email = $event.value.name;
    this.editPetForm.patchValue({ email: $event.value.name });
  }
  ngOnInit() {


    this.isDataLoaded = true;
    this.petid = localStorage.getItem("edit_pet_id");
    this.chip_no = localStorage.getItem("edit_chip_number");

    console.log('inside chip', this.chip_no);
    console.log('inside petid', this.petid);
    this.editPetForm = this.formBuilder.group({
      user: [''],
      description: [''],
      chip_no: [this.chip_no, [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(15),
      ]],
      date_injected: [''],
      email: [''],
      phone: [''],
      status: [''],

      pet_type: ['', Validators.required],
      sex: ['', Validators.required],
      pet_breed: ['', Validators.required],

      pet_name: [''],
      pet_medical_info: [''],

      petdob: ['', Validators.required],
      gold: ['', Validators.required],
    });
    this.editChipOwnerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: ['', Validators.required],
      is_vet: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      login_name: ['', [Validators.required, Validators.email]],

      charity_name: ['', ''],
      charity_no: [''],
      approve_type: [''],
      street1: ['', Validators.required],
      street2: ['', Validators.required],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      passcode: ['', ''],
      trained_with: [''],
      implanter_code: ['']
    });
    if (this.petid != null) {
      this.getallusers();
      if (this.petid != 0) {
        this.getpetinfo(this.petid);
      } else {
        this.getpetinfo_by_chipno(this.chip_no);
      }
      this.getchipdetails(this.chip_no);
      this.getPetTypes();
      this.gettransferhistory(this.chip_no);
    } else {
      console.log('in null petid');

    }
    this.getCountries();
    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }



    this.editPetOwnerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],


      email: ['', [Validators.required, Validators.email]],
      login_name: ['', [Validators.required, Validators.email]],
      approve_type: [''],

      charity_name: [''],
      charity_no: [''],
      street1: [''],
      street2: [''],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: [''],
      postcode: ['', Validators.required],
      phone: ['', Validators.required],
      passcode: [''],
      is_vet: [''],
      implanter_code: [''],
      trained_with: [''],

    });


    this.holidayAddressForm = this.formBuilder.group({

      street1: ['', Validators.required],
      street2: ['', Validators.required],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],


    });
    this.adminnotesForm = this.formBuilder.group({


      admin_notes: ['', Validators.required],


    });





  }

  getStates(id) {
    this.authenticationService.getStates(id).subscribe(
      response => {
        console.log(response);
        if (response['stateList'] !== {}) {
          this.states = response['stateList'];
        }
      }, error => {
        console.log(error);
      });
  }
  getCities(id) {
    this.authenticationService.getCities(id).subscribe(
      response => {
        if (response['CitiesList'] !== {}) {
          this.cities = response['CitiesList'];
        }
      }, error => {
        console.log(error);
      });
  }
  getCountries() {
    this.authenticationService.getCountries().subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];
          //console.log(this.countries);

        }
      }, error => {
        console.log(error);
      });
  }
  searchChangeData(data) {
    console.log('take', data);
    this.getallusers(data);
  }
  getallusers(term = "") {
    this.showLoader = true;
    let postData = {
      term: term
    };
    this.authenticationService.userslist(postData).subscribe(
      response => {
        if (response['userList'] !== {}) {
          this.users = response['userList'];
          this.options = [];
          this.users.forEach(element => {
            //this.options = [...this.options, { 'name': element.firstname + ' ' + element.lastname + ' (' + element.email + ')', 'id': element.id }];
            this.options = [...this.options, { 'id': element.id, 'name': element.email }];
            setTimeout(function () {
              if (document.getElementsByName("search-text").length > 0) {
                document.getElementsByName("search-text")[0]['value'] = term;
              }
            }, 10);
          });
          this.showLoader = false;
        }
      }, error => {
        console.log(error);
      });
  }
  getPetTypes() {
    this.authenticationService.getallpettypes().subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['petTypeList'] !== {}) {
          this.pet_types = response['petTypeList'];

        }
        //}
      }, error => {
        console.log(error);
      });
  }
  getchipdetails(chip_no) {
    this.showLoader = true;
    this.authenticationService.getchipdetails(chip_no).subscribe(
      response => {
        this.showLoader = false;
        //console.log('insidee', response['data'].data[0].user);

        if (response['data'].data[0] != {}) {
          let GetResponse = response['data'].data[0];
          let data = GetResponse.chipset.user;
          let udata = response['data'].data[0].chipset.user_addresses;
          let edata = response['data'].data[0].chipset.user_extras;

          console.log(data);
          this.getStates(udata.country);
          this.getCities(udata.county);
          this.editChipOwnerForm.patchValue({ firstname: data.firstname });
          this.editChipOwnerForm.patchValue({ lastname: data.lastname });
          this.editChipOwnerForm.patchValue({ email: data.email });
          this.editChipOwnerForm.patchValue({ login_name: data.login_name });
          this.editChipOwnerForm.patchValue({ passcode: data.bypass_code });
          this.editChipOwnerForm.patchValue({ approve_type: data.approve_type });
          this.editChipOwnerForm.patchValue({ charity_name: data.charity_name ? data.charity_name : '' });
          this.editChipOwnerForm.patchValue({ charity_no: data.charity_no ? data.charity_no : '' });
          this.editChipOwnerForm.patchValue({ implanter_code: data.implanter_code ? data.implanter_code : '' });
          this.editChipOwnerForm.patchValue({ trained_with: data.implanter_trained_with ? data.implanter_trained_with : '' });

          this.editChipOwnerForm.patchValue({ street1: udata.street1 ? udata.street1 : '' });
          this.editChipOwnerForm.patchValue({ Country: udata.country ? udata.country : '' });
          this.editChipOwnerForm.patchValue({ county: udata.county ? udata.county : '' });
          this.editChipOwnerForm.patchValue({ city: udata.city ? udata.city : '' });
          this.editChipOwnerForm.patchValue({ street2: udata.street2 ? udata.street2 : '' });
          this.editChipOwnerForm.patchValue({ postcode: udata.postcode ? udata.postcode : '' });
          this.editChipOwnerForm.patchValue({ phone: edata.phone ? edata.phone : '' });
          this.showLoader = false;
        }
      }, error => {
        console.log(error);
      });
  }



  getpetinfo(id) {
    this.showLoader = true;
    this.authenticationService.getPetinfo(id).subscribe(
      response => {
        this.showLoader = false;
        if (response['data'] !== {}) {
          let data = response['data'];
          console.log(data);
          if (this.chip_no == null) {
            this.chip_no = data.chip_no;
            this.getchipdetails(this.chip_no);
          }
          if (data.dob != null) {
            this.dueDate_array = data.dob.split('-');
            let dyear = this.dueDate_array[0];
            let dmonth = this.dueDate_array[1];
            let ddate = this.dueDate_array[2];

            this.dobdate = { year: parseInt(dyear), month: parseInt(dmonth), day: parseInt(ddate) };
          }


          if (data.date_injected != null) {
            this.date_injected_array = data.date_injected.split('-');

            let ddyear = this.date_injected_array[0];
            let ddmonth = this.date_injected_array[1];
            let dddate = this.date_injected_array[2];

            this.date_injectedDate = { year: parseInt(ddyear), month: parseInt(ddmonth), day: parseInt(dddate) };
          } else {
            this.date_injectedDate = '';
          }

          this.selectedDatasource = data.user.login_name;

          this.editPetForm.patchValue({

            user: data.user.login_name.replace(/ /g, ""),
            description: data.description,
            status: data.status,
            // status: data.level == '2' ? 1 : 0,
            chip_no: data.chip_no ? data.chip_no : this.chip_no,
            date_injected: this.date_injectedDate,

            pet_type: data.pet_type,
            sex: data.sex,
            pet_breed: data.breed,
            pet_color: data.colour,
            pet_name: data.name,
            pet_medical_info: data.medical_info,
            for_breeding: data.for_breeding,
            petdob: this.dobdate,
            gold: data.gold,
          });

          this.currentuser_id = data.user_id;
          console.log('petowner=', data.user_id.replace(/ /g, ""));

          let petowner = data.user;
          let petownerAddress = data.user_addresses;
          let petownerExtras = data.user_extras;
          let user_holiday_address = data.user_holiday_address;

          this.getStates(petownerAddress.country);
          this.getCities(petownerAddress.county);

          this.editPetOwnerForm.patchValue({
            firstname: petowner.firstname,
            lastname: petowner.lastname,
            email: petowner.email,
            approve_type: petowner.approve_type,
            login_name: petowner.email,
            Country: petownerAddress.country,
            county: petownerAddress.county,
            city: petownerAddress.city,
            postcode: petownerAddress.postcode,
            phone: petownerExtras.phone,
            street1: petownerAddress.street1,
            street2: petownerAddress.street2,
          });
          this.adminnotesForm = this.formBuilder.group({
            admin_notes: [data.admin_notes, Validators.required],
          });
          if (user_holiday_address != null) {
            this.holidayAddressForm.patchValue({
              street1: user_holiday_address.street1,
              street2: user_holiday_address.street2,
              Country: user_holiday_address.country,
              county: user_holiday_address.county,
              city: user_holiday_address.city,
              postcode: user_holiday_address.postcode,
            });
          }
        }
      }, error => {
        console.log(error);
      });
  }

  getpetinfo_by_chipno(chipno) {
    this.showLoader = true;
    this.authenticationService.getpetinfo_by_chipno(chipno).subscribe(
      response => {
        this.showLoader = false;
        if (response['data'] !== {}) {
          let data = response['data'];
          console.log(data);
          if (this.chip_no == null) {
            this.chip_no = data.chip_no;
            this.getchipdetails(this.chip_no);
          }

          this.dueDate_array = data.dob.split('-');
          let dyear = this.dueDate_array[0];
          let dmonth = this.dueDate_array[1];
          let ddate = this.dueDate_array[2];

          this.dobdate = { year: parseInt(dyear), month: parseInt(dmonth), day: parseInt(ddate) };

          if (data.date_injected != null) {
            this.date_injected_array = data.date_injected.split('-');

            let ddyear = this.date_injected_array[0];
            let ddmonth = this.date_injected_array[1];
            let dddate = this.date_injected_array[2];

            this.date_injectedDate = { year: parseInt(ddyear), month: parseInt(ddmonth), day: parseInt(dddate) };
          }

          this.selectedDatasource = data.user.login_name;

          this.editPetForm.patchValue({

            user: data.user.login_name.replace(/ /g, ""),
            description: data.description,
            status: data.status,
            chip_no: data.chip_no ? data.chip_no : this.chip_no,
            date_injected: this.date_injectedDate,

            pet_type: data.pet_type,
            sex: data.sex,
            pet_breed: data.breed,
            pet_color: data.colour,
            pet_name: data.name,
            pet_medical_info: data.medical_info,
            for_breeding: data.for_breeding,
            petdob: this.dobdate,
            gold: data.gold,
          });

          this.currentuser_id = data.user_id;
          console.log('petowner=', data.user_id.replace(/ /g, ""));

          let petowner = data.user;
          let petownerAddress = data.user_addresses;
          let petownerExtras = data.user_extras;

          this.getStates(petownerAddress.country);
          this.getCities(petownerAddress.county);

          this.editPetOwnerForm.patchValue({
            firstname: petowner.firstname,
            lastname: petowner.lastname,
            email: petowner.email,
            approve_type: petowner.approve_type,
            login_name: petowner.email,
            Country: petownerAddress.country,
            county: petownerAddress.county,
            city: petownerAddress.city,
            postcode: petownerAddress.postcode,
            phone: petownerExtras.phone,
            street1: petownerAddress.street1,
            street2: petownerAddress.street2,
          });
          this.adminnotesForm = this.formBuilder.group({
            admin_notes: [data.admin_notes, Validators.required],
          });
        }
      }, error => {
        console.log(error);
      });
  }

  openpdf(petid) {
    this.authenticationService.openpetcertificate(petid).subscribe(
      response => {
        if (response !== {}) {
          this.pdfurl = response['data'][0].fileurl;
          console.log(response['data'][0].fileurl);
          setTimeout(() => {
            let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
            el3.click();
            console.log('inside');

          }, 1000);
        } else {
          //this.emptydocs = true;
        }
      }, error => {
        console.log(error);
      });
  }


  onUpdateOwner() {
    console.log('called');

    this.submittedOwner = true;
    if (this.editPetOwnerForm.invalid) {
      console.log(this.editPetOwnerForm);

      return false;
    }
    var data = this.editPetOwnerForm.value;
    console.log(data);
    var myPostObject = {
      // identification: data.identification,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      login_name: data.login_name,
      phone: data.phone,
      is_vet: data.is_vet,
      country: data.Country,
      county: data.county,
      city: data.city,
      charity_name: data.charity_name,
      charity_number: data.charity_no,
      implanter_code: data.implanter_code,
      implanter_trained_with: data.trained_with,
      postcode: data.postcode,
      approve_type: data.approve_type,
      street1: data.street1,
      street2: data.street2,
      bypass_code: data.passcode,
      petid: this.petid,
      petname: this.editPetForm.value.pet_name

    }
    this.authenticationService.updateUser(myPostObject, this.currentuser_id)
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => this.toastr.success('User updated successfully!'))
          //  this.registerForm.reset();
          this.submitted = false;
          this.router.navigate(['admin/petlist']);
        },
        error => {
          setTimeout(() => this.toastr.error(error.error.msg))
        });



  }


  notesForm() {
    this.submittednotes = true;
    if (this.adminnotesForm.invalid) {
      return false;
    }
    var data = this.adminnotesForm.value;
    console.log(data);
    var myNotesObject = {
      admin_notes: data.admin_notes,
    }
    this.authenticationService.updatePetinfo(myNotesObject, this.petid)
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => this.toastr.success('User updated successfully!'))
          //  this.registerForm.reset();
          // this.submitted = false;
          // this.router.navigate(['admin/petlist']);
        },
        error => {
          setTimeout(() => this.toastr.error(error.error.msg))
        });
  }


  addholidayAddress() {
    this.submittedholiday = true;
    if (this.holidayAddressForm.invalid) {

      return false;
    }
    var data = this.holidayAddressForm.value;


    console.log(data);
    var myHolidayObject = {

      country: data.Country,
      county: data.county,
      city: data.city,
      street1: data.street1,
      street2: data.street2,
      postcode: data.postcode,

    }

    this.authenticationService.addholidayaddress(myHolidayObject, this.currentuser_id)
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => this.toastr.success('User updated successfully!'))
          //  this.registerForm.reset();
          // this.submitted = false;
          // this.router.navigate(['admin/petlist']);
        },
        error => {
          setTimeout(() => this.toastr.error(error.error.msg))
        });
  }

  gettransferhistory(chipno) {
    this.showLoader = true;
    this.authenticationService.getAdminTransferHistroy(this.petid, chipno).subscribe(
      response => {
        this.showLoader = false;

        if (response['transferAdminHistory'].length != 0) {
          this.history = response['transferAdminHistory'];
          console.log(this.history);

          //console.log("hunny");
          //console.log(this.history);
          let totalhistory = response['transferAdminHistory'].length;
          //console.log(totalhistory);
          if (totalhistory == 0) {
            this.emptyhistory = true;
          } else {
            this.emptyhistory = false;
          }
        } else {
          //console.log('i am getting empty chips');

        }

      }, error => {
        console.log(error);
      });
  }

  updatePet() {

    this.submitted = true;
    // if (this.editPetForm.invalid) {

    //   return false;
    // }
    var data = this.editPetForm.value;
    var data2 = this.editPetOwnerForm.value;
    //return false;
    console.log(data);
    let date_injected = '';
    let petdob = data.petdob.year + '-' + data.petdob.month + '-' + data.petdob.day;
    if (data.date_injected != null) {
      date_injected = data.date_injected.year + '-' + data.date_injected.month + '-' + data.date_injected.day;
    }
    if (data.date_injected.year == '0' || data.date_injected.year == undefined) {
      date_injected = '';
    }


    var myPostObject = {

      description: data.description,
      status: data.status,
      name: data.pet_name,
      breed: data.pet_breed,
      colour: data.pet_color,
      dob: petdob,
      sex: data.sex,
      medical_info: data.pet_medical_info,
      pet_type: data.pet_type,
      user_id: this.currentuser_id,
      chip_no: data.chip_no,
      date_injected: date_injected,
      for_breeding: data.for_breeding,
      got_from: data.for_breeding,
      neutered: 'no',
      gold: data.gold,
      email: this.current_email ? this.current_email : data2.email,

      // firstname: data2.firstname,
      // lastname: data2.lastname,
      // email: this.current_email,
      // phone: data2.phone,
      // country: data2.Country,
      // county: data2.county,
      // city: data2.city,
      // postcode: data2.postcode,
      // street1: data2.street1,
      // street2: data2.street2,

    }
    console.log(myPostObject);
    this.authenticationService.updatePetinfo(myPostObject, this.petid)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Pet updated successfully!'))
          this.editPetForm.reset();
          this.submitted = false;
          this.router.navigate(['/admin/petlist']);
        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }


}
