import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PetTypesComponent } from '../pet-types/pet-types.component';

const now = new Date();
@Component({
  selector: 'app-addpet',
  templateUrl: './addpet.component.html',
  styleUrls: ['./addpet.component.scss']
})
export class AddpetComponent implements OnInit {
  addPetForm: FormGroup;
  param2;
  users: any = [];
  pet_types: any = [];
  options: any = [];
  chipnumb;
  approve_type;
  anDate;
  submitted: Boolean = false;
  maxDate = {};
  config = {
    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 20
  };

  constructor(config: NgbTabsetConfig,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router) { }

  get r() { return this.addPetForm.controls; }
  ngOnInit() {
    this.getallusers();
    const mdt = new Date();
    const mm = mdt.getMonth() + 1;
    this.maxDate = { 'year': mdt.getFullYear(), 'month': mm, 'day': mdt.getDate() };

    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }
    this.addPetForm = this.formBuilder.group({
      user: ['', Validators.required],
      description: ['', Validators.required],

      pet_type: ['', Validators.required],
      sex: ['', Validators.required],
      pet_breed: ['', Validators.required],
      // email: ['', Validators.required],
      pet_name: ['', Validators.required],
      pet_medical_info: [''],
      // phone: ['', Validators.required],
      //approve_type: [''],
      petdob: ['', Validators.required],
      gold: ['', Validators.required],
      chip_no: ['', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(15),
      ]],
      date_injected: ['']
      // pet_medical_info: ['', Validators.required],
      // pet_medical_info: ['', Validators.required],
    });

    this.getPetTypes();
    this.approve_type;

  }

  getallusers(term = '') {
    let postData = {
      term: term
    };
    this.authenticationService.userslist(postData).subscribe(
      response => {
        if (response['userList'] !== {}) {
          this.options = [];
          this.users = response['userList'];
          this.users.forEach(element => {

            this.options = [...this.options, { 'id': element.id, 'name': element.email }];

            setTimeout(function () {
              if (document.getElementsByName("search-text").length > 0) {
                document.getElementsByName("search-text")[0]['value'] = term;
              }
            }, 10);
          });
          console.log('options', this.options);

        }

      }, error => {
        console.log(error);
      });

  }


  addnewPet() {
    this.submitted = true;
    if (this.addPetForm.invalid) {
      // console.log('err ch');

      return false;
    }
    var data = this.addPetForm.value;
    //return false;
    let petdob = data.petdob.year + '-' + data.petdob.month + '-' + data.petdob.day;
    let date_injected = data.date_injected.year + '-' + data.date_injected.month + '-' + data.date_injected.day;
    var myPostObject = {

      description: data.description,
      name: data.pet_name,
      breed: data.pet_breed,
      email: data.email,
      dob: petdob,
      sex: data.sex,
      medical_info: data.pet_medical_info,
      pet_type: data.pet_type,
      user_id: data.user.id,
      chip_no: data.chip_no,
      date_injected: date_injected,

      phone: data.phone,
      got_from: data.for_breeding,
      gold: data.gold,
      neutered: 'no',


    }

    this.authenticationService.addnewPet(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Pet added successfully!'))
          this.addPetForm.reset();
          this.submitted = false;
        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }

  getUserdata(id) {
    console.log(id);
    this.authenticationService.getsingleuser(id).subscribe(
      response => {
        console.log(response);
        if (response['data'] !== {}) {
          let data = response['data'];


          this.addPetForm.patchValue({
            email: data.email,
            phone: data.user_extras.phone,
            approve_type: data.approve_type,
          });
        }
      }, error => {

      });
  };

  searchChange(data) {
    console.log('rr', data);
    this.getallusers(data);
  }
  getPetdetailsfromChip() {
    let userchip = this.chipnumb;
    console.log(userchip);

    if (userchip == null || userchip == '') {
      setTimeout(() => this.toastr.error('Please enter chip number to search!'));
    } else {

      this.authenticationService.getbreederandPetinfo(userchip).subscribe(
        response => {
          //if (response['status'] == 200) { 
          if (response['BreederPetinfo'].length != 0) {
            var alldata = response['BreederPetinfo'][0]['pet'];

            if (alldata != null) {
              setTimeout(() => this.toastr.info('Pet is already registered with this chip no!'));
            } else {
              setTimeout(() => this.toastr.success('Chip is available to register!!'));
            }

          } else {
            setTimeout(() => this.toastr.error('Chip is not registered with any pet!!'));
          }
          //}
        }, error => {
          setTimeout(() => this.toastr.error(error.error.message));
        });


    }

  }
  getPetTypes() {
    this.authenticationService.getallpettypes().subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['petTypeList'] !== {}) {
          this.pet_types = response['petTypeList'];

        }
        //}
      }, error => {
        console.log(error);
      });
  }
}
