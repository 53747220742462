import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  checklogin;
  user_type;
  constructor(private cookieService: CookieService, private toastr: ToastrService, private BrowserAnimationsModule: BrowserAnimationsModule, private authenticationService: AuthenticationService, private authService: AuthenticationService, private http: HttpClient, private formBuilder: FormBuilder, private router: Router) { }
  get f() { return this.loginForm.controls; }
  ngOnInit() {

    // /this.nav.show();
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    //this.checklogin = localStorage.getItem("currentUser");
    //this.user_type= localStorage.getItem("user_type");
    this.user_type = localStorage.getItem("user_type");
    console.log('user_type', this.user_type);
    this.checklogin = localStorage.getItem("currentUser");
    console.log('checklogin', this.checklogin);
    if (this.user_type != null) {
      this.user_type = this.user_type.replace(/^"(.*)"$/, '$1');
    }
    if (this.user_type == 'admins') {


      this.router.navigate(['admin']);
    }
  }
  onSubmit() {
    this.invalidLogin = false;
    this.loginsubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    // var data = this.loginForm.value;
    this.authenticationService.login(this.f.email.value, this.f.password.value, 'admins')
      .subscribe(
        data => {
          console.log(data);
          this.router.navigate(['admin/petlist']);
        },
        error => {
          console.log('fail');
          setTimeout(() => this.toastr.error('Invalid Credentials', 'Oops!'));
          this.invalidLogin = true;
          // this.error = error;
          // this.loading = false;
        });
  }

}
