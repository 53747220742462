import { Component, OnInit, TemplateRef, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { environment } from '../../environments/environment';


declare var StripeCheckout: any;


@Component({
  selector: 'app-mypets',
  templateUrl: './mypets.component.html',
  styleUrls: ['../../assets/css/style.css', './mypets.component.scss']
})

export class MypetsComponent implements OnInit {
  @ViewChild('myDiv3') myDiv3: ElementRef;
  @ViewChild('myDiv4') myDiv4: ElementRef;
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('myDiv2') myDiv2: ElementRef;


  modalRef: BsModalRef;
  changeOwnerForm: FormGroup;
  p: number = 1;
  report_info;
  param;
  user;
  gold_price;
  basic_price;
  pets;
  myVar;
  temp_petid;
  user_account;
  submittedowner: Boolean = false;
  handler: any;
  loggedin: boolean = false;
  isgold_upgrade: boolean = false;
  isbasic_upgrade: boolean = false;
  emptypets: boolean = false;
  showLoader: boolean = false;
  useremail;
  checkedList = [];
  sitedata = [];
  editSomethingModal = 'changeowner';
  countries: any = [];
  states: any = [];
  cities: any = [];
  items: any = [];
  account_type;
  newItem;
  userFilter: any = { name: '' };

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  get c() { return this.changeOwnerForm.controls; }
  ngOnInit() {

    let payment = localStorage.getItem('show_payment_popup');
    console.log('payment--', payment);

    if (payment == '1') {
      let el3: HTMLElement = this.myDiv4.nativeElement as HTMLElement;
      el3.click();
    }


    this.getCountries();
    this.sitesettinglist();


    this.user_account = localStorage.getItem("user_account");
    this.account_type = localStorage.getItem("user_account_type");
    console.log('is it gold?------->' + this.user_account);

    this.param = localStorage.getItem("currentUser");
    this.user = localStorage.getItem("user_type");
    this.useremail = localStorage.getItem('user_email');
    if (this.user != '"users"') {
      this.router.navigate(['']);
    }
    this.getmypets();

    this.changeOwnerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      telephone: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
    });
  }
  upgradePlan(token) {
    //this.spinner.show();
    var tokenobject = {
      token: token
    }
    this.authenticationService.upgradeSubscription(tokenobject).subscribe(response => {
      console.log(response);
      //this.spinner.hide();
      this.user_account = 1;
      localStorage.setItem('user_account', '1');
      setTimeout(() => this.toastr.success('Plan upgraded successfully!'))
      this.modalRef.hide();
    }, error => {
      this.modalRef.hide();
    });
  }
  openCheckout() {
    let localthis = this;
    this.handler = (<any>window).StripeCheckout.configure({
      image: './../../assets/img/logo.png',
      key: environment.stripeKey,
      locale: 'auto',
      token: function (token: any) {
        console.log(token);
        //this.checkfun();
        localthis.upgradePlan(token);

        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
      }
    });

    this.handler.open({
      name: 'Protected Pet (c)',
      email: this.useremail,
      description: 'Upgrade Account ',
      currency: 'GBP',
      amount: this.isgold_upgrade ? this.gold_price * 100 : this.basic_price * 100,
    });

  }

  @HostListener('window:popstate')
  onPopstate() {
    this.handler.close();
  }
  getmypets() {
    this.showLoader = true;
    this.authenticationService.getmypets().subscribe(
      response => {
        //if (response['status'] == 200) { 
        this.showLoader = false;
        if (response['PetFrontChipList'] !== {}) {
          //this.pets = [{ name: 'John' }, { name: 'Jane' }, { name: 'Mario' }];
          this.pets = response['PetFrontChipList'];
          console.log(this.pets);
          let totalpets = response['PetFrontChipList'].length;
          console.log(totalpets);
          if (totalpets == 0) {
            this.emptypets = true;
          } else {
            this.emptypets = false;
          }

          console.log(this.pets);

        }
        //}
      }, error => {
        console.log(error);
      });
  }
  logout() {

    setTimeout(() => this.toastr.success('Logged Out Successfully')
    );
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user_type');
    localStorage.removeItem('userid');
    this.authenticationService.loggedOut();
    this.loggedin = false;
  }
  petUpdate(id) {
    this.temp_petid = id;
  }
  reportLost() {
    //setTimeout(() => this.toastr.info('Pet id is '+ this.temp_petid));
    let dataobject = {
      details: this.report_info
    }
    this.authenticationService.reportLostPet(this.temp_petid, dataobject).subscribe(
      response => {
        if (response['status'] == 201) {
          setTimeout(() => this.toastr.success('Pet status updated successfully!'))
          this.report_info = "";
          this.modalRef.hide();
          this.getmypets();
        }
      }, error => {
        setTimeout(() => this.toastr.success('Something went wrong'))
      });
  }
  report_deceased() {
    this.authenticationService.reportDeceased(this.temp_petid).subscribe(
      response => {
        if (response['status'] == 200) {
          setTimeout(() => this.toastr.success('Pet status updated successfully!'))
          this.modalRef.hide();
          this.getmypets();
        }
      }, error => {
        setTimeout(() => this.toastr.error('Something went wrong'))
      });
    // setTimeout(() => this.toastr.info('Pet id isss '+ this.temp_petid));
  }

  checkChipsToTransfer() {
    console.log(this.checkedList.length);
    if (this.checkedList.length == 0) {
      setTimeout(() => this.toastr.error('Please select atleast 1 pet to transfer ownership!'))
    } else {
      let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
      el3.click();
    }

  }
  onCheckboxChange(option, event) {
    if (event.target.checked) {
      this.checkedList.push(event.target.value);
    } else {
      for (var i = 0; i < this.pets.length; i++) {
        if (this.checkedList[i] == event.target.value) {
          this.checkedList.splice(i, 1);
        }
      }
    }
    console.log(this.checkedList);
  }

  addchangeownerRequest() {

    let petid = 5;
    this.submittedowner = true;
    if (this.changeOwnerForm.invalid) {
      return false;
    }
    var data = this.changeOwnerForm.value;
    var myPostObject = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.telephone,
      street1: data.address1,
      street2: data.address2,
      country: data.Country,
      county: data.county,
      city: data.city,
      postcode: data.postcode,
      petids: this.checkedList
    }


    this.authenticationService.multichangeOwner(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //  console.log(data.change_owner.id);   
          localStorage.setItem('change_request_id', data.change_owner.id);
          this.changeOwnerForm.reset();
          this.submittedowner = false;
          this.modalRef.hide();
          this.getmypets();
          let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
          el.click();
          // setTimeout(() => this.toastr.success('Request added successfully!'),1000);

        },
        error => {
          setTimeout(() => this.toastr.error(error.error.msg))
        });
  }


  getCountries() {
    this.authenticationService.getCountries().subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];
          console.log(this.countries);

        }
      }, error => {
        console.log(error);
      });
  }
  getStates(id) {
    this.authenticationService.getStates(id).subscribe(
      response => {
        if (response['stateList'] !== {}) {
          this.states = response['stateList'];
        }
      }, error => {
        console.log(error);
      });
  }
  getCities(id) {
    this.authenticationService.getCities(id).subscribe(
      response => {
        if (response['CitiesList'] !== {}) {
          this.cities = response['CitiesList'];
        }
      }, error => {
        console.log(error);
      });
  }


  openCheckout2() {
    var localthis = this;
    this.items = JSON.parse(localStorage.getItem('items'));
    this.handler = (<any>window).StripeCheckout.configure({
      image: './../assets/img/logo.png',
      key: environment.stripeKey,
      locale: 'auto',
      token: function (token: any) {
        //console.log(token);
        localthis.updateTransferRequest(token);
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
      }
    });

    this.handler.open({
      name: 'Protected Pet (c)',
      email: this.useremail,
      description: ' Ownership change Payment',
      currency: 'GBP',
      amount: 22.99 * 100
    });

  }

  sitesettinglist() {
    this.authenticationService.sitesettinglist().subscribe(
      response => {
        if (response['status'] == 200) {
          if (response['siteSettingList'] !== {}) {
            this.sitedata = response['siteSettingList'];
            console.log(this.sitedata[0].basic_price);
            this.gold_price = this.sitedata[0].gold_price;
            this.basic_price = this.sitedata[0].basic_price;

          }
        }
      }, error => {
        console.log(error);
      });
  }

  updateTransferRequest(token) {
    var myPostObject = {
      status: 'complete',
      transaction_id: token.id,
    }
    var change_request_id = localStorage.getItem('change_request_id');
    this.authenticationService.updateTransferRequest(myPostObject, change_request_id)
      .pipe(first())
      .subscribe(
        data => {
          localStorage.removeItem('change_request_id');
          setTimeout(() => this.toastr.success('Owner change request successfully placed!'));

          this.modalRef.hide();
        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
        });
  }


  open_basic() {

    this.modalRef.hide();
    let el: HTMLElement = this.myDiv2.nativeElement as HTMLElement;
    el.click();
    this.isgold_upgrade = false;
    this.isbasic_upgrade = true;
  }

  setaccount() {
    this.isgold_upgrade = true;
  }
  addtocart(plan) {
    console.log(plan);

    let currency = {
      "code": "GBP",
      "country": "Britain (United Kingdom)",
      "created_at": "2019-03-07 04:45:57",
      "currency": "Pounds",
      "id": 63,
      "status": "1",
      "symbol": "£",
      "updated_at": "-0001-11-30 00:00:00"
    }
    if (plan == 'basic') {
      console.log('hereeeee--basic');
      this.newItem = {
        'product_id': 991,
        'name': 'Basic Plan',
        'quantity': '1',
        'price': '9.95',
        'image': '',
        'currency': currency,
      };
    }
    if (plan == 'gold') {
      console.log('hereeeee--gold');

      this.newItem = {
        'product_id': 992,
        'name': 'Gold Plan',
        'quantity': '1',
        'price': '24.99',
        'image': '',
        'currency': currency,
      };
      console.log(this.newItem);
    }

    console.log(this.newItem);

    this.modalRef.hide();
    this.authenticationService.items.push(this.newItem);
    localStorage.setItem('items', JSON.stringify(this.authenticationService.items));

    this.items = JSON.parse(localStorage.getItem('items'));
    console.log(this.items);

    this.authenticationService.changeMessage(this.authenticationService.items.length);


  }
}
