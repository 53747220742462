import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {
  config = {
    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 20
  };
  param2;
  action: string = '';
  userid;
  updateuser;
  assign_to;
  users: any = [];
  countries: any = [];
  data: any = [];
  products: any = [];
  showLoader: boolean = true;
  localParams: any;
  states: any = [];
  shippingData: any = [];
  cities: any = [];
  options: any = [];
  pageaction: any = 'add';
  submitted: boolean = false;
  emptyData: boolean = false;
  createOrderForm: FormGroup;
  selectedProducts: any = [];
  current_order_id;
  shipping: any;
  orderItems: any;
  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.action = params['a'];
      console.log(this.action);
      this.localParams = params;
    });
  }
  get r() { return this.createOrderForm.controls; }
  ngOnInit() {
    // this.authenticationService.userslist().subscribe(
    //   response => {
    //     if (response['userList'] !== {}) {
    //       this.users = response['userList'];
    //       this.users.forEach(element => {
    //         this.options = [...this.options, { 'name': element.email, 'id': element.id }];
    //       });
    //     }
    //   }, error => {
    //     console.log(error);
    //   });
    this.nav.show();
    this.getallusers();
    this.getProductName();
    this.getShippingMethod();


    this.getCountries();



    this.createOrderForm = this.formBuilder.group({
      assign_to: [''],
      firstname: [''],
      lastname: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      address1: [''],
      address2: [''],
      city: [''],
      postcode: [''],
      country: [''],
      county: [''],
      shipping_method: [''],
      tracking_no: [''],
      instructions: [''],
      payment_status: [''],
      products: [[]],


    });

    if (this.GetParam('id') != 0) {
      let oid = this.GetParam('id');
      this.current_order_id = oid;
      console.log("nikhil" + oid);
      this.OrderDetails(oid);
    }

  }
  getallusers(term = "") {
    let postData = {
      term: term
    };
    this.authenticationService.userslist(postData).subscribe(
      response => {
        if (response['userList'] !== {}) {
          this.options = [];
          this.users = response['userList'];
          this.users.forEach(element => {
            this.options = [...this.options, { 'id': element.id, 'name': element.email }];
            setTimeout(function () {
              if (document.getElementsByName("search-text").length > 0) {
                document.getElementsByName("search-text")[0]['value'] = term;
              }
            }, 10);
          });
        }
      }, error => {
        console.log(error);
      });
  }

  searchChange(data) {
    this.getallusers(data);
  }

  getProductName() {
    //console.log("Testing");
    this.authenticationService.getProductName().subscribe(
      response => {
        if (response['productName'] !== {}) {

          this.products = response['productName'];

        }
      }, error => {
        console.log(error);
      });
  }
  getCountries() {
    this.authenticationService.getCountries().subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];
          // console.log(this.countries);

        }
      }, error => {
        console.log(error);
      });
  }
  getStates(id) {
    this.authenticationService.getStates(id).subscribe(
      response => {
        if (response['stateList'] !== {}) {
          this.states = response['stateList'];
        }
      }, error => {
        console.log(error);
      });
  }

  /***************************Shipping Method *******************************************/
  getShippingMethod() {
    this.authenticationService.shippinglist().subscribe(
      response => {

        if (response['shippinglist']['data'] !== {}) {
          this.shippingData = response['shippinglist']['data'];
          console.log(this.shippingData);

        }
      }, error => {
        console.log(error);
      });
  }
  getCities(id) {
    this.authenticationService.getCities(id).subscribe(
      response => {
        if (response['CitiesList'] !== {}) {
          this.cities = response['CitiesList'];
        }
      }, error => {
        console.log(error);
      });
  }



  shippingmethod(id) {
    this.authenticationService.getSingleShipping(id).subscribe(
      response => {
        if (response['shipping'] !== {}) {
          this.shipping = response['shipping']
        }
      }, error => {
        console.log(error);
      });
  }
  getUserdata(data) {
    console.log(data.value.id);
    let id = data.value.id;
    this.assign_to = data.value.id;
    this.authenticationService.getsingleuser(id).subscribe(
      response => {
        console.log(response);
        if (response['data'] !== {}) {
          let data = response['data'];
          this.createOrderForm.patchValue({
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            country: data.user_addresses.country,
            county: data.user_addresses.county,

            postcode: data.user_addresses.postcode,
            phone: data.user_extras.phone,
            payment_status: data.payment_status,
            address1: data.user_addresses.street1,
            address2: data.user_addresses.street2,

            shipping_method: data.shipping_method,
            tracking_no: data.tracking_no,
            instructions: data.instructions,
            city: data.user_addresses.city,


          });
          this.getStates(data.user_addresses.country);


        }
        //}
      }, error => {
        console.log(error);
      });
  }
  onChangeProduct(product_id, quantity) {
    if (quantity == 0) {
      let index = this.products.findIndex(x => x.product_id === product_id);
      this.selectedProducts.splice(index, 1);
    } else {
      this.selectedProducts.push({ "product_id": product_id, "quantity": quantity });
    }

    this.createOrderForm.patchValue({ products: this.selectedProducts })
  }
  GetParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }
  updateOrder(status, id) {
    var myPostObject = {
      status: status

    }
    this.authenticationService.updateOrders(myPostObject, id)
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => this.toastr.success('Order Status update successfully!'))




          this.router.navigate(['/admin/orders']);
        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });



  }
  OrderDetails(id) {
    this.showLoader = true;
    this.authenticationService.getOrderDetails(id).subscribe(
      response => {
        this.showLoader = false;
        if (response['data'] !== {}) {
          console.log(response['order'])
          let data = response['order'];
          console.log("Hunny");
          console.log(data);
          this.createOrderForm.patchValue({
            firstname: [data.user_detail.firstname],
            lastname: [data.user_detail.lastname],
            email: [data.user_detail.email],
            phone: [data.telephone],
            address1: [data.address1],
            address2: [data.address2],
            city: [data.city],
            postcode: [data.postcode],
            country: [data.country],

            shipping_method: [data.shipping_id],

            tracking_no: [data.order_item.parcel_tracking_no],
            instructions: [data.order_item.special_instructions],



          });
          this.getStates(data.user_addresses.country);
          this.getCities(data.user_addresses.county);

          this.orderItems = data.order_item;


        }
      });

  }





  onRegister() {
    //  console.log("testing12");
    this.submitted = true;
    if (this.createOrderForm.invalid) {
      console.log(this.createOrderForm);
      return false;
    }
    console.log(this.pageaction);
    var data = this.createOrderForm.value;
    let input = new FormData();
    input.append('firstname', data.firstname);
    input.append('lastname', data.lastname);
    input.append('email', data.email);
    input.append('country', data.country);
    input.append('city', data.city);
    input.append('postcode', data.postcode);
    input.append('address1', data.address1);
    input.append('address2', data.address2);
    input.append('payment_status', data.payment_status);
    input.append('shipping_method', data.shipping_method);
    input.append('tracking', data.tracking);
    input.append('instuctions', data.instuctions);

    var myPostObject = {
      assign_to: this.assign_to,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      country: data.country,
      city: data.city,
      postcode: data.postcode,
      address1: data.address1,
      address2: data.address2,
      payment_status: data.payment_status,
      shipping_method: data.shipping_method,
      tracking: data.tracking,
      instuctions: data.instructions,
      products: data.products

    }
    console.log(input);

    if (this.pageaction == 'add') {
      let outThis = this;
      this.authenticationService.addOrder(myPostObject)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('Order added successfully!'))
            this.router.navigate(['/admin/orders']);
          },
          error => {
            setTimeout(() => this.toastr.error('Something went wrong!'))
            //   this.error = error;
            //   this.loading = false;
          });
    }
    //else {

    //   this.authenticationService.updateProduct(myPostObject,this.updateproduct)
    //     .pipe(first())
    //     .subscribe(
    //       data => {
    //         setTimeout(() => this.toastr.success('Product updated successfully!'))
    //         // this.addProductForm.reset();
    //         this.submitted = false;

    //         let productid = this.updateproduct;
    //         for (let item of this.urls) {

    //           this.uploadProductImages(item, productid);
    //         }
    //         this.router.navigate(['/admin/viewproducts']);                 
    //       },
    //       error => {
    //         setTimeout(() => this.toastr.error('Something went wrong!'))
    //         //   this.error = error;
    //         //   this.loading = false;
    //       });

    // }
    //this.router.navigate(['/admin/viewproducts']);
  }



}
