import { Component, OnInit,TemplateRef,ElementRef, ViewChild  } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  //styleUrls: ['./contactus.component.scss']
  styleUrls: ['../../assets/css/style.css']
})
export class ContactusComponent implements OnInit {
  contactusForm: FormGroup;
  formsubmitted: boolean = false;
  constructor(private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }
  get f() { return this.contactusForm.controls; }

  ngOnInit() {
    this.contactusForm = this.formBuilder.group({
      name: ['', Validators.required],
      PhoneNumber: ['', Validators.required],
      message: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],

    }); 
  }

  onSubmit() {
    this.formsubmitted = true;
    if (this.contactusForm.invalid) {
      return;
    }
    var data = this.contactusForm.value;


    var myPostObject = {

      name : data.name,
      email :data.email,
      phone :data.PhoneNumber,
      message :data.message,
    }      
      this.authenticationService.contact(myPostObject)
      .pipe(first())
      .subscribe(
          data => {
            console.log(data);
            this.contactusForm.reset();
            this.formsubmitted = false;
           setTimeout(() => this.toastr.success('Message sent Successfully')
            );

          },
          error => {
            console.log(error.error);
            
            setTimeout(() => this.toastr.error(error.error.msg, 'Oops!'));

          });        
  } 
}
