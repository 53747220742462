import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['../../assets/css/style.css']
})
export class TermsComponent implements OnInit {

  contactusForm: FormGroup;
  formsubmitted: boolean = false;
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  get f() { return this.contactusForm.controls; }
  ngOnInit() {
    console.log('i am in termmmm');

  }

}
