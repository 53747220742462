
export const environment = {
  production: false,
  /* Dev Site */
  api_url: 'http://protectedpet-backend.yellowbulb.co.uk/api',
  site_url: 'http://protectedpet.yellowbulb.co.uk/',
  admin_api_url: 'http://127.0.0.1:8000/index.php/api/',
  serviceurl: 'http://protectedpet-backend.yellowbulb.co.uk/api',
  uploadurl: 'http://protectedpet-backend.yellowbulb.co.uk/uploads/',
  stripeKey: 'pk_test_oi0sKPJYLGjdvOXOM8tE8cMa',

  /* Live Site */
  // api_url: 'https://api.protectedpet.com/api',
  // site_url: 'https://www.protectedpet.com/',
  // admin_api_url: 'http://127.0.0.1:8000/index.php/api/',
  // serviceurl: 'https://api.protectedpet.com/api',
  // uploadurl: 'https://api.protectedpet.com/uploads/',
  // stripeKey: 'pk_test_oi0sKPJYLGjdvOXOM8tE8cMa',
  //stripeKey: 'pk_live_ig583PNlq63pEFU9wkHbR8oQ',

};
