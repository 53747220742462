import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-orderhistory',
  templateUrl: './orderhistory.component.html',
  styleUrls: ['../../assets/css/style.css', './orderhistory.component.scss']
})
export class OrderhistoryComponent implements OnInit {
  param;
  user;
  orders;
  loggedin: boolean = false;
  emptyorders: boolean = false;
  showLoader: boolean = true;
  dateFormat;
  pdfurl;
  p;
  @ViewChild('myDiv3') myDiv3: ElementRef;
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.dateFormat = localStorage.getItem("date_time_format");
    this.dateFormat = this.dateFormat.replace(/^"(.*)"$/, '$1');
    this.param = localStorage.getItem("currentUser");
    this.user = localStorage.getItem("user_type");
    if (this.user != '"users"') {
      this.router.navigate(['']);
    }
    this.getorderhistory();
    this.frontCartOrderList();
  }
  getorderhistory() {
    //this.showLoader=true;
    this.authenticationService.getorderhistory().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
        if (response['frontOrderList'] !== {}) {
          this.orders = response['frontOrderList'];
          let totalorders = response['frontOrderList'].length;
          if (totalorders == 0) {
            this.emptyorders = true;
          } else {
            this.emptyorders = false;
          }
          console.log(this.orders);

        }
        //}
      }, error => {
        console.log(error);
      });
  }
  frontCartOrderList() {
    this.authenticationService.frontCartOrderList().subscribe(
      response => {
        this.showLoader = false;
        if (response['frontOrderList'] !== {}) {
        }
      }, error => {
        console.log(error);
      });
  }
  logout() {

    setTimeout(() => this.toastr.success('Logged Out Successfully')
    );
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user_type');
    localStorage.removeItem('userid');
    this.authenticationService.loggedOut();
    this.loggedin = false;


  }
  ordercancel(orderid) {
    console.log("orderId", orderid);
    this.authenticationService.ordercancel(orderid).subscribe(
      response => {
        console.log(response);
        setTimeout(() => this.toastr.success('Order Cancel Successfully'));
        this.getorderhistory();
      });

  }

  openpdf(orderid) {
    this.showLoader = true;
    this.authenticationService.openPdfInvoice(orderid).subscribe(
      response => {
        if (response !== {}) {
          this.pdfurl = response['data'][0].fileurl;
          console.log(response['data'][0].fileurl);
          setTimeout(() => {
            let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
            el3.click();
            this.showLoader = false;
            console.log('inside');

          }, 1000);
        } else {
          this.showLoader = false;
          //this.emptydocs = true;
        }
      }, error => {
        console.log(error);
      });
  }
}
