import { Component, OnInit,TemplateRef,Pipe, PipeTransform } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DomSanitizer } from "@angular/platform-browser";
@Pipe({ name: 'safe' })
@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	//styleUrls: ['./faq.component.scss']
	styleUrls: ['../../assets/css/style.css','./faq.component.scss']
})
export class FaqComponent implements OnInit {
	data :any = [];
	total: number;  
	response;
	constructor(private sanitizer: DomSanitizer,private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }
	transform(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
	ngOnInit() {
		this.getAllFaq();
	}
	getAllFaq(){
		this.authenticationService.getAllfaq().subscribe(
			response => {
				 if (response['petChipList'] !== {}) {
					this.data = response['faq'];
					this.data.forEach(element => {
						if(element.video_link != ""){
							let x = element.video_link.split("youtube.com/");
							let y = element.video_link.split("v=");
							let newlink = x[0]+'youtube.com/embed/'+y[1];
							element.video_link = newlink;
						}
					});
					console.log(this.data);
					
					this.total=response['faq'].total;
					this.response=response['faq'];
				}
			}, error => {
				console.log(error);
			});
	}

}
