import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-approveusers',
  templateUrl: './approveusers.component.html',
  styleUrls: ['./approveusers.component.scss']
})
export class ApproveusersComponent implements OnInit {
  addProductCat: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  showLoader: boolean = true;
  emptyData: boolean = false;
  param2;
  data: any = [];
  modalRef: BsModalRef;
  @ViewChild('myDiv3') myDiv3: ElementRef;
  pdfurl;
  loading: boolean = false;
  response;
  p: number = 1;
  total: number;
  search: string = "";
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  get r() { return this.addProductCat.controls; }

  ngOnInit() {

    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addProductCat = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      image_url: ['', Validators.required],
    });
    this.approveusersList(1);
  }
  approveusersList(page: number) {
    this.showLoader = true;
    this.authenticationService.approveusersList().subscribe(
      response => {
        this.showLoader = false;


        if (response['approverUsersList'] !== {}) {
          this.data = response['approverUsersList'].data;
          this.total = response['approverUsersList'].total;
          this.response = response['approverUsersList'];
          this.p = page;
          if (this.data.length == 0) {
            this.emptyData = true;
          }

        }
        //}
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }

  openCertificate(id) {
    this.authenticationService.openusercertificate(id).subscribe(
      response => {
        if (response !== {}) {
          this.pdfurl = response['data'][0].fileurl;
          console.log(response['data'][0].fileurl);
          setTimeout(() => {
            let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
            el3.click();
            console.log('inside');

          }, 1000);
        } else {
          //this.emptydocs = true;
        }
      }, error => {
        console.log(error);
      });
  }
  deleteLostfound(id) {

    if (confirm("Are you sure you want to delete Lost found entry")) {
      this.authenticationService.deleteLostfound(id).subscribe(
        response => {
          //this.getUsers();
          if (response['status'] == 200) {
            this.approveusersList(1);
            setTimeout(() => this.toastr.success('Lost found deleted successfully!'))
          }
        }, error => {

          setTimeout(() => this.toastr.success(error.error.msg))
        });
    }
  }
  logout() {
    this.nav.hide();
    this.authenticationService.logout();
  }
  Edituser(id) {
    this.router.navigate(['admin/adduser'], { queryParams: { user_id: id } });
  }

  approveUser(id) {

    this.authenticationService.approveUser(id)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('User Approved successfully!'))
          this.approveusersList(1);
        });

  }

  declineUser(id) {

    this.authenticationService.declineUser(id)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.info('User Declined successfully!'))
          this.approveusersList(1);
        });

  }
  addCat() {
    this.submitted = true;
    if (this.addProductCat.invalid) {
      return false;
    }
    var data = this.addProductCat.value;
    //return false;
    var myPostObject = {
      name: data.name,
      description: data.description,
      image_url: data.image_url,
    }
    this.authenticationService.addCategory(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Category added successfully!'))
          this.addProductCat.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.approveusersList(1);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['approverUsersList'] !== {}) {
          this.data = response['approverUsersList'].data;
          this.total = response['approverUsersList'].total;
          this.p = page;

        }
      }, error => {
        console.log(error);
      });
  }
  updateFilter() {
    if (this.search.trim() == "") {
      this.approveusersList(1);

    } else {
      // this.showLoader = true;
      // this.data = [];
      // this.total=0;
      // this.p=0;
      // let api = "searchPetType";
      // this.authenticationService.getSearchData(api,this.search).subscribe(
      //   response => {
      //     this.showLoader = false;
      //     if (response['petTypeList'] !== {}) {
      //       this.data = response['petTypeList'].data;
      //       this.total=response['petTypeList'].total;
      //       this.response=response['petTypeList'];
      //       this.p=0;  

      //     }
      //   }, error => {
      //     console.log(error);
      //   }); 
    }
  }
}
