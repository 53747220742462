import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {

  addProductCat: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  data: any = [];
  modalRef: BsModalRef;
  loading: boolean = false;
  response;
  p: number = 1;
  total: number;
  dateFormat;
  showLoader: boolean = true;
  enable: boolean = false;
  search: string = "";
  constructor(private cookieService: CookieService, private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }

  ngOnInit() {

    this.param2 = localStorage.getItem("currentUser");
    this.dateFormat = localStorage.getItem("date_time_format");
    this.dateFormat = this.dateFormat.replace(/^"(.*)"$/, '$1');

    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.getChipsetlist(1);
  }
  getChipsetlist(page: number) {
    this.showLoader = true;
    this.authenticationService.getChipList().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
        if (response['petChipList'] !== {}) {
          this.data = response['petChipList'].data;
          this.total = response['petChipList'].total;
          this.response = response['petChipList'];
          this.p = page;
          console.log(this.data);


        }
        //}
      }, error => {
        console.log(error);
      });
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['petChipList'] !== {}) {
          this.data = response['petChipList'].data;
          this.total = response['petChipList'].total;
          this.p = page;

        }
      }, error => {
        console.log(error);
      });
  }
  onChange(event, index, chip_number) {
    //  this.showLoader = true;
    this.data[index].hide_search = event == true ? '1' : '0';
    let ChipData = { 'chip_number': chip_number, 'status': event ? 1 : 0 };
    this.authenticationService.updateChipStatus(ChipData).subscribe(
      response => {
        //    this.showLoader = false;
        setTimeout(() => this.toastr.success('Chip Status updated successfully'))
      }, error => {
        console.log(error);
      });
  }
  editPet(id, chipnumber) {
    console.log('new_chipnumber', chipnumber);

    localStorage.setItem('edit_pet_id', id);
    localStorage.setItem('edit_chip_number', chipnumber);
    this.router.navigate(['/admin/editpet']);
  }

  Edituser(id) {
    this.router.navigate(['admin/adduser'], { queryParams: { user_id: id } });
  }
  updateFilter() {
    if (this.search.trim() == "") {
      this.getChipsetlist(1);

    } else {
      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchchip";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          console.log("result is arrivedddd");

          this.showLoader = false;
          if (response['petChipList'] !== {}) {
            this.data = response['petChipList'].data;
            this.total = response['petChipList'].total;
            this.response = response['petChipList'];
            this.p = 0;
            console.log(this.data);

          }
        }, error => {
          console.log(error);
        });
    }
  }
}
