import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router,ActivatedRoute} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';

@Component({
  selector: 'app-accountdetails',
  templateUrl: './accountdetails.component.html',
  styleUrls: ['../../assets/css/style.css','../../assets/css/responsive.css']
})
export class AccountdetailsComponent implements OnInit {
  Accoutdetails: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  isupdate:boolean = false;
  param2;
  addUserDetail;
  action: string;
  account_type;
  userid;
  data :any = [];
  users :any = [];
  countries:any= [];
  states:any= [];
  cities:any= [];
  localParams:any;
  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
          console.log(params);
            this.action = params['a'];
            this.localParams = params;
    });
    console.log(this.action);
    console.log(this.localParams);
  }
  get r() { return this.Accoutdetails.controls; }
  ngOnInit() {
    this.getCountries();
    this.Accoutdetails = this.formBuilder.group({
      email: [''],
      firstname: [''],
      lastname: [''],
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      country: [''],
      telephone: [''],
      secret_word: [''],
      secret_hint: [''],
      postcode: [''],
    });   
    this.getAccoutdetails();

     this.addUserDetail = this.formBuilder.group({
      email: ['', Validators.required],
   
      address1:['',Validators.required],
      firstname: ['', Validators.required],
      address2: ['', Validators.required],
      lastname: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: [''],
      postcode: [''],
      
    }); 

    
  }


  getAccoutdetails() { 
    this.authenticationService.getAccoutdetails().subscribe(
      response => {
        //if (response['status'] == 200) { 
          if (response['data'] !== {}) {
            this.data = response['data'];
            //console.log(this.data.email);
            this.account_type = this.data.approve_type;
            if(this.account_type == 'User'){
              this.account_type = 'Pet Owner Account';
            }else{
              this.account_type = this.account_type+' account';
            }
            this.getStates(this.data.user_addresses.country);
            this.getCities(this.data.user_addresses.county);

            //userid
            this.Accoutdetails = this.formBuilder.group({
              email: [this.data.email],
              firstname: [this.data.firstname],
              lastname: [this.data.lastname],
              address1: [this.data.user_addresses.street1],
              address2: [this.data.user_addresses.street2],
              city: [this.data.user_addresses.city],
              state: [this.data.user_addresses.county],
              country: [this.data.user_addresses.country],
              telephone: [this.data.user_extras.phone],
              secret_word: [this.data.user_extras.secret_question],
              secret_hint: [this.data.user_extras.secret_answer],
              postcode: [this.data.user_addresses.postcode],
            });               

          }
        //}
      }, error => {
        console.log(error);
      });
  }
    GetParam(name){
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if(!results){
      return 0;
    }
    return results[1] || 0;
  }
  getCountries() { 
    this.authenticationService.getCountries().subscribe(
      response => {
          if (response['countrieslist'] !== {}) {            
            this.countries = response['countriesList'];
            //console.log(this.countries);
            
          }
      }, error => {
        console.log(error);
      });
  }

 addUserDetails(){    
    this.submitted = true;
    // if (this.addUserDetail.invalid) {
    //   console.log('err ch');
      
    //   return false;
    // }
    this.userid = this.data.id;
    var data = this.Accoutdetails.value;
    console.log(data);
    //return false;
    
    var myPostObject = {
      
       
      email:data.email,
      address1 : data.address1,
      firstname: data.firstname,
      address2: data.address2,
      lastname: data.lastname,
      city: data.city,
      state: data.state,
      country:data.country,
      postcode: data.postcode,
      } 
   // console.log(myPostObject);
      
  this.authenticationService.updateuserDetail(myPostObject,this.userid)
  .pipe(first())
  .subscribe(
      data => {        
          setTimeout(() => this.toastr.success('User Update Succesfull!'))
          this.addUserDetail.reset();
          this.submitted = false;
          this.router.navigate(['/home/mypets']);
      },
      error => {
        console.log(error.error.message);
        //This chip does not exist in our database. It may be registered on a different database
        setTimeout(() => this.toastr.error(error.error.message))
        //   this.error = error;
        //   this.loading = false;
      });
}





  getStates(id) { 
    this.authenticationService.getStates(id).subscribe(
      response => {
          if (response['stateList'] !== {}) {
            this.states = response['stateList'];
          }
      }, error => {
        console.log(error);
      });
  }  
  getCities(id) { 
    this.authenticationService.getCities(id).subscribe(
      response => {
          if (response['CitiesList'] !== {}) {
            this.cities = response['CitiesList'];
          }
      }, error => {
        console.log(error);
      });
  }   
}
