import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdduserComponent implements OnInit {
  param2;
  action;
  userid;
  updateuser;
  countries: any = [];
  states: any = [];
  cities: any = [];
  pageaction: any = 'add';
  submitted: boolean = false;
  showLoader: boolean = true;
  registerForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute) { }
  get r() { return this.registerForm.controls; }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      login_name: [''],
      Password: [''],
      CPassword: [''],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      charity_name: [''],
      charity_no: [''],
      postcode: ['', Validators.required],
      approve_type: ['', Validators.required],
      street1: [''],
      street2: [''],
      phone: ['', Validators.required],
      is_vet: [''],
      passcode: [''],
      practice_code: [''],
      practice_name: [''],
      implanter_code: [''],
      trained_with: [''],
      council_name: [''],
      license_number: ['']
    });


    this.nav.show();
    //this.getCountries();
    this.param2 = localStorage.getItem("formaction");
    this.updateuser = this.route.snapshot.queryParams['user_id']
    //let userid = localStorage.getItem("userid");
    if (this.updateuser != null) {
      this.pageaction = "update";
      this.getUserdata(this.updateuser);
    } else {
      this.registerForm.reset();
    }
    this.getCountries();
  }
  getCountries() {
    this.authenticationService.getCountries().subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];

        }
      }, error => {
        this.toastr.error(error.error.msg);
      });
  }
  getStates(id) {
    this.authenticationService.getStates(id).subscribe(
      response => {
        if (response['stateList'] !== {}) {
          this.states = response['stateList'];
        }
      }, error => {
        this.toastr.error(error.error.msg);
      });
  }
  getCities(id) {
    this.authenticationService.getCities(id).subscribe(
      response => {
        if (response['CitiesList'] !== {}) {
          this.cities = response['CitiesList'];
        }
      }, error => {
        this.toastr.error(error.error.msg)
      });
  }
  getUserdata(id) {
    this.showLoader = true;
    this.authenticationService.getsingleuser(id).subscribe(
      response => {

        //if (response['status'] == 200) { 
        if (response['data'] !== {}) {
          let data = response['data'];
          this.registerForm.patchValue({
            firstname: data.firstname,
            lastname: data.lastname,
            Email: data.email,
            login_name: data.login_name,
            Country: data.user_addresses.country,
            county: data.user_addresses.county,
            city: data.user_addresses.city,
            charity_name: data.charity_name,
            charity_no: data.charity_number,
            postcode: data.user_addresses.postcode,
            approve_type: data.approve_type,
            street1: data.user_addresses.street1,
            street2: data.user_addresses.street2,
            phone: data.user_extras.phone,
            is_vet: data.user_extras.is_vet,
            passcode: data.bypass_code,
            practice_code: data.vet_practice_code,
            practice_name: data.vet_name,
            implanter_code: data.implanter_code,
            trained_with: data.implanter_trained_with,
            license_number: data.license_number,
            council_name: data.council_name

          });
          this.getStates(data.user_addresses.country);
          this.getCities(data.user_addresses.county);
          this.showLoader = false;
        }
        //}
      }, error => {
        this.toastr.error(error.error.msg)
      });
  }
  onRegister() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return false;
    }
    var data = this.registerForm.value;
    //return false;
    var myPostObject = {
      // identification: data.identification,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.Email,
      login_name: data.login_name,
      password: data.Password,
      CPassword: data.Password,
      country: data.Country,
      county: data.county,
      city: data.city,
      charity_name: data.charity_name,
      charity_number: data.charity_no,
      postcode: data.postcode,
      approve_type: data.approve_type,
      street1: data.street1,
      street2: data.street2,
      phone: data.phone,
      is_vet: data.is_vet,
      implanter_trained_with: data.trained_with,
      implanter_code: data.implanter_code,
      bypass_code: data.passcode,
      vet_practice_code: data.practice_code,
      vet_name: data.practice_name,
      council_name: data.council_name,
      license_number: data.license_number,

    }
    if (this.pageaction == "add") {
      this.authenticationService.adduser(myPostObject)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('User added successfully!'))
            this.registerForm.reset();
            this.submitted = false;
            this.router.navigate(['admin/viewusers']);

          },
          error => {
            setTimeout(() => this.toastr.error(error.error.msg))
          });
    } else {
      this.authenticationService.updateUser(myPostObject, this.updateuser)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('User updated successfully!'))
            this.registerForm.reset();
            this.submitted = false;
            this.router.navigate(['admin/viewusers']);
          },
          error => {
            setTimeout(() => this.toastr.error(error.error.msg))
          });
    }

  }


}
