import { Component, OnInit,TemplateRef,ViewChild,ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-mydocuments',
  templateUrl: './mydocuments.component.html',
  styleUrls: ['../../assets/css/style.css','./mydocuments.component.scss']
})
export class MydocumentsComponent implements OnInit {
  param;
  user;
  documents;
  emptydocs:boolean=false;
  loggedin:boolean = false;
  user_account;
  handler: any;
  modalRef: BsModalRef;
  useremail;
  tempidofpet;
  pdfurl;
  showLoader:boolean=false;
  @ViewChild('myDiv3') myDiv3: ElementRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ backdrop: 'static', keyboard: false });
  }  
  constructor(private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }

  ngOnInit() {
    this.param= localStorage.getItem("currentUser");
    this.user= localStorage.getItem("user_type");
    if(this.user != '"users"'){
      this.router.navigate(['']);
    }
    this.getmydocuments();    
  }
  getmydocuments(){
    this.showLoader=true;
    this.authenticationService.getmydocuments().subscribe(
      response => {
        this.showLoader=false;
        //if (response['status'] == 200) { 
          if (response['documents'] !== {}) {
            this.documents = response['documents'];
             let totaldocs = response['documents'].length;
            if(totaldocs == 0){
              this.emptydocs = true;
            }else{
              this.emptydocs = false;
            }
            
            console.log(this.documents);
          }
        //}
      }, error => {
        console.log(error);
      });    
  }
  logout(){

    setTimeout(() => this.toastr.success('Logged Out Successfully')
            );
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user_type');
    localStorage.removeItem('userid');
    this.authenticationService.loggedOut();
    this.loggedin = false;
  }

  openpdf(petid){
    this.authenticationService.openpetdocument(petid).subscribe(
      response => {
          if (response !== {}) {
            this.pdfurl = response['data'][0].fileurl;
            console.log(response['data'][0].fileurl);              
            setTimeout(() => {
              let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
              el3.click(); 
              console.log('inside');
              
            }, 1000);
          }else{
            //this.emptydocs = true;
          }
      }, error => {
        console.log(error);
      });    
  }

  setpetid(petid){
    this.tempidofpet = petid;
  }
  openCheckout() {
    let localthis = this;
    this.handler = (<any>window).StripeCheckout.configure({
      image: './../../assets/img/logo.png',
      key: environment.stripeKey,
      locale: 'auto',
      token: function (token: any) {
        console.log(token);
        //localthis.upgradePlan(token);
        localthis.openpdf(localthis.tempidofpet);
      }
    });

    this.handler.open({
      name: 'Protected Pet (c)',
      email: this.useremail,
      description: 'Pet certificate ',
      currency: 'GBP',
      amount: 9.99 * 100
    });

  }  

  onNavigate(url){
    window.open(url, "_blank");
  }
}
