import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-micro',
  templateUrl: './about-micro.component.html',
  styleUrls: ['../../assets/css/style.css']
})
export class AboutMicroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
