import { Component, OnInit } from '@angular/core';
import {Router,NavigationEnd} from "@angular/router";
import {AuthenticationService} from '../services/authentication.service';
@Component({
  selector: 'app-homefooter',
  templateUrl: './homefooter.component.html',
  styleUrls: ['./homefooter.component.scss']
})
export class HomefooterComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,private router: Router) { }

  ngOnInit() {
  }
  gotoSearch(){
    if(this.router.url != "/home"){
      this.router.navigate(['home']);
    }else{
      this.authenticationService.filter('Lost/found pet');
    }
    // this.renderer.selectRootElement('#chipinputbox').focus()
    //$("#chipinputbox").focus();
  }

}
