import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation, EventEmitter, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { log } from 'util';
import { CookieService } from 'ngx-cookie-service';
import { ViewproductsComponent } from '../viewproducts/viewproducts.component';
import { environment } from '../../environments/environment';
declare let $: any;
@Component({
  selector: 'app-homeheader',
  templateUrl: './homeheader.component.html',
  styleUrls: ['../../assets/css/style.css', './homeheader.component.scss']
})
export class HomeheaderComponent implements OnInit {
  modalRef: BsModalRef;
  loginForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  uploadurl: any = environment.uploadurl;
  param;
  user;
  report_info;
  lostchip;
  itemcount: any = 0;
  currentMessage: any;
  message: string;
  items;
  sub;
  currencies: any = [];
  isCollapsed = true;
  mycurrency = '';
  unpaid_payment: boolean = false;
  HideMyAccount: boolean = false;
  isDivShow: boolean = true;
  @ViewChild('myDiv') myDiv: ElementRef;
  @Output() onSearch = new EventEmitter();
  loggedin: boolean = false;
  @Output() myEvent = new EventEmitter();
  constructor(private cookieService: CookieService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    //private productsComponent: ViewproductsComponent,
    private router: Router) { }


  get f() { return this.loginForm.controls; }
  openModal(template: TemplateRef<any>) {
    this.isDivShow = false;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  appParentMessage: any;
  //var countriesApp = angular.module('currenciesApp', ['tw-currency-select']);
  ngOnInit() {
    console.log('vince', environment.uploadurl);
    if (window.innerWidth <= 768) {
      $('.nav-link').click(() => $('.collapse').collapse('toggle'));
    }


    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.getCurrencies();
    //this.itemcount = this.authenticationService.items.subscribe();
    // console.log(this.itemcount);
    this.param = localStorage.getItem("currentUser");//localStorage.getItem("currentUser");
    this.user = localStorage.getItem("user_type");//localStorage.getItem("user_type");
    let currencyvalue = localStorage.getItem('temp_currency_id');//localStorage.getItem("user_currency");
    //console.log('here---'+currencyvalue);

    if (currencyvalue != "") {
      console.log('currency is not null-' + currencyvalue);

      this.mycurrency = currencyvalue;
    } else {
      let cookiecurrency = localStorage.getItem('temp_currency_id');
      console.log('cookicurr' + cookiecurrency);

      if (cookiecurrency == '' || cookiecurrency == null) {
        console.log('in null curr');

        this.cookieService.set('temp_currency_id', '63');
        this.mycurrency = '63';
      } else {
        this.mycurrency = cookiecurrency;
      }


    }
    if (this.user == '"users"') {
      this.authenticationService.loggedIn();
      this.loggedin = this.authenticationService.loggedin;
    }

    this.loggedin = this.authenticationService.loggedin;
    this.authenticationService.currentMessage.subscribe(message => this.message = message)
    this.items = JSON.parse(localStorage.getItem('items'));
    if (this.message == null || this.message == '0') {
      if (this.items != null)
        this.message = this.items.length;
    }

    this.authenticationService.userloggedIn.subscribe(
      message => this.loggedin = message,
      console.log('amma amma--' + this.authenticationService.userloggedIn)
    )
    console.log('voince');
    let show_payment_popup = localStorage.getItem('show_payment_popup');
    if (show_payment_popup == '1') {
      this.unpaid_payment = true;
    }
    // this.authenticationService.navchange.subscribe(isOpen => {
    //   this.loggedin = true;
    // }); 

    // this.authenticationService.getproducts.subscribe(isOpen => {
    //   this.loggedin = true;
    // });  

    this.authenticationService.unpaid_payment_status.subscribe(isOpen => {
      this.unpaid_payment = isOpen;
      console.log('rest', isOpen);
    });
    // this.authenticationService.unpaid();
    // this.HideMyAccount = this.authenticationService.HideMyAccount;


  }
  toggle() {
    this.isCollapsed = !this.isCollapsed;

  }
  newMessage() {
    //this.authenticationService.changeMessage("Hello from Sibling")
  }
  receiveMessage($event) {
    this.appParentMessage = $event
  }
  onSubmit() {
    this.invalidLogin = false;
    this.loginsubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    var data = this.loginForm.value;
    this.authenticationService.login(this.f.email.value, this.f.password.value, 'users')
      .pipe(first())
      .subscribe(
        data => {
          console.log('heyaa', data);

          setTimeout(() => this.toastr.success('Logged In Successfully')
          );
          this.frontCartOrderList();
          this.modalRef.hide();
          this.authenticationService.loggedIn();
          this.loggedin = true;

          //this.router.navigate(['home/myaccount']);
          let inactive_pet = data.inactive_pet;
          if (inactive_pet == 1) {
            localStorage.setItem('show_payment_popup', '1');

            this.router.navigate(['home/cart']);
            this.unpaid_payment = true;
            console.log('pay now');

          } else {
            localStorage.setItem('show_payment_popup', '0');
            this.router.navigate(['home/myaccount']);
          }
        },
        error => {
          console.log('fail');
          setTimeout(() => this.toastr.error(error.error.error));
          this.invalidLogin = true;
          // this.error = error;
          // this.loading = false;
        });
  }
  logout() {

    this.authenticationService.changeMessage(0);
    setTimeout(() => this.toastr.success('Logged Out Successfully')
    );
    localStorage.clear();
    this.isDivShow = false;
    this.authenticationService.loggedOut();
    this.loggedin = false;
    //this.router.navigate(['']);
    window.location.href = "/";
  }
  frontCartOrderList() {
    this.authenticationService.frontCartOrderList().subscribe(
      response => {
        //this.showLoader = false;
        if (response['frontCartOrderList'] !== {}) {
          let items = response['frontCartOrderList'];
          items.order_item.forEach(element => {
            console.log(element.product_id);
            var newItem = {
              'product_id': element.product_id,
              'name': element.product_detail.name,
              'quantity': element.qty,
              'price': element.price,
              'image': environment.uploadurl + element.product_id + '/' + element.product_detail.product_image[0].image_url,
              'currency': element.product_detail.currency,
              'symbol': element.product_detail.currency.symbol,
              'currencyCode': element.product_detail.currency.code,
              'currencyID': element.product_detail.currency.id,
            };
            this.authenticationService.items.push(newItem);
            localStorage.setItem('items', JSON.stringify(this.authenticationService.items));
            this.authenticationService.changeMessage(this.authenticationService.items.length);
          });


        }
      }, error => {
        console.log(error);
      });
  }
  getCurrencies() {
    this.authenticationService.getCurrencies().subscribe(
      response => {
        if (response['currenciesList'] !== {}) {
          this.currencies = response['currenciesList'];
          console.log(this.currencies);

        }
      }, error => {
        console.log(error);
      });
  }

  onChangeCurrency(event) {
    //
    console.log(event);

    this.authenticationService.emptycart();
    this.authenticationService.updateCurrency(event)
      .pipe(first())
      .subscribe(
        data => {
          //localStorage.removeItem('items');    

          //console.log('here i am man'); return false;

          localStorage.setItem('user_currency', JSON.stringify(event));
          localStorage.setItem('temp_currency_id', event);
          this.authenticationService.items.length = 0;

          this.authenticationService.callproducts();

          this.items = JSON.parse(localStorage.getItem('items'));
          console.log(this.items);
          setTimeout(() => this.toastr.success('Currency updated Successfully!'));


          //this.authenticationService.emptycart();
          //localStorage.removeItem('items');
        },
        error => {
          localStorage.setItem('temp_currency_id', event);
          this.authenticationService.callproducts();
          setTimeout(() => this.toastr.success('Currency updated!'));

        });
  }

  gotoSearch() {
    // if (this.router.url != "/home") {
    //   this.router.navigate(['home']);
    // } else {
    //   this.authenticationService.filter('Lost/found pet');
    // }

  }
  reportLost() {
    console.log(this.lostchip);

    if (this.lostchip == null || this.lostchip == undefined || this.lostchip == "") {
      setTimeout(() => this.toastr.error('Please input a valid chip number!'));
    } else {
      let dataobject = {
        details: this.report_info
      }
      this.authenticationService.reportLostChip(this.lostchip, dataobject).subscribe(
        response => {
          if (response['status'] == 200) {
            this.lostchip = "";
            this.report_info = "";
            setTimeout(() => this.toastr.success('Pet status updated successfully!'))
            this.modalRef.hide();

          }
        }, error => {
          this.lostchip = "";
          this.report_info = "";
          setTimeout(() => this.toastr.error(error.error.message))
        });
      // this.authenticationService.chipnumber(this.lostchip).subscribe(
      //   (response: any) => {
      //     if (response.status != 200) {
      //       setTimeout(() => this.toastr.error('Chip number does not exist in our database.'));
      //     } else {
      //       this.modalRef.hide();
      //       setTimeout(() => this.toastr.success('Pet reported as Lost!'));
      //     }
      //   });
    }
  }


}
