import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss', '../../assets/css/style.css']
})
export class BenefitsComponent implements OnInit {
  sitedata = [];
  gold_price;
  basic_price;
  constructor(private authenticationService: AuthenticationService, ) { }

  ngOnInit() {
    this.sitesettinglist();
  }
  sitesettinglist() {
    this.authenticationService.sitesettinglist().subscribe(
      response => {
        if (response['status'] == 200) {
          if (response['siteSettingList'] !== {}) {
            this.sitedata = response['siteSettingList'];
            console.log(this.sitedata[0].basic_price);
            this.gold_price = this.sitedata[0].gold_price;
            this.basic_price = this.sitedata[0].basic_price;

          }
        }
      }, error => {
        console.log(error);
      });
  }
}
