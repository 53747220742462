import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgModel } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
declare var angular: any;
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-viewproducts',
  templateUrl: './viewproducts.component.html',
  // styleUrls: ['./viewproducts.component.scss']
  styleUrls: ['../../assets/css/style.css', './viewproducts.component.scss']
})

export class ViewproductsComponent implements OnInit {
  data: any = [];
  products: any = [];
  clicked: boolean = false;
  selectedLevel: any = [];
  emptyProducts: boolean = false;
  items;
  usertype;
  is_vet;
  cur_id;
  uploadURL: any = environment.uploadurl;
  showLoader: boolean = false;
  @Output() myEvent = new EventEmitter();
  constructor(private formBuilder: FormBuilder,
    private cookieService: CookieService,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router, private route: ActivatedRoute) { }
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('multipleCarousel') multipleCarousel: ElementRef;
  @Output() messageEvent = new EventEmitter<string>();

  mySlideOptions = {
    items: 4, dots: false, nav: true, responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  };


  ngOnInit() {
    this.usertype = localStorage.getItem('account_type');
    this.is_vet = localStorage.getItem('is_vet');
    if (this.usertype) {
      this.usertype = this.usertype.replace(/^"(.*)"$/, '$1');
    }
    if (this.is_vet) {
      this.is_vet = this.is_vet.replace(/^"(.*)"$/, '$1');
    }
    //console.log("chdck", this.usertype);
    this.getProductCats();
    this.getAllProducts();

    // this.getProducts(4,null)
    // this.selectedLevel[7] = 1;

  }
  ngAfterViewInit() {
    // console.log(this.multipleCarousel);
    let outerThis = this;
    $('#multipleCarousel').on('click', '.item', function () {
      var $this = $(this);
      $(".item").removeClass('clicked');
      $this.addClass('clicked');
      let id = $this.attr("id");
      outerThis.getProducts(id, null)
    });
  }
  $scope; range = function (min, max, step) {
    step = step || 1;
    var input = [];
    for (var i = min; i <= max; i += step) input.push(i);
    return input;
  };
  triggerFalseClick() {
    let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
    el.click();
  }
  getProductCats() {
    this.authenticationService.getProductCats().subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['frontProductCatList'] !== {}) {
          this.data = response['frontProductCatList'];
          //this.getProducts(this.data['0'].id,null)
          let outerThis = this;
          this.authenticationService.getproducts.subscribe(isOpen => {
            let cookiecurrency = localStorage.getItem('temp_currency_id');
            console.log(cookiecurrency);

            this.getProducts(outerThis.data['0'].id, cookiecurrency);
          });
          setTimeout(() => this.slideright(), 1000);


        }
        //}
      }, error => {
        console.log(error);
      });
  }
  getProductsByCategory(event, id) {
    // $(".item").removeClass('clicked');
    console.log(this.multipleCarousel);
    $('.productTitle').removeClass("clicked");
    // this.multipleCarousel.srcElement.classList.remove("clicked");
    event.srcElement.classList.add('clicked');

    this.getProducts(id, null)
  }

  getProducts(id, cur_id) {
    //console.log('heela=='+id);
    this.products = [];
    this.showLoader = true;
    let cookiecurrency = localStorage.getItem('temp_currency_id');
    if (cookiecurrency != null) {
      cur_id = cookiecurrency;
    } else {
      cur_id = null;
    }
    this.authenticationService.getProducts(id, cur_id).subscribe(
      response => {
        this.showLoader = false;
        if (response['frontProductList'] !== {}) {
          this.products = response['frontProductList'];
          let checkp = this.products.length ? this.products.length : '0';// response['frontProductList'].length;
          console.log(checkp);
          if (checkp == 0) {
            this.emptyProducts = true;
          } else {
            this.emptyProducts = false;
            this.products.forEach(item => {
              this.selectedLevel[item.product_id] = 1;
            });
          }


          setTimeout(() => {    //<<<---    using ()=> syntax
            this.updateButtons()
          }, 1000);



        }
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }
  getAllProducts() {
    this.products = [];
    this.showLoader = true;
    let cookiecurrency = localStorage.getItem('temp_currency_id');
    if (cookiecurrency != null) {
      this.cur_id = cookiecurrency;
    } else {
      this.cur_id = null;
    }
    this.authenticationService.getAllProducts().subscribe(
      response => {
        this.showLoader = false;
        if (response['frontProductCatList'] !== {}) {
          this.products = response['frontProductCatList'];
          //this.getProducts(this.data['0'].id,null)
          console.log('test', this.products);
          let checkp = this.products.length ? this.products.length : '0';// response['frontProductList'].length;
          console.log(checkp);
          if (checkp == 0) {
            this.emptyProducts = true;
          } else {
            this.emptyProducts = false;
            this.products.forEach(item => {
              this.selectedLevel[item.product_id] = 1;
            });
          }


          setTimeout(() => {    //<<<---    using ()=> syntax
            this.updateButtons()
          }, 1000);



        }
      }, error => {
        console.log(error);
      });

  }



  getsaleproducts() {
    this.products = [];
    this.showLoader = true;
    let cookiecurrency = localStorage.getItem('temp_currency_id');
    if (cookiecurrency != null) {
      this.cur_id = cookiecurrency;
    } else {
      this.cur_id = null;
    }
    this.authenticationService.getsaleProducts(this.cur_id).subscribe(
      response => {
        this.showLoader = false;
        if (response['frontProductList'] !== {}) {
          this.products = response['frontProductList'];
          let checkp = this.products.length ? this.products.length : '0';// response['frontProductList'].length;
          console.log(checkp);
          if (checkp == 0) {
            this.emptyProducts = true;
          } else {
            this.emptyProducts = false;
            this.products.forEach(item => {
              this.selectedLevel[item.product_id] = 1;
            });
          }


          setTimeout(() => {    //<<<---    using ()=> syntax
            this.updateButtons()
          }, 1000);



        }
      }, error => {
        console.log(error);
      });

  }
  updateButtons() {
    this.items = JSON.parse(localStorage.getItem('items'));

    if (this.items != null && this.items.length > 0) {
      this.items.forEach(item => {
        $('#btn-' + item.product_id).text("ADDED");
      });
    }

  }

  addtocart(item) {
    let pid = item.product_id;
    let pquantity = 1;

    if (this.selectedLevel[pid] != null) {
      pquantity = this.selectedLevel[pid];
    }
    console.log(item.currency);

    this.clicked = true;
    var newItem = {
      'product_id': pid,
      'name': item.name,
      'quantity': pquantity,
      'price': item.price,
      'image': item.image,
      'currency': item.currency,
    };
    console.log(newItem);

    if ($('#btn-' + item.product_id).text() == "ADDED") {
      return false;
    }
    $('#btn-' + item.product_id).text("ADDED");
    this.authenticationService.items.push(newItem);
    localStorage.setItem('items', JSON.stringify(this.authenticationService.items));
    this.authenticationService.changeMessage(this.authenticationService.items.length);


    //$(".shopping-cart").effect( "shake", {times:4}, 1000 );
    var div = document.getElementsByClassName('shopping-cart');
    var interval = 100;
    var distance = 10;
    var times = 4;

    $(div).css('position', 'relative');

    for (var iter = 0; iter < (times + 1); iter++) {
      $(div).animate({
        left: ((iter % 2 == 0 ? distance : distance * -1))
      }, interval);
    }
    $(div).animate({ left: 0 }, interval);


  }
  slideright() {
    var navbtnRight = $('.items-control.right');
    var navbtnleft = $('.items-control.left');
    var menuItem = $(".menu-item");
    var moveLeft = 0;
    var menuWidth = 0;
    $(menuItem).each(function () {
      menuWidth += parseInt($(this).outerWidth());
    });


    $(".menu-list").css({ 'width': menuWidth });

    var navWidth = $(".items-wrapper").width();
    if (navWidth < menuWidth) {
      navbtnRight.addClass('showbtn');
    }
    else {
      if (navbtnRight.hasClass("showbtn")) {
        navbtnRight.removeClass('showbtn');
      }
    }
    var btnWidth = $('.items-control').outerWidth();
    $(".menu-list").css({ 'left': "0" });
    navbtnRight.click(function () {
      //var left = menuWidth - navWidth;
      moveLeft = menuWidth - navWidth;
      //var move = menuWidth - left;
      navbtnleft.addClass('showbtn');
      if (menuWidth < (moveLeft + navWidth + 1)) {
        navbtnRight.removeClass('showbtn');
      }
      var totalMove = moveLeft + btnWidth;

      $(".menu-list").css({ 'left': - totalMove });
    });
    navbtnleft.click(function () {
      moveLeft = 0;
      navbtnRight.addClass('showbtn');
      navbtnleft.removeClass('showbtn');
      $(".menu-list").css({ 'left': '0' })
    });
  }
  getData(event) {
    console.log(event);

  }
}


