import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../app.component.scss','./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  data :any = [];
  constructor(private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }
  get f() { return this.loginForm.controls; }
  ngOnInit() { 
    this.param2= localStorage.getItem("currentUser");
    if(this.param2 == null){
      console.log('in null');
      this.router.navigate(['/login']);
    }else{
      this.nav.show();
    }
    this.getUsers();
  }
  getUsers() { 
    this.authenticationService.getallusers().subscribe(
      response => {
        //if (response['status'] == 200) { 
          if (response['userList'] !== {}) {
            this.data = response['userList'];
            console.log(this.data);
            
          }
        //}
      }, error => {
        console.log(error);
      });
  }
  deleteuser(id){
    if (confirm("Are you sure you want to delete")) {
    this.authenticationService.deleteuser(id).subscribe(
      response => {
        this.getUsers();
        if (response['status'] == 200) { 
          console.log('success');
          this.getUsers();
          setTimeout(() => this.toastr.success('User deleted successfully!'))
        }
      }, error => {
        console.log('errorrrrr');
        
        setTimeout(() => this.toastr.success('Something went wrong'))
      });    
    }
  }
  logout(){
    console.log('inlogout');
    this.nav.hide();
    this.authenticationService.logout();
  }  
}
