import { Component, OnInit, TemplateRef, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-mymicrochips',
  templateUrl: './mymicrochips.component.html',
  styleUrls: ['../../assets/css/style.css', './mymicrochips.component.scss']
})
export class MymicrochipsComponent implements OnInit {
  @ViewChild('myDiv3') myDiv3: ElementRef;
  @ViewChild('myDiv4') myDiv4: ElementRef;
  changeOwnerForm: FormGroup;
  param;
  report_info;
  user;
  modalRef: BsModalRef;
  breederInfoForm: FormGroup;
  ownerInfoForm: FormGroup;
  add_transfer_petinfo_form: FormGroup;
  pets;
  myVar;
  temp_petid;
  ownertitle: string = 'Add Owner Info';
  loggedin: boolean = false;
  emptychips: boolean = false;
  submittedowner: boolean = false;
  submittedbreeder: boolean = false;
  submitted: boolean = false;
  checkifpet: boolean = false;
  countries: any = [];
  states: any = [];
  selected_date;
  cities: any = [];
  items: any = [];
  userdata: any = [];
  pet_types: any = [];
  dueDate_array: any = [];
  checkedList = [];
  checkedList2 = [];
  checkedList3 = [];
  action;
  fetched_user_email;
  implantation_date;
  fetched_usertype;
  handler: any;
  showLoader: boolean = false;
  dobdate;
  p;
  implant_date;
  selectedDate;
  chipnumb;
  useremail;
  chipnumber;
  selected_chip_no;
  account_type: any = '';
  pet_name;
  userFilter: any = { name: '' };
  implanterDate: any = '';
  userNotfound: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router) { }

  get r() { return this.ownerInfoForm.controls; }
  get b() { return this.breederInfoForm.controls; }
  get c() { return this.changeOwnerForm.controls; }
  get t() { return this.add_transfer_petinfo_form.controls; }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  ngOnInit() {
    var today = new Date();
    this.selectedDate = today.getFullYear(), today.getMonth(), today.getDate();

    this.account_type = localStorage.getItem("user_account_type");
    this.param = localStorage.getItem("currentUser");
    this.user = localStorage.getItem("user_type");
    this.useremail = localStorage.getItem('user_email');
    if (this.user != '"users"') {
      this.router.navigate(['']);
    }
    this.getmychips();
    this.add_transfer_petinfo_form = this.formBuilder.group({
      chipno: ['', Validators.required],
      description: [''],
      dateimplanted: [''],
      pet_type: ['', Validators.required],
      sex: ['male', Validators.required],
      pet_breed: ['', Validators.required],
      pet_name: ['', Validators.required],
      pet_medical_info: [''],
      neutered: ['1'],
      petdob: ['', Validators.required],
      // sellername: [''],
      // selleraddress: [''],
    });
    this.breederInfoForm = this.formBuilder.group({
      kennelname: ['', Validators.required],
      email: ['', Validators.required],
      name: ['', Validators.required],
      telephone: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      license_no: [''],
      council_name: [''],
      pet_name: ['', Validators.required],
      pet_type: [''],
      sex: [''],
      pet_breed: [''],
      pet_color: [''],
      for_breeding: [''],
      neutered: [''],
      petdob: [''],
    });
    this.ownerInfoForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      telephone: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
    });

    this.changeOwnerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      telephone: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      userType: [''],
    });
    this.getCountries();
    this.getPetTypes();
  }
  FetchDetails() {
    let fillemail = this.changeOwnerForm.value.email;
    if (fillemail == "") {
      setTimeout(() => this.toastr.error('Please fill email to fetch details!'));
      this.submittedowner = false;
      return false;
    } else {
      this.fetched_user_email = fillemail;
      this.authenticationService.getuserbyemail(fillemail).subscribe(
        response => {
          if (response['status'] == 200) {
            this.userNotfound = false;
            this.userdata = response['data'];
            let fdata = response['data'];
            this.fetched_usertype = fdata.approve_type;
            if (this.fetched_usertype == "User" && this.checkedList2.length > 1) {
              setTimeout(() => this.toastr.error("Please select only one chip when transferring chip to Pet Owner Account"));
              this.modalRef.hide();
              return false;

            }
            this.getStates(fdata.user_addresses.country);
            this.getCities(fdata.user_addresses.county);
            console.log(this.userdata);
            this.changeOwnerForm.patchValue({
              firstname: fdata.firstname,
              lastname: fdata.lastname, telephone: fdata.user_extras.phone, address1: fdata.user_addresses.street1
              , address2: fdata.user_addresses.street2, Country: fdata.user_addresses.country
              , county: fdata.user_addresses.county
              , city: fdata.user_addresses.city
              , postcode: fdata.user_addresses.postcode

            });

          }
          this.showLoader = false;
        }, error => {
          console.log('test####');
          this.userNotfound = true;
          setTimeout(() => this.toastr.error(error.error.message))

        });
      this.submittedowner = false;
      return false;
    }
  }
  getmychips() {
    this.showLoader = true;
    this.authenticationService.getmychips().subscribe(
      response => {
        //if (response['status'] == 200) { 
        this.showLoader = false;
        if (response['PetFrontChipList'].length != 0) {
          this.pets = response['PetFrontChipList'];

          this.pets.map((petInfo) => {
            //console.log('aayoooo1', petInfo)
            if (petInfo.PetInfo == '1') {
              // console.log('aayoooo', petInfo.pet[0].date_injected)
              if (petInfo.pet[0].date_injected) {
                if (petInfo.pet[0].date_injected.includes("-")) {
                  let implant_array = petInfo.pet[0].date_injected.split('-');
                  let imp_year = implant_array[0];
                  let imp_month = implant_array[1];
                  let imp_date = implant_array[2];
                  let newdate = { year: parseInt(imp_year), month: parseInt(imp_month), day: parseInt(imp_date) };
                  //let newdate = imp_year + '-' + imp_month + '-' + imp_date;
                  petInfo.pet_name = newdate;//{ year: 2017, month: 8, day: 8 };
                  // this.petname = newdate;//{ year: 2017, month: 8, day: 8 };
                  petInfo.pet[0].date_injected = newdate;
                }
              }


            }
            return petInfo;
          })


          console.log(this.pets);

        } else {
          this.emptychips = true;
        }
      }, error => {
        console.log(error);
      });
  }
  logout() {

    setTimeout(() => this.toastr.success('Logged Out Successfully')
    );
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user_type');
    localStorage.removeItem('userid');
    this.authenticationService.loggedOut();
    this.loggedin = false;
  }

  checkChipsToTransfer() {
    if (this.checkedList.length == 0 && this.checkedList2.length == 0) {
      setTimeout(() => this.toastr.error('Please select atleast 1 pet to transfer ownership!1=' + this.checkedList.length + '-2=' + this.checkedList2.length))
    } else {

      let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
      el3.click();
    }

  }
  onCheckboxChange(option, event, ispet, chipid, chip_no, date) {
    console.log('test', chip_no);
    this.selected_chip_no = chip_no;
    this.selected_date = date;
    console.log(date);

    if (event.target.checked) {
      //console.log(ispet);

      if (!ispet) {
        this.checkifpet = false;
      } else {
        this.checkifpet = true;
      }
      this.checkedList.push(event.target.value);
      this.checkedList2.push(chipid);
      this.checkedList3.push(chip_no);
    } else {
      if (!ispet) {
        this.checkifpet = true;
      } else {
        this.checkifpet = true;
      }
      for (var i = 0; i < this.pets.length; i++) {
        if (this.checkedList[i] == event.target.value) {
          this.checkedList.splice(i, 1);
        }
        if (this.checkedList2[i] == chipid) {
          this.checkedList2.splice(i, 1);
        }
        if (this.checkedList3[i] == chip_no) {
          this.checkedList3.splice(i, 1);
        }
      }
    }

    //console.log(this.checkedList2.length);
  }
  getCountries() {
    this.authenticationService.getCountries().subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];
          // console.log(this.countries);

        }
      }, error => {
        console.log(error);
      });
  }
  getStates(id) {
    this.authenticationService.getStates(id).subscribe(
      response => {
        if (response['stateList'] !== {}) {
          this.states = response['stateList'];
        }
      }, error => {
        console.log(error);
      });
  }
  getCities(id) {
    this.authenticationService.getCities(id).subscribe(
      response => {
        if (response['CitiesList'] !== {}) {
          this.cities = response['CitiesList'];
        }
      }, error => {
        console.log(error);
      });
  }
  addownerInfoForm() {

    this.submittedowner = true;
    if (this.ownerInfoForm.invalid) {
      return false;
    }
    var data = this.ownerInfoForm.value;
    var myPostObject = {
      name: data.name,
      email: data.email,
      phone: data.telephone,
      street1: data.address1,
      street2: data.address2,
      country: data.Country,
      county: data.county,
      city: data.city,
      postcode: data.postcode
    }
    this.authenticationService.updateownerinfo(myPostObject, this.chipnumber)
      .pipe(first())
      .subscribe(
        data => {
          this.submittedowner = false;
          this.ownerInfoForm.reset();
          this.modalRef.hide();
          setTimeout(() => this.toastr.success('Owner Info updated successfully!'), 1000);

        },
        error => {
          setTimeout(() => this.toastr.error(error.error.msg))
        });
  }

  reportLost() {
    console.log(this.temp_petid);
    if (this.temp_petid == undefined) {
      setTimeout(() => this.toastr.error('No pet assigned for this chip'))
      return false;
    }
    let dataobject = {
      details: this.report_info
    }
    //setTimeout(() => this.toastr.info('Pet id is '+ this.temp_petid));
    this.authenticationService.reportLostPet(this.temp_petid, dataobject).subscribe(
      response => {
        if (response['status'] == 200) {
          setTimeout(() => this.toastr.success('Pet status updated successfully!'))
          this.modalRef.hide();
          this.getmychips();
        }
      }, error => {
        setTimeout(() => this.toastr.error('Something went wrong'))
      });
  }
  report_deceased() {
    if (this.temp_petid == undefined) {
      setTimeout(() => this.toastr.error('No pet assigned for this chip'))
      return false;
    }
    this.authenticationService.reportDeceased(this.temp_petid).subscribe(
      response => {
        if (response['status'] == 200) {
          setTimeout(() => this.toastr.success('Pet status updated successfully!'))
          this.modalRef.hide();
          this.getmychips();
        }
      }, error => {
        setTimeout(() => this.toastr.error('Something went wrong'))
      });
    // setTimeout(() => this.toastr.info('Pet id isss '+ this.temp_petid));
  }


  addbreederInfo() {
    // console.log('i am in func addbreeder');

    this.submittedbreeder = true;
    if (this.breederInfoForm.invalid) {

      console.log('in error');
      return false;
    }
    var data = this.breederInfoForm.value;
    console.log(data);
    let petdob = data.petdob.year + '-' + data.petdob.month + '-' + data.petdob.day;

    var myPostObject = {
      kennel: data.kennelname,
      name: data.name,
      email_address: data.email,
      phone: data.telephone,
      street1: data.address1,
      street2: data.address2,
      country: data.Country,
      county: data.county,
      city: data.city,
      postcode: data.postcode,
      licence_number: data.license_no,
      council_name: data.council_name,

      pet_name: data.pet_name,
      pet_type: data.pet_type,
      sex: data.sex,
      breed: data.pet_breed,
      colour: data.pet_color,
      for_breeding: data.for_breeding,
      neutered: data.neutered,
      dob: petdob,

    }
    this.authenticationService.updatebreeder(myPostObject, this.chipnumber)
      .pipe(first())
      .subscribe(
        data => {

          this.submittedbreeder = false;
          this.breederInfoForm.reset();
          this.getmychips();
          setTimeout(() => this.toastr.success('Breeder Info updated successfully!'), 1000);
          this.modalRef.hide();

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
        });
  }


  save_pet_for_owner() {
    this.submitted = true;
    if (this.add_transfer_petinfo_form.invalid) {

      console.log('in error', this.add_transfer_petinfo_form.value);
      return false;
    }
    var data = this.add_transfer_petinfo_form.value;

    let petdob = data.petdob.year + '-' + data.petdob.month + '-' + data.petdob.day;
    let pet_dateimplanted = data.dateimplanted.year + '-' + data.dateimplanted.month + '-' + data.dateimplanted.day;

    var myPostObject = {
      chip_no: data.chipno,
      dateimplanted: pet_dateimplanted,
      pet_name: data.pet_name,
      pet_type: data.pet_type,
      sex: data.sex,
      breed: data.pet_breed,
      for_breeding: data.for_breeding,
      neutered: data.neutered,
      dob: petdob,
      users_email: this.fetched_user_email

    }
    console.log(myPostObject);
    //return false;
    this.authenticationService.transfer_chip_to_owner(myPostObject)
      .pipe(first())
      .subscribe(
        data => {

          // this.submittedbreeder = false;
          // this.breederInfoForm.reset();
          this.getmychips();
          setTimeout(() => this.toastr.success('Pet transferred to owner successfully!'), 1000);
          this.modalRef.hide();
          this.add_transfer_petinfo_form.reset();
          this.changeOwnerForm.reset();

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
        });
  }


  setchipno(chipid, action) {
    this.action = action;
    this.chipnumber = chipid;
    this.submittedbreeder = false;
    this.breederInfoForm.reset();
    this.getbreederandPetinfo(this.chipnumber);
    this.getOwnerProvisionalinfo(this.chipnumber);
  }

  getPetTypes() {
    this.authenticationService.getallpettypes().subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['petTypeList'] !== {}) {
          this.pet_types = response['petTypeList'];
          console.log(this.pet_types);

        }
        //}
      }, error => {
        console.log(error);
      });
  }

  getbreederandPetinfo(chipid) {
    this.authenticationService.getbreederandPetinfo(chipid).subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['BreederPetinfo'].length != 0) {
          var alldata = response['BreederPetinfo'];
          // this.getStates(alldata[0].breeder_info.country);
          // this.getCities(alldata[0].breeder_info.county);            
          console.log(alldata[0].pet.name);

          this.dueDate_array = alldata[0].pet.dob.split('-');
          let dyear = this.dueDate_array[0];
          let dmonth = this.dueDate_array[1];
          let ddate = this.dueDate_array[2];

          this.dobdate = { year: parseInt(dyear), month: parseInt(dmonth), day: parseInt(ddate) };


          this.breederInfoForm = this.formBuilder.group({
            kennelname: [alldata[0].breeder_info.kennel, ''],
            email: [alldata[0].breeder_info.email_address, Validators.required],
            name: [alldata[0].breeder_info.name, Validators.required],
            telephone: [alldata[0].breeder_info.phone, Validators.required],
            address1: [alldata[0].breeder_info.street1, Validators.required],
            address2: [alldata[0].breeder_info.street2, Validators.required],

            postcode: [alldata[0].breeder_info.postcode, Validators.required],
            license_no: [alldata[0].breeder_info.licence_number, Validators.required],
            council_name: [alldata[0].breeder_info.council_name, Validators.required],
            pet_name: [alldata[0].pet.name, Validators.required],
            pet_type: [alldata[0].pet.pet_type, Validators.required],
            sex: [alldata[0].pet.sex, Validators.required],
            pet_breed: [alldata[0].pet.breed, Validators.required],
            pet_color: [alldata[0].pet.colour, Validators.required],
            for_breeding: [alldata[0].pet.for_breeding, Validators.required],
            neutered: [alldata[0].pet.neutered],
            petdob: [this.dobdate, Validators.required],
            Country: [alldata[0].breeder_info.country, ''],
            county: [alldata[0].breeder_info.county, ''],
            city: [alldata[0].breeder_info.city, ''],
          });

        } else {
          this.breederInfoForm = this.formBuilder.group({
            kennelname: ['', Validators.required],
            email: ['', Validators.required],
            name: ['', Validators.required],
            telephone: ['', Validators.required],
            address1: ['', Validators.required],
            address2: ['', Validators.required],
            Country: ['', Validators.required],
            county: ['', Validators.required],
            city: ['', Validators.required],
            postcode: ['', Validators.required],
            license_no: [''],
            council_name: [''],
            pet_name: [''],
            pet_type: [''],
            sex: [''],
            pet_breed: [''],
            pet_color: [''],
            for_breeding: ['', Validators.required],
            neutered: [''],
            petdob: [''],
          });
          // setTimeout(() => this.toastr.error('Something went wrong!'));
        }
        //}
      }, error => {
        console.log(error);
      });
  }
  petUpdate(id) {
    this.temp_petid = id;
  }
  getOwnerProvisionalinfo(chipid) {

    this.authenticationService.getOwnerProvisionalinfo(chipid).subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['Provisionalinfo'].length != 0) {
          this.ownertitle = 'Edit Owner Info';
          var ownerdata = response['Provisionalinfo'];
          console.log(ownerdata);
          this.ownerInfoForm.reset();
          this.getStates(ownerdata[0].country);
          this.getCities(ownerdata[0].county);
          this.ownerInfoForm.patchValue({ email: ownerdata[0].email_address });
          this.ownerInfoForm.patchValue({ name: ownerdata[0].name });
          this.ownerInfoForm.patchValue({ telephone: ownerdata[0].phone });
          this.ownerInfoForm.patchValue({ address1: ownerdata[0].street1 });
          this.ownerInfoForm.patchValue({ address2: ownerdata[0].street2 });
          this.ownerInfoForm.patchValue({ Country: ownerdata[0].country });
          this.ownerInfoForm.patchValue({ county: ownerdata[0].county });
          this.ownerInfoForm.patchValue({ city: ownerdata[0].city });
          this.ownerInfoForm.patchValue({ postcode: ownerdata[0].postcode });
          // this.ownerInfoForm = this.formBuilder.group({
          //   email: [ownerdata[0].email_address, Validators.required],
          //   name: [ownerdata[0].name, Validators.required],
          //   telephone: [ownerdata[0].phone, Validators.required],
          //   address1: [ownerdata[0].street1, Validators.required],
          //   address2: [ownerdata[0].street2, Validators.required],
          //   Country: [ownerdata[0].country, Validators.required],
          //   county: [ownerdata[0].county, Validators.required],
          //   city: [ownerdata[0].city, Validators.required],
          //   postcode: [ownerdata[0].postcode, Validators.required],
          // });             

        } else {
          this.ownertitle = 'Add Owner Info';
          this.ownerInfoForm = this.formBuilder.group({
            email: ['', Validators.required],
            name: ['', Validators.required],
            telephone: ['', Validators.required],
            address1: ['', Validators.required],
            address2: ['', Validators.required],
            Country: ['', Validators.required],
            county: ['', Validators.required],
            city: ['', Validators.required],
            postcode: ['', Validators.required],
          });
          //setTimeout(() => this.toastr.error('Something went wrong!'));
        }
        //}
      }, error => {
        console.log(error);
      });
  }
  addchangeownerRequest() {
    if (this.fetched_usertype == "User") {
      this.modalRef.hide();

      console.log('c2', this.checkedList2);
      console.log('c3', this.checkedList3[0]);

      this.add_transfer_petinfo_form.patchValue({ chipno: this.selected_chip_no });
      this.add_transfer_petinfo_form.patchValue({ dateimplanted: this.selected_date });
      //this.add_transfer_petinfo_form.patchValue({});
      this.chipnumb = this.selected_chip_no;
    } else {
      //let petid = 5;
      this.submittedowner = true;
      if (this.changeOwnerForm.invalid) {
        return false;
      }
      var data = this.changeOwnerForm.value;
      var myPostObject = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phone: data.telephone,
        street1: data.address1,
        street2: data.address2,
        country: data.Country,
        county: data.county,
        city: data.city,
        postcode: data.postcode,
        user_type: data.userType,
        petids: this.checkedList,
        chipids: this.checkedList2,
      }
      //console.log(myPostObject);

      // if (myPostObject.petids[0] == '') {
      //   setTimeout(() => this.toastr.error('Pet Not Assign For this Chip'), 1000);
      //   return false;
      // }



      this.authenticationService.transfer_chips(myPostObject)
        .pipe(first())
        .subscribe(
          data => {
            //  console.log(data.change_owner.id);   
            localStorage.setItem('change_request_id', data.change_owner.id);

            this.submittedowner = false;
            this.modalRef.hide();
            if (this.changeOwnerForm.value.userType == 'pet_owner') {
              this.add_transfer_petinfo_form.patchValue({ chipno: this.selected_chip_no });
              this.chipnumb = this.selected_chip_no;
              let el4: HTMLElement = this.myDiv4.nativeElement as HTMLElement;
              el4.click();
            }
            this.changeOwnerForm.reset();
            this.getmychips();
            setTimeout(() => this.toastr.success('Chips transferred successfully!'), 1000);


          },
          error => {
            setTimeout(() => this.toastr.error(error.error.msg))
          });
    }
    return false;

  }

  @HostListener('window:popstate')
  onPopstate() {
    this.handler.close();
  }

  openCheckout() {
    var localthis = this;
    this.items = JSON.parse(localStorage.getItem('items'));
    this.handler = (<any>window).StripeCheckout.configure({
      image: './../assets/img/logo.png',
      key: environment.stripeKey,
      locale: 'auto',
      token: function (token: any) {
        //console.log(token);
        localthis.updateTransferRequest(token);
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
      }
    });

    this.handler.open({
      name: 'Protected Pet (c)',
      email: this.useremail,
      description: ' Ownership change Payment',
      currency: 'GBP',
      amount: 22.99 * 100
    });

  }

  updateImplanterDate(event, chipnumber) {
    let syear = event.year;
    let smonth = event.month;
    let sdate = event.day;
    console.log(sdate + '-' + smonth + '-' + syear);
    console.log(chipnumber);

    var myPostObject = {
      implant_date: syear + '-' + smonth + '-' + sdate,
    }

    this.authenticationService.updateImplanterDate(myPostObject, chipnumber)
      .pipe(first())
      .subscribe(
        data => {
          localStorage.removeItem('change_request_id');
          setTimeout(() => this.toastr.success('Implanter Date successfully updated!'));
          this.getmychips();
          //this.modalRef.hide();
        },
        error => {
          setTimeout(() => this.toastr.error('something went wrong'))
        });

  }
  updateTransferRequest(token) {
    var myPostObject = {
      status: 'complete',
      transaction_id: token.id,
    }
    var change_request_id = localStorage.getItem('change_request_id');
    this.authenticationService.updateTransferRequest(myPostObject, change_request_id)
      .pipe(first())
      .subscribe(
        data => {
          localStorage.removeItem('change_request_id');
          setTimeout(() => this.toastr.success('Owner change request successfully placed!'));

          this.modalRef.hide();
        },
        error => {
          setTimeout(() => this.toastr.error(error.msg))
        });
  }
}
