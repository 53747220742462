import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Http, Headers, Response } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { throwError, BehaviorSubject } from 'rxjs';
import { NavbarService } from '../services/navbar.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs/Subject';
interface IServerResponse {
  items: string[];
  total: number;
}
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  navchange: EventEmitter<boolean> = new EventEmitter();
  getproducts: EventEmitter<boolean> = new EventEmitter();
  userloggedIn: EventEmitter<boolean> = new EventEmitter();
  productcartCount: EventEmitter<any> = new EventEmitter();
  cartstatus: EventEmitter<boolean> = new EventEmitter();
  unpaid_payment_status: EventEmitter<boolean> = new EventEmitter();
  loggedin: boolean;
  HideMyAccount: boolean;
  private messageSource = new BehaviorSubject('0');
  currentMessage = this.messageSource.asObservable();
  constructor(private cookieService: CookieService, public nav: NavbarService, private router: Router, private http: HttpClient) { }
  // baseUrl: string = 'http://hash.breota.com/api';
  baseUrl: string = environment.api_url;

  private _listners = new Subject<any>();
  toggle() {
    this.navchange.emit(true);
  }

  callproducts() {
    this.getproducts.emit(true);
  }

  loggedOut() { this.loggedin = false; }

  loggedIn() {
    this.loggedin = true;
    this.userloggedIn.emit(true);
  }

  unpaid() {
    this.HideMyAccount = true;
    this.unpaid_payment_status.emit(this.HideMyAccount);
  }

  unpaidSuccess() {
    this.HideMyAccount = false;
    this.unpaid_payment_status.emit(this.HideMyAccount);
  }


  items: any = [];
  updateCart(item: any) {
    this.items.next(item)
  }

  changeMessage(message) {
    //is.productcartCount.emit(0);
    this.messageSource.next(message)
  }

  emptycart() {
    localStorage.removeItem('items');
    this.items = [];
    this.changeMessage('0');
    this.cartstatus.emit(true);
  }

  sharingData: any = '';
  dataFromService;
  evaluatoraction;
  fileToUpload;
  formaction;
  checklogin: boolean = false;
  SolId;
  account_type;
  solisticationId;
  addSolitication;
  usertype;
  login(username: string, password: string, provider: string) {
    //let provider= 'admins';
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };
    return this.http.post<any>(this.baseUrl + `/login`, { username, password, provider }, httpOptions)
      .pipe(map(data => {
        if (data && data.access_token) {
          // console.log(data.access_token);
          localStorage.setItem('currentUser', JSON.stringify(data.access_token));
          localStorage.setItem('userid', JSON.stringify(data.id));
          localStorage.setItem('user_type', JSON.stringify(data.user_type));
          //this.cookieService.set('user_type', JSON.stringify(data.user_type), 1);
          localStorage.setItem('account_type', JSON.stringify(data.account_type));

          localStorage.setItem('user_account', data.User_gold);
          localStorage.setItem('user_email', data.email);
          localStorage.setItem('date_time_format', JSON.stringify(data.site_dateTime));
          this.usertype = data.user_type.replace(/^"(.*)"$/, '$1');


          //console.log(this.account_type);

          if (this.usertype == 'users') {
            let usercurrency = JSON.stringify(data.user_currency);
            usercurrency = usercurrency.replace(/^"(.*)"$/, '$1');

            let is_vet = data.is_vet;
            localStorage.setItem('is_vet', JSON.stringify(is_vet));


            localStorage.setItem('user_currency', usercurrency);
            this.loggedIn();
            this.account_type = JSON.stringify(data.account_type);
            this.account_type = data.account_type.replace(/^"(.*)"$/, '$1');

            console.log('acctype' + this.account_type);

            if (this.account_type == 'User') {
              this.account_type = 'Pet Owner Account';
            } else {
              this.account_type = this.account_type + ' Account';
            }
            localStorage.setItem('user_account_type', this.account_type);
            //console.log('this is from last yp');

            //this.frontCartOrderList();
          }
        }
        return data;
      }));
  }
  logout() {
    // remove user from local storage to log user out
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };
    return this.http.post<any>(this.baseUrl + `/logout`, null, httpOptions)
      .pipe(map(data => {
        if (data && data.access_token) {
          // console.log(data.access_token);
          this.nav.hide();
          localStorage.removeItem('currentUser');
          localStorage.clear();
          this.router.navigate(['/login']);
          this.cookieService.delete('temp_currency_id');
        }
        return data;
      }));

  }
  getuserbyemail(email) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getuserbyemail/' + email, { headers });
  }
  getAllfaq() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getAllFaq', { headers });
  }

  getallusers() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/userlists', { headers });
  }
  getuser(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getuser/' + id, { headers });
  }
  getChangeOwnerPdf() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/changeownergenrate', { headers });
  }
  // userslist() {
  //   var token = localStorage.getItem("currentUser");
  //   if (token) {
  //     token = token.replace(/^"(.*)"$/, '$1');
  //   }
  //   //
  //   const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
  //   return this.http.get(this.baseUrl + '/getallusers', { headers });
  // }

  userslist(data = {}) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/getallusers', data, httpOptions);
  }
  getPetinfo(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/pet/' + id, { headers });
  }
  getpetinfo_by_chipno(chipno) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getpetby_chipnumber/' + chipno, { headers });
  }
  getalluserspagination(url) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(url, { headers });
  }
  getSearchUsers(data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/searchusers/' + data, { headers });
  }
  getSearchData(url, data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/' + url + '/' + data, { headers });
  }
  searchByQueryString(url, data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/' + url + '?search=' + data, { headers });
  }
  postSearchData(url, data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/' + url, data, httpOptions);
  }
  postSearchByQueryString(url, data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/' + url + '?search=' + data, null, httpOptions);
  }
  getmypets() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/petfrontchiplist', { headers });
  }

  getAllProducts() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getAllProducts', { headers });
  }

  getmydocuments() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/mydocuments', { headers });
  }
  openpetdocument(pid) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/petdocument/' + pid, { headers });
  }

  openusercertificate(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/userdocument/' + id, { headers });
  }

  openpetcertificate(pid) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/petcertificate/' + pid, { headers });
  }
  ordercancel(orderid) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.post(this.baseUrl + '/cancelorder/' + orderid, { headers });
  }
  openPdfInvoice(orderid) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/frontordergenerate/' + orderid, { headers });
  }
  getmychips() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/petfrontchipmicrochips', { headers });
  }
  getbreederandPetinfo(chipid) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getbreederandPetinfo/' + chipid, { headers });
  }
  getOwnerProvisionalinfo(chipid) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getOwnerProvisionalinfo/' + chipid, { headers });
  }
  getorderhistory() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/frontorderlist', { headers });
  }
  frontCartOrderList() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/frontCartOrderList', { headers });
  }
  getCountries() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/countrieslist', { headers });
  }
  getCurrencies() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/currencieslist', { headers });
  }
  updateCurrency(cid) {
    console.log(cid + 'in serv');

    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/storeusercurrency/' + cid, null, httpOptions);
  }
  getStates(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/statelist/' + id, { headers });
  }
  getCities(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/citieslist/' + id, { headers });
  }
  getsingleuser(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/user/' + id, { headers });
  }
  getPetTypes() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/pettypelist', { headers });
  }
  getallpettypes() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getallpettypes', { headers });
  }
  getPetlist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/petlist', { headers });
  }
  sitesettinglist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/sitesettinglist', { headers });
  }
  getVendors() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/vendorlist', { headers });
  }
  getProductCats() {
    var token = localStorage.getItem("currentUser");

    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/frontproductcatlist', { headers });
  }
  // getProducts(id,cur_id){
  //   var token = localStorage.getItem("currentUser");
  //   var applId = null;
  //   //console.log('curid>>>'+cur_id);
  //   if(token){

  //     applId = localStorage.getItem("userid");
  //     //console.log('i am in token'+applId);
  //     token = token.replace(/^"(.*)"$/, '$1');
  //     if(applId){

  //       let user_type = localStorage.getItem("user_type");
  //       user_type = user_type.replace(/^"(.*)"$/, '$1');
  //       if(user_type == 'users'){
  //         applId = applId.replace(/^"(.*)"$/, '$1');
  //       }
  //     }
  //   }
  //   const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
  //   return this.http.get(this.baseUrl+'/frontproductlist/'+id+'/'+applId+'/'+cur_id, { headers });          
  // }
  removeItemfromOrder(pid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    //const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.post(this.baseUrl + '/deleteItem/' + pid, null, httpOptions);
  }
  getProducts(id, cur_id) {
    var token = localStorage.getItem("currentUser");
    var applId = null;
    var newuserid = null;
    //console.log('curid>>>'+cur_id);
    if (token) {

      applId = localStorage.getItem("userid");
      //console.log('i am in token'+applId);
      token = token.replace(/^"(.*)"$/, '$1');
      if (applId) {

        let user_type = localStorage.getItem("user_type");
        user_type = user_type.replace(/^"(.*)"$/, '$1');
        if (user_type == 'users') {
          newuserid = applId.replace(/^"(.*)"$/, '$1');
        }
      }
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/frontproductlist/' + id + '/' + newuserid + '/' + cur_id, { headers });
  }
  getProductName() {
    var token = localStorage.getItem("currentUser");
    var applId = null;
    //console.log('curid>>>'+cur_id);
    if (token) {

      applId = localStorage.getItem("userid");
      //console.log('i am in token'+applId);
      token = token.replace(/^"(.*)"$/, '$1');
      if (applId) {

        let user_type = localStorage.getItem("user_type");
        user_type = user_type.replace(/^"(.*)"$/, '$1');
        if (user_type == 'users') {
          applId = applId.replace(/^"(.*)"$/, '$1');
        }
      }
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/productName', { headers });
  }

  getsaleProducts(cur_id) {
    var token = localStorage.getItem("currentUser");
    var applId = null;
    //console.log('curid>>>'+cur_id);
    if (token) {

      applId = localStorage.getItem("userid");
      //console.log('i am in token'+applId);
      token = token.replace(/^"(.*)"$/, '$1');
      if (applId) {

        let user_type = localStorage.getItem("user_type");
        user_type = user_type.replace(/^"(.*)"$/, '$1');
        if (user_type == 'users') {
          applId = applId.replace(/^"(.*)"$/, '$1');
        }
      }
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getsaleproduct/' + applId + '/' + cur_id, { headers });
  }
  getSingleProductData(id, cur_id) {
    var token = localStorage.getItem("currentUser");
    var applId = null;
    console.log('curid>>>' + cur_id);

    if (token) {
      applId = localStorage.getItem("userid");
      //console.log('i am in token'+applId);
      token = token.replace(/^"(.*)"$/, '$1');
      if (applId) {
        applId = applId.replace(/^"(.*)"$/, '$1');
      }
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/frontproductdata/' + id + '/' + applId + '/' + cur_id, { headers });
  }
  requestownerlist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/requestownerlist', { headers });
  }
  deleteuser(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    //const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.post(this.baseUrl + '/userdelete/' + id, null, httpOptions);
  }
  deleteVendor(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.delete(this.baseUrl + '/vendor/' + id, httpOptions);
  }
  getCatById(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/categories/' + id, { headers });
  }
  deleteCat(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.delete(this.baseUrl + '/categories/' + id, httpOptions);
  }

  deletePetType(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/pettypedelete/' + id, null, httpOptions);
  }
  chipnumber(data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/chipnumber/' + data, { headers });
  }
  getchipdetails(chipno) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.post(this.baseUrl + '/getchipinfo/' + chipno, { headers });
  }
  chipnumberwithbypass(chipno, bypasscode) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/checkchipnumber/' + chipno + '/' + bypasscode, { headers });
  }
  deletePet(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.delete(this.baseUrl + '/pet/' + id, httpOptions);
  }
  updatePetinfo(postobject, id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.put(this.baseUrl + '/pet/' + id, postobject, httpOptions);
  }


  updateOrders(postobject, id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.put(this.baseUrl + '/order/' + id, postobject, httpOptions);



  }
  adduser(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/userregister`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  registeruser(userobject: any) {
    var applId = localStorage.getItem("userid");
    const httpOptions = {

    };
    return this.http.post<any>(this.baseUrl + `/userregisteration`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  changeOwner(userobject: any, petid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/changeowner/` + petid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  multichangeOwner(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/multichangeowner`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  transfer_chips(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/transfer_chips`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  updateTransferRequest(userobject: any, cid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/updatetransfercharge/` + cid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updateImplanterDate(userobject: any, cid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/update_implant_date/` + cid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updatebreeder(userobject: any, chipid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/addchipbreeder/` + chipid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  transfer_chip_to_owner(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/transfer_chip_to_owner`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  updateownerinfo(userobject: any, chipid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/addchipowner/` + chipid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updateholidayaddress(userobject: any, chipid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/updateownerholidayaddress/` + chipid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  addholidayaddress(userobject: any, chipid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/updateholidayaddress/` + chipid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  updateUser(userobject, id) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/userupdate/` + id, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updateSiteSettings(userobject) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<any>(this.baseUrl + `/sitesetting/1`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  addPetname(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/addpettype`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updatePetname(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/pettypeupdate/` + userobject.id, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  reportlost(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': 'Bearer '+token
      })
    };
    return this.http.post<any>(this.baseUrl + `/reportlost`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  addnewPet(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/pet`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  addnewChipset(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/addchipset`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  adduserPet(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/frontpet`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updateuserPet(userobject: any, pid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/frontpetupdate/` + pid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }



  updateuserDetail(userobject: any, pid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/frontuserupdate/` + pid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  addOrder(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/order`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }


  addDiscountCode(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/discount`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  addFaq(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/addfaq`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }



  addCategory(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({

        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/categories`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updateCategory(id, userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({

        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/categoriesupdate/` + id, userobject, httpOptions)
      .pipe(map(data => {
        return data;
      }));
  }
  addProduct(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({

        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/product`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  getProduct(id) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({

        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<any>(this.baseUrl + `/product/` + id, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updateProduct(productobject: any, pid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/product/` + pid, productobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  update_payment(status) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    let userobject = null
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<any>(this.baseUrl + `/user_payment_update/` + status + '/' + applId, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  uploadProductImage(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({

        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/uploadproductimage`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  addVendor(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/vendor`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updateVendor(id, userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<any>(this.baseUrl + `/vendor/` + id, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  getProductCat() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/productcatlist', { headers });
  }
  getPetStats() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/petstat', { headers });
  }
  getProductlist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/productlist', { headers });
  }
  getChipsetlist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/chiplist', { headers });
  }
  getChipList() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/petchiplist', { headers });
  }
  applyDiscountCode(code) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/applydiscount/' + code, { headers });
  }
  approveusersList() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/approveruserslist', { headers });
  }
  lostfoundlist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/lostfoundlist', { headers });
  }
  reportLostPet(id, data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    //return this.http.post(this.baseUrl + '/lostfound/' + id, { headers });
    return this.http.post<any>(this.baseUrl + `/lostfound/` + id, data, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  reportLostChip(id, data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    //return this.http.post(this.baseUrl + '/lostfound/' + id, { headers });
    return this.http.post<any>(this.baseUrl + `/reportLostChip/` + id, data, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  reportDeceased(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/reportdeceased/' + id, { headers });
  }
  discountlist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/discountlist', { headers });
  }

  faqlist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/faqList', { headers });
  }
  deleteFaq(id) {

    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.delete(this.baseUrl + '/deletefaq/' + id, httpOptions);
  }

  shippinglist() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/getshipping', { headers });
  }
  ordersList() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/orderlist', { headers });
  }
  deleteProduct(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.delete(this.baseUrl + '/product/' + id, httpOptions);
  }

  approveUser(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/approverusers/' + id, null, httpOptions);
  }

  declineUser(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/declineusers/' + id, null, httpOptions);
  }



  deleteShipping(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.delete(this.baseUrl + '/shipping/' + id, httpOptions);
  }
  getSingleShipping(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.get(this.baseUrl + '/shipping/' + id, httpOptions);
  }
  addShipping(userobject: any) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/shipping`, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updateShipping(userobject: any, sid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<any>(this.baseUrl + `/shipping/` + sid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  getSingleProduct(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.get(this.baseUrl + '/product/' + id, httpOptions);
  }
  deleteChipset(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.delete(this.baseUrl + '/addchipset/' + id, httpOptions);
  }

  deleteLostfound(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.delete(this.baseUrl + '/lostfound/' + id, httpOptions);
  }
  /************************Edit Discount *********************************/

  getSingleDiscount(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.get(this.baseUrl + '/discount/' + id, httpOptions);
  }


  getSingleFaq(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    // const headers = new HttpHeaders().set("Authorization",'Bearer '+token);
    return this.http.get(this.baseUrl + '/faq/' + id, httpOptions);
  }
  /**************************End Discount *********************************/




  getOrderDetails(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/order/' + id, { headers });
  }
  /**************************Update Discount *******************************/
  updateDiscount(userobject: any, sid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<any>(this.baseUrl + `/discount/` + sid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  updateFaq(userobject: any, sid) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<any>(this.baseUrl + `/updateFaq/` + sid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }









  /***************************End Update Discount *****************************/




  deleteDiscount(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.delete(this.baseUrl + '/discount/' + id, httpOptions);
  }
  deleteOrder(id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.delete(this.baseUrl + '/order/' + id, httpOptions);
  }
  requestownerapprove(id) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/requestownerapprove/` + id, null, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  requestownerdecline(id) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/requestownerdecline/` + id, null, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  getAccoutdetails() {
    var token = localStorage.getItem("currentUser");
    var userid = localStorage.getItem("userid");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
      userid = userid.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/frontuser/' + userid, { headers });
  }
  userchangepassword(object) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/userchangepassword`, object, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  contact(object) {
    // var token = localStorage.getItem("currentUser");
    //var applId = localStorage.getItem("userid");
    //token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': 'Bearer '+token
      })
    };
    return this.http.post<any>(this.baseUrl + `/contact`, object, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  frontorder(object) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/frontorder`, object, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  serverCall(meals: string[], page: number): Observable<IServerResponse> {
    const perPage = 10;
    const start = (page - 1) * perPage;
    const end = start + perPage;

    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    //return this.http.get(this.baseUrl+'/userlists', { headers });      

    return Observable
      .of({
        items: meals.slice(start, end),
        total: 100
      }).delay(1000);
  }

  upgradeSubscription(tokenobject) {
    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<any>(this.baseUrl + `/upgradesubscription`, tokenobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  updateOrder(orderobject, id) {

    var token = localStorage.getItem("currentUser");
    var applId = localStorage.getItem("userid");
    token = token.replace(/^"(.*)"$/, '$1');
    // applId = applId.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.put<any>(this.baseUrl + `/frontorder/` + id, orderobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  sendResetPasswordLink(email) {
    var token = localStorage.getItem("currentUser");
    //token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': 'Bearer '+token
      })
    };
    return this.http.get<any>(this.baseUrl + `/forgetpassword/` + email, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }
  updatePassword(userobject, userid) {
    var token = localStorage.getItem("currentUser");
    //token = token.replace(/^"(.*)"$/, '$1');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': 'Bearer '+token
      })
    };
    return this.http.post<any>(this.baseUrl + `/resetpassword/` + userid, userobject, httpOptions)
      .pipe(map(data => {
        console.log(data);
        return data;
      }));
  }

  downloadFile(data) {
    const blob = new Blob([data], { type: 'text/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
  updateChipStatus(data) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/showhidechip', data, httpOptions);
  }


  updateOrderStatus(data) {
    console.log("hunny singh");
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(this.baseUrl + '/orderstatus', data, httpOptions);
  }



  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  filter(filterBy: string) {
    this._listners.next(filterBy);
  }

  deleteProductImage(product_id, image_id) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    //
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + `/image_delete/` + product_id + '/' + image_id, { headers });
  }


  getTransferHistroy() {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/transferhistory', { headers });
  }
  getAdminTransferHistroy(pet_id, chipno) {
    var token = localStorage.getItem("currentUser");
    if (token) {
      token = token.replace(/^"(.*)"$/, '$1');
    }
    const headers = new HttpHeaders().set("Authorization", 'Bearer ' + token);
    return this.http.get(this.baseUrl + '/admintransferhistory/' + pet_id + '/' + chipno, { headers });
  }
}
