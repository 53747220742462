import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-transferhistory',
  templateUrl: './transferhistory.component.html',
  styleUrls: ['../../assets/css/style.css', './transferhistory.component.scss']
})
export class TransferhistoryComponent implements OnInit {
  pets;
  history;
  emptyhistory;
  p;

  constructor(
    private authenticationService: AuthenticationService,
  ) {

  }

  ngOnInit() {
    this.gettransferhistory();
  }
  gettransferhistory() {

    this.authenticationService.getTransferHistroy().subscribe(
      response => {


        if (response['transferHistory'].length != 0) {
          this.history = response['transferHistory'];
          //console.log("hunny");
          console.log(this.history);
          let totalhistory = response['transferHistory']['data'].length;
          //console.log(totalhistory);
          if (totalhistory == 0) {
            this.emptyhistory = true;
          } else {
            this.emptyhistory = false;
          }





        } else {
          console.log('i am getting empty chips');

        }

      }, error => {
        console.log(error);
      });
  }

}
