import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-viewusers',
  templateUrl: './viewusers.component.html',
  styleUrls: ['./viewusers.component.scss']
})
export class ViewusersComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  response;
  loading: boolean = true;
  showLoader: boolean = false;
  @ViewChild('myDiv3') myDiv3: ElementRef;
  pdfurl;
  @Input('data') data: string[] = [];
  users: Observable<string[]>;
  p: number = 1;
  total: number;
  dateFormat;
  userFilter: any = [];
  searchUser: string = "";
  constructor(private cookieService: CookieService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  get f() { return this.loginForm.controls; }
  ngOnInit() {
    this.param2 = localStorage.getItem("currentUser");
    console.log(this.param2);

    this.dateFormat = localStorage.getItem("date_time_format");
    this.dateFormat = this.dateFormat.replace(/^"(.*)"$/, '$1');
    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }
    this.getUsers(1);
  }
  getUsers(page: number) {
    this.showLoader = true;
    this.authenticationService.getallusers().subscribe(
      response => {
        this.showLoader = false;
        if (response['userList'] !== {}) {
          this.data = response['userList'].data;
          this.total = response['userList'].total;
          this.response = response['userList'];
          this.p = page;
        }
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }
  deleteuser(id) {
    if (confirm("Are you sure you want to delete")) {
      this.authenticationService.deleteuser(id).subscribe(
        response => {
          this.getUsers(1);
          if (response['status'] == 200) {
            this.getUsers(1);
            setTimeout(() => this.toastr.success('User deleted successfully!'))
          }
        }, error => {
          setTimeout(() => this.toastr.error(error.err.msg))
        });
    }
  }
  logout() {
    this.nav.hide();
    this.authenticationService.logout();
  }
  Edituser(id) {
    this.router.navigate(['admin/adduser'], { queryParams: { user_id: id } });
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['userList'] !== {}) {
          this.data = response['userList'].data;
          this.userFilter = [...this.data]
          this.total = response['userList'].total;
          this.p = page;
        }
      }, error => {
        setTimeout(() => this.toastr.error(error.err.msg))
      });
  }


  openCertificate(id) {
    this.authenticationService.openusercertificate(id).subscribe(
      response => {
        if (response !== {}) {
          this.pdfurl = response['data'][0].fileurl;
          console.log(response['data'][0].fileurl);
          setTimeout(() => {
            let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
            el3.click();
            console.log('inside');

          }, 1000);
        } else {
          //this.emptydocs = true;
        }
      }, error => {
        console.log(error);
      });
  }

  updateFilter() {
    if (this.searchUser.trim() == "" || this.searchUser.trim() === "STANDARD") {
      this.getUsers(1);

    } else {

      let api = "searchuser";
      this.authenticationService.searchByQueryString(api, this.searchUser).subscribe(
        response => {
          if (response['userList'].length != 0) {
            this.data = response['userList'].data;
            this.total = response['userList'].total;
            this.response = response['userList'];
            this.p = 0;
          } else {
            this.data = [];
            this.userFilter = [];
            this.total = 0;
            this.p = 0;
          }
        }, error => {
          setTimeout(() => this.toastr.error(error.err.msg))
        });
    }

  }
}
