import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../services/navbar.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public samplePagesCollapsed = true;
  constructor(public nav: NavbarService) { }

  ngOnInit() {
  }

}
