import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { forEach } from '@angular/router/src/utils/collection';
import { environment } from 'src/environments/environment';
import { TabHeadingDirective } from 'ngx-bootstrap';

const now = new Date();

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.scss']
})
export class AddproductComponent implements OnInit {
  addProductForm: FormGroup;
  param2;
  users: any = [];
  data: any = [];
  deletedProductImages: any = [];
  public imagePath;
  imgURL: any;
  submitted: boolean = false;
  showLoader: boolean = true;
  fileToUpload;
  urls = [];
  fronturls: any = [];
  catselected: any = 0;
  pageaction: any = 'add';
  coverImage: any = [];
  updateproduct;
  @ViewChild("ckeditor") ckeditor: any;
  public ckeditorContent: string;
  constructor(config: NgbTabsetConfig,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) { }
  get r() { return this.addProductForm.controls; }
  ngOnInit() {
    this.ckeditorContent = ``;//<p>Greetings from CKEditor...</p>
    this.param2 = localStorage.getItem("currentUser");
    this.nav.show();
    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }
    this.addProductForm = this.formBuilder.group({
      title: ['', Validators.required],
      tags: ['', Validators.required],
      price: ['', Validators.compose([
        Validators.required,
        Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')
      ])],
      sprice: [null, Validators.compose([
        Validators.required,
        Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')
      ])],
      Category: ['', Validators.required],
      description: [''],
      stock: ['', Validators.required],
      is_restricted: ['', Validators.required],
      on_sale: ['', Validators.required],

    });
    this.updateproduct = this.route.snapshot.queryParams['product_id']
    if (this.updateproduct != null) {
      this.pageaction = "update";
      this.getProductdata(this.updateproduct);
    }
    this.getProductCats();
  }

  getallusers() {
    this.authenticationService.getallusers().subscribe(
      response => {
        if (response['userList'] !== {}) {
          this.users = response['userList'];
        }
      }, error => {
        console.log(error);
      });
  }
  getProductdata(id) {
    this.showLoader = true;
    this.authenticationService.getProduct(id).subscribe(
      response => {
        this.showLoader = false;
        if (response['product'] !== {}) {
          let data = response['product'];
          this.addProductForm.patchValue({
            title: data.name,
            tags: data.tags,
            price: data.price,
            sprice: data.sale_price,
            Category: data.product_category_links.cat_id,
            description: data.description,
            stock: data.stock,
            is_restricted: data.is_restricted,
            on_sale: data.on_sale,
          });
          let uploadurl = environment.uploadurl;
          var filesAmount = data.product_image.length;
          for (let i = 0; i < filesAmount; i++) {
            this.fronturls.push({ "product_id": data.id, "image_id": data.product_image[i].id, "image_url": uploadurl + '/' + data.id + '/' + data.product_image[i].image_url, "file": "" });
          }
          this.coverImage = { "image_url": uploadurl + 'product_cover/' + data.cover_image, "file": "" };
          console.log('coverImage', this.coverImage);
        }

      }, error => {
        console.log(error);
      });
  }
  getProductCats() {
    this.authenticationService.getProductCats().subscribe(
      response => {
        if (response['frontProductCatList'] !== {}) {
          this.data = response['frontProductCatList'];
        }
      }, error => {
        console.log(error);
      });
  }
  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;

    for (let i = 0; i < files.length; i++) {
      this.urls.push(files.item(i));
    }
  }
  onSelectFile(event, imageType = '') {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var files = event.target.files;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (event) => {
          if (imageType == 'cover') {
            this.coverImage = { "image_url": (<FileReader>event.target).result, "file": files.item(i) };
          }
          else {
            this.fronturls.push({ "image_url": (<FileReader>event.target).result, "file": files.item(i) });
          }

        }
      }
    }

  }
  addnewProduct() {
    this.submitted = true;
    if (this.addProductForm.invalid) {
      return false;
    }
    var data = this.addProductForm.value;
    let input = new FormData();
    input.append('name', data.title);
    input.append('description', data.description);
    input.append('tags', data.tags);
    input.append('price', data.price);
    input.append('sale_price', data.sprice);
    input.append('stock', data.stock);
    input.append('is_restricted', data.is_restricted);
    input.append('cat_id', data.Category);
    input.append('on_sale', data.on_sale);
    input.append('cover_image', this.coverImage.file);
    if (this.deletedProductImages.length != 0) {
      for (let value of this.deletedProductImages) {
        input.append('deletedProductImages[]', value);
      }
    }
    for (let item of this.fronturls) {
      if (item.file != "") {
        input.append('product_images[]', item.file);
      }
    }
    if (this.pageaction == 'add') {
      let outThis = this;
      this.authenticationService.addProduct(input)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('Product added successfully!'))
            this.addProductForm.reset();
            this.submitted = false;
            this.router.navigate(['/admin/viewproducts']);
          },
          error => {
            setTimeout(() => this.toastr.error(error.error.msg))
          });
    } else {
      input.append('_method', 'PUT');
      this.authenticationService.updateProduct(input, this.updateproduct)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('Product updated successfully!'));
            this.submitted = false;
            // this.router.navigate(['/admin/viewproducts']);
          },
          error => {
            setTimeout(() => this.toastr.error(error.error.msg))
          });

    }
  }

  deleteImage(index) {
    let data = this.fronturls.filter(function (image, i) {
      return i != index;
    });

    let deletedData = this.fronturls.filter(function (image, i) {
      return i == index;
    });
    if (typeof deletedData[0].image_id != 'undefined') {
      this.deletedProductImages.push(deletedData[0].image_id);
    }
    this.fronturls = data;
  }

  deleteImageCover() {

    this.coverImage = [];
  }

  reset() {
    this.addProductForm.reset();
  }

  uploadProductImages(imagefile, productid) {
    let input = new FormData();
    input.append('gallery_img', imagefile);
    input.append('productid', productid);

    this.authenticationService.uploadProductImage(input)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          //setTimeout(() => this.toastr.success('Product added successfully!'))
        },
        error => {
          //setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
}
