import { Component, OnInit, TemplateRef, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PetTypesComponent } from '../pet-types/pet-types.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-newpet',
  templateUrl: './newpet.component.html',
  styleUrls: ['../../assets/css/style.css', './newpet.component.scss']
})
export class NewpetComponent implements OnInit {

  addPetForm: FormGroup;
  viewPetForm: FormGroup;
  holidayAddressForm: FormGroup;
  changeOwnerForm: FormGroup;
  modalRef: BsModalRef;
  uploadUrl: any = environment.uploadurl;
  param2;
  sitedata = [];
  gold_price;
  basic_price;
  isgold_upgrade: boolean = false;
  show_payment_popup: any = '0';
  isbasic_upgrade: boolean = false;
  users: any = [];
  pet_types: any = [];
  submitted: Boolean = false;
  updatesubmitted: Boolean = false;
  submittedholiday: Boolean = false;
  submittedowner: Boolean = false;
  addpet: boolean = true;
  petdata;
  petprofile;
  countries: any = [];
  newItem: any = [];
  states: any = [];
  cities: any = [];
  myVar;
  chipnumb;
  isupdate: boolean = false;
  implant_array: any = [];
  action: string;
  localParams: any;
  user_account;
  dob_array: any = [];
  implantDate;
  dobDate;
  currentPet;
  olimage;
  petimplanted;
  public imagePath;
  fileToUpload;
  imgURL;
  ischipfree: any = 0;
  is_gold_plan: boolean = false;
  items: any = [];
  handler: any;
  useremail;
  maxDate = {};
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('myDiv2') myDiv2: ElementRef;
  @ViewChild('myDiv3') myDiv3: ElementRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  constructor(config: NgbTabsetConfig,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public nav: NavbarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.action = params['a'];
      this.localParams = params;
    });

  }

  get r() { return this.addPetForm.controls; }
  get u() { return this.viewPetForm.controls; }
  get h() { return this.holidayAddressForm.controls; }
  get c() { return this.changeOwnerForm.controls; }

  ngOnInit() {



    //console.log('test6');

    this.useremail = localStorage.getItem('user_email');
    let payment = localStorage.getItem('show_payment_popup');
    console.log('payment--', payment);

    if (payment == '1') {
      let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
      el3.click();
    }

    if (this.GetParam('id') != 0) {
      console.log('uri---' + this.GetParam('id'));

      this.addpet = false;
      let pid = this.GetParam('id');
      this.getPetdetails(pid);
      this.getCountries();

      if (this.GetParam('a') == 'edit') {
        this.isupdate = true;
      }
    }

    this.param2 = localStorage.getItem("currentUser");
    this.user_account = localStorage.getItem("user_account");
    // console.log('is it gold?------->' + this.user_account);


    if (this.param2 == null) {
      console.log('in null');
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }
    this.addPetForm = this.formBuilder.group({
      chipno: ['', [Validators.required, Validators.pattern('^[1-9][0-9]+$'), Validators.minLength(15), Validators.maxLength(15)]],
      description: [''],
      dateimplanted: [''],
      pet_type: ['', Validators.required],
      sex: ['', Validators.required],
      pet_breed: ['', Validators.required],
      // pet_color: ['', Validators.required],
      pet_name: ['', Validators.required],
      pet_medical_info: [''],
      // for_breeding: [''],
      neutered: [''],
      petdob: ['', Validators.required],
      sellername: [''],
      selleraddress: [''],
    });
    this.viewPetForm = this.formBuilder.group({
      chipno: ['', Validators.required],
      description: [''],
      dateimplanted: ['', Validators.required],
      pet_type: ['', Validators.required],
      sex: ['', Validators.required],
      pet_breed: [''],

      pet_name: ['', Validators.required],
      pet_medical_info: [''],

      neutered: [''],
      petdob: [''],
      sellername: [''],
      selleraddress: [''],
      image: ['']
    });
    this.holidayAddressForm = this.formBuilder.group({
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
    });
    this.changeOwnerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      telephone: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      Country: ['', Validators.required],
      county: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
    });
    this.sitesettinglist();
    this.getallusers();
    this.getPetTypes();
  }

  @HostListener('window:popstate')
  onPopstate() {
    this.handler.close();
  }
  getPetdetails(id) {
    this.authenticationService.getPetinfo(id).subscribe(
      response => {
        if (response['data'] !== {}) {
          this.petdata = response['data'];
          console.log(this.petdata);
          console.log('gold plan - ', this.petdata.gold);
          if (this.petdata.gold == 1) {
            this.is_gold_plan = true;
          }
          this.petprofile = this.petdata.name;
          if (this.petdata.date_injected) {
            this.implant_array = this.petdata.date_injected.split('-');
          } else {
            this.implant_array = '2018-12-12'.split('-');
          }
          let imp_year = this.implant_array[0];
          let imp_month = this.implant_array[1];
          let imp_date = this.implant_array[2];


          this.dob_array = this.petdata.dob.split('-');
          let dob_year = this.implant_array[0];
          let dob_month = this.implant_array[1];
          let dob_date = this.implant_array[2];
          this.implantDate = { year: parseInt(imp_year), month: parseInt(imp_month), day: parseInt(imp_date) };
          this.dobDate = { year: parseInt(dob_year), month: parseInt(dob_month), day: parseInt(dob_date) };
          this.currentPet = this.petdata.id;
          this.olimage = this.petdata.Image_url;
          this.imgURL = this.olimage;
          this.viewPetForm = this.formBuilder.group({
            chipno: [this.petdata.chip_no, Validators.required],
            description: [this.petdata.description],
            dateimplanted: [this.implantDate, Validators.required],
            pet_type: [this.petdata.pet_type, Validators.required],
            sex: [this.petdata.sex, Validators.required],
            pet_breed: [this.petdata.breed, Validators.required],
            //pet_color: [this.petdata.colour, Validators.required],
            pet_name: [this.petdata.name, Validators.required],
            pet_medical_info: [this.petdata.medical_info],
            // for_breeding: [this.petdata.for_breeding, Validators.required],
            neutered: [this.petdata.neutered],
            petdob: [this.dobDate, Validators.required],
            sellername: [this.petdata.got_from_name],
            selleraddress: [this.petdata.got_from_address],
            image: [this.petdata.Image_url],
          });
        }
      }, error => {
        console.log(error);
      });
  }
  GetParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }

  PrintParams() {
    console.log('param1 = ' + this.GetParam('param1'));
    console.log('param2 = ' + this.GetParam('param2'));
  }


  sitesettinglist() {
    this.authenticationService.sitesettinglist().subscribe(
      response => {
        if (response['status'] == 200) {
          if (response['siteSettingList'] !== {}) {
            this.sitedata = response['siteSettingList'];
            console.log(this.sitedata[0].basic_price);
            this.gold_price = this.sitedata[0].gold_price;
            this.basic_price = this.sitedata[0].basic_price;

          }
        }
      }, error => {
        console.log(error);
      });
  }
  getallusers() {
    this.authenticationService.userslist().subscribe(
      response => {
        if (response['userList'] !== {}) {
          this.users = response['userList'];
          console.log(this.users);
        }
      }, error => {
        console.log(error);
      });
  }
  addnewPet() {

    // console.log('error');

    this.submitted = true;
    if (this.addPetForm.invalid) {
      //console.log('err ch');

      return false;
    }
    var data = this.addPetForm.value;
    //console.log(data);
    //return false;
    let petdob = data.petdob.year + '-' + data.petdob.month + '-' + data.petdob.day;
    if (data.dateimplanted) {
      this.petimplanted = data.dateimplanted.year + '-' + data.dateimplanted.month + '-' + data.dateimplanted.day;
      //console.log(this.petimplanted);
    }

    var myPostObject = {

      description: data.description,
      name: data.pet_name,
      breed: data.pet_breed,
      // colour: data.pet_color,
      date_injected: this.petimplanted,
      dob: petdob,
      sex: data.sex,
      medical_info: data.pet_medical_info,
      pet_type: data.pet_type,
      user_id: data.user,
      chip_no: data.chipno,
      // for_breeding: data.for_breeding,
      got_from: data.for_breeding,
      neutered: '0',
      got_from_name: data.sellername,
      got_from_address: data.selleraddress

    }

    if (this.user_account == 0) {
      this.isgold_upgrade = true;
      let check_account = localStorage.getItem("new_account");
      if (check_account == "1") {

      } else {
        let el3: HTMLElement = this.myDiv3.nativeElement as HTMLElement;
        el3.click();
      }

      this.authenticationService.adduserPet(myPostObject)
        .pipe(first())
        .subscribe(
          data => {
            //this.show_payment_popup = true;
            this.authenticationService.update_payment(1).subscribe(
              response => {
                localStorage.setItem('show_payment_popup', '1');
                this.authenticationService.unpaid();
                //this.unpaid_payment = this.authenticationService.unpaid_payment_status;
                this.router.navigate(['/home/cart']);
              }, error => {
                console.log(error);
              });
            // localStorage.setItem('show_payment_popup', '1');

            //this.router.navigate(['/home/cart']);
            //window.location.href= "/home/cart";
          },
        );


      return true;
    }
    else {
      this.authenticationService.adduserPet(myPostObject)
        .pipe(first())
        .subscribe(
          data => {
            setTimeout(() => this.toastr.success('Pet added successfully!'))
            this.addPetForm.reset();
            this.submitted = false;
            this.router.navigate(['/home/mypets']);
          },
          error => {
            console.log(error.error.message);
            //This chip does not exist in our database. It may be registered on a different database
            setTimeout(() => this.toastr.error(error.error.message))
            //   this.error = error;
            //   this.loading = false;
          });
      return false;
    }
    this.handler.open({
      name: 'Protected Pet (c)',
      email: this.useremail,
      description: 'Upgrade Account ',
      currency: 'GBP',
      amount: this.isgold_upgrade ? this.gold_price * 100 : this.basic_price * 100,
    });

  }

  updatePet() {
    this.updatesubmitted = true;
    if (this.viewPetForm.invalid) {
      console.log(this.viewPetForm.value);
      console.log('err ch update');

      return false;
    }
    var data = this.viewPetForm.value;
    //return false;
    let petdob = data.petdob.year + '-' + data.petdob.month + '-' + data.petdob.day;

    console.log(this.fileToUpload);


    let input = new FormData();
    input.append('name', data.pet_name);
    input.append('description', data.description);
    input.append('breed', data.pet_breed);
    input.append('colour', data.pet_color);
    input.append('dob', petdob);
    input.append('sex', data.sex);
    input.append('medical_info', data.pet_medical_info);
    input.append('pet_type', data.pet_type);
    input.append('chip_no', data.chipno);
    input.append('for_breeding', data.for_breeding);
    input.append('got_from', data.for_breeding);
    input.append('neutered', '0');
    input.append('got_from_name', data.sellername);
    input.append('got_from_address', data.selleraddress);

    if (this.fileToUpload != undefined) {
      input.append('Image_url', this.fileToUpload);
    }
    //input.append('petid', this.currentPet);

    console.log(input);
    let petid = this.currentPet;
    this.authenticationService.updateuserPet(input, petid)
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => this.toastr.success('Pet updated successfully!'))
          // this.viewPetForm.reset();
          //this.updatesubmitted = false;
        },
        error => {
          console.log(error.error.message);
          //This chip does not exist in our database. It may be registered on a different database
          setTimeout(() => this.toastr.error(error.error.message))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
  }
  getPetTypes() {
    this.authenticationService.getallpettypes().subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['petTypeList'] !== {}) {
          this.pet_types = response['petTypeList'];
          console.log(this.pet_types);

        }
        //}
      }, error => {
        console.log(error);
      });
  }
  getCountries() {
    this.authenticationService.getCountries().subscribe(
      response => {
        if (response['countrieslist'] !== {}) {
          this.countries = response['countriesList'];
          console.log(this.countries);

        }
      }, error => {
        console.log(error);
      });
  }
  getStates(id) {
    this.authenticationService.getStates(id).subscribe(
      response => {
        if (response['stateList'] !== {}) {
          this.states = response['stateList'];
        }
      }, error => {
        console.log(error);
      });
  }
  getCities(id) {
    this.authenticationService.getCities(id).subscribe(
      response => {
        if (response['CitiesList'] !== {}) {
          this.cities = response['CitiesList'];
        }
      }, error => {
        console.log(error);
      });
  }

  addholidayAddress() {
    let id = 1;
    this.submittedholiday = true;
    if (this.holidayAddressForm.invalid) {
      return false;
    }
    var data = this.holidayAddressForm.value;
    var myPostObject = {
      street1: data.address1,
      street2: data.address2,
      country: data.Country,
      county: data.county,
      city: data.city,
      postcode: data.postcode
    }


    this.authenticationService.updateholidayaddress(myPostObject, id)
      .pipe(first())
      .subscribe(
        data => {
          console.log("in status200");
          setTimeout(() => this.toastr.success('Address updated successfully!'), 1000);
          this.submittedholiday = false;
          this.holidayAddressForm.reset();
          this.modalRef.hide()

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
        });
  }

  addchangeownerRequest() {

    let petid = this.currentPet;
    this.submittedowner = true;
    if (this.changeOwnerForm.invalid) {
      return false;
    }
    var data = this.changeOwnerForm.value;

    console.log(data);
    var myPostObject = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.telephone,
      street1: data.address1,
      street2: data.address2,
      country: data.Country,
      county: data.county,
      city: data.city,
      postcode: data.postcode
    }


    this.authenticationService.changeOwner(myPostObject, petid)
      .pipe(first())
      .subscribe(
        data => {
          //  console.log(data.change_owner.id);   
          localStorage.setItem('change_request_id', data.change_owner.id);
          this.changeOwnerForm.reset();
          this.submittedowner = false;
          this.modalRef.hide();
          let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
          el.click();
          // setTimeout(() => this.toastr.success('Request added successfully!'),1000);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
        });
  }
  getPetdetailsfromChip() {
    let userchip = this.chipnumb;
    console.log(userchip);

    if (userchip == null || userchip == '') {
      setTimeout(() => this.toastr.error('Please enter chip number to search!'));
    } else {

      this.authenticationService.getbreederandPetinfo(userchip).subscribe(
        response => {
          //if (response['status'] == 200) { 
          if (response['BreederPetinfo'].length != 0) {
            this.ischipfree = response['BreederPetinfo'][0]['is_free'] ? response['BreederPetinfo'][0]['is_free'] : null;
            var alldata = response['BreederPetinfo'][0]['pet'] ? response['BreederPetinfo'][0]['pet']['name'] : null;
            console.log(alldata);

            if (alldata != null) {
              setTimeout(() => this.toastr.info('Pet is already registered with this chip no!'));
            } else {
              //console.log(response['BreederPetinfo'][0]['pet']['date_injected']);
              // let implant_date = response['BreederPetinfo'][0]['pet']['date_injected'];
              var implant_date = response['BreederPetinfo'][0]['pet'] ? response['BreederPetinfo'][0]['pet']['implant_date'] : null;
              let newdate;
              if (implant_date != null) {
                let implant_array = implant_date.split('-');
                let imp_year = implant_array[0];
                let imp_month = implant_array[1];
                let imp_date = implant_array[2];
                newdate = { year: parseInt(imp_year), month: parseInt(imp_month), day: parseInt(imp_date) };
              }

              console.log(implant_date);

              this.addPetForm.patchValue({ dateimplanted: newdate })
              setTimeout(() => this.toastr.success('Chip is available to register!!'));
            }

          } else {
            setTimeout(() => this.toastr.info('Chip is not registered with any pet!!'));
          }
          //}
        }, error => {
          setTimeout(() => this.toastr.info('Chip is not Avialable in  Database!!'));
        });


    }

  }
  upgradePlan(token) {
    //this.spinner.show();
    var tokenobject = {
      token: token
    }
    this.authenticationService.upgradeSubscription(tokenobject).subscribe(response => {
      console.log(response);
      //this.spinner.hide();
      this.user_account = 1;
      localStorage.setItem('user_account', '1');
      setTimeout(() => this.toastr.success('Plan upgraded successfully!'))
      this.modalRef.hide();
    }, error => {
      this.modalRef.hide();
    });
  }
  openCheckout() {
    let localthis = this;
    this.handler = (<any>window).StripeCheckout.configure({
      image: './../../assets/img/logo.png',
      key: environment.stripeKey,
      locale: 'auto',
      token: function (token: any) {
        console.log(token);
        //this.checkfun();
        localthis.upgradePlan(token);

        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
      }
    });

    this.handler.open({
      name: 'Protected Pet (c)',
      email: this.useremail,
      description: 'Upgrade Account ',
      currency: 'GBP',
      amount: this.isgold_upgrade ? this.gold_price * 100 : this.basic_price * 100,
    });

  }
  open_basic() {

    this.modalRef.hide();
    let el: HTMLElement = this.myDiv2.nativeElement as HTMLElement;
    el.click();
    this.isgold_upgrade = false;
    this.isbasic_upgrade = true;
  }

  setaccount() {
    this.isgold_upgrade = true;
  }

  updateTransferRequest(token) {
    var myPostObject = {
      status: 'complete',
      transaction_id: token.id,
    }
    var change_request_id = localStorage.getItem('change_request_id');
    this.authenticationService.updateTransferRequest(myPostObject, change_request_id)
      .pipe(first())
      .subscribe(
        data => {
          localStorage.removeItem('change_request_id');
          setTimeout(() => this.toastr.success('Owner change request successfully placed!'));

          this.modalRef.hide();
        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
        });
  }

  addtocart(plan) {
    console.log(plan);

    let currency = {
      "code": "GBP",
      "country": "Britain (United Kingdom)",
      "created_at": "2019-03-07 04:45:57",
      "currency": "Pounds",
      "id": 63,
      "status": "1",
      "symbol": "£",
      "updated_at": "-0001-11-30 00:00:00"
    }
    if (plan == 'basic') {
      console.log('hereeeee--basic');
      this.newItem = {
        'product_id': 991,
        'name': 'Basic Plan',
        'quantity': '1',
        'price': '9.95',
        'image': '',
        'currency': currency,
      };
    }
    if (plan == 'gold') {
      console.log('hereeeee--gold');

      this.newItem = {
        'product_id': 992,
        'name': 'Gold Plan',
        'quantity': '1',
        'price': '24.99',
        'image': '',
        'currency': currency,
      };
      console.log(this.newItem);
    }

    console.log(this.newItem);

    this.modalRef.hide();
    this.authenticationService.items.push(this.newItem);
    localStorage.setItem('items', JSON.stringify(this.authenticationService.items));

    this.items = JSON.parse(localStorage.getItem('items'));
    console.log(this.items);

    this.authenticationService.changeMessage(this.authenticationService.items.length);


  }
}
