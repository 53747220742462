import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

function is_url(control: FormControl) {
  let str = control.value;
  let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return null;

  }
  else {
    return { pattern: true };

  }
}
@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit {
  addVendorForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  loading: boolean = false;
  data: any = [];
  modalRef: BsModalRef;
  response;
  p: number = 1;
  total: number;
  showLoader: boolean = true;
  search: string = "";
  isEditVendor: boolean = false;
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.isEditVendor = false;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  get r() { return this.addVendorForm.controls; }

  ngOnInit() {

    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addVendorForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      username: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(10)]],
      url: ['', [Validators.required, is_url]],
      password: ['', Validators.required],
      page: ['', Validators.required],
    });
    this.getVendors(1);
  }

  getVendors(page: any) {
    this.showLoader = true;
    this.authenticationService.getVendors().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
        if (response['vendorList'] !== {}) {
          this.data = response['vendorList'].data;
          this.total = response['vendorList'].total;
          this.response = response['vendorList'];
          this.p = page;

        }
        //}
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }
  deleteVendor(id) {
    if (confirm("Are you sure you want to delete vendor")) {
      this.authenticationService.deleteVendor(id).subscribe(
        response => {
          //this.getUsers();
          if (response['status'] == 200) {
            this.getVendors(1);
            setTimeout(() => this.toastr.success('vendor deleted successfully!'))
          }
        }, error => {
          setTimeout(() => this.toastr.success(error.error.msg))
        });
    }
  }
  logout() {
    this.nav.hide();
    this.authenticationService.logout();
  }

  addvendor() {
    this.submitted = true;
    if (this.addVendorForm.invalid) {
      return false;
    }
    var data = this.addVendorForm.value;
    //return false;
    var myPostObject = {
      name: data.name,
      username: data.username,
      password: data.password,
      phone: data.phone,
      url: data.url,
      page: data.page,
    }
    this.authenticationService.addVendor(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Vendor added successfully!'))
          this.addVendorForm.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.getVendors(1);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error(error.error.msg))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['vendorList'] !== {}) {
          this.data = response['vendorList'].data;
          this.total = response['vendorList'].total;
          this.p = page;

        }
      }, error => {
        console.log(error);
      });
  }

  updateFilter() {
    if (this.search.trim() == "") {
      this.getVendors(1);

    } else {
      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchvendors";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          this.showLoader = false;
          if (response['vendorList'] !== {}) {
            this.data = response['vendorList'].data;
            this.total = response['vendorList'].total;
            this.response = response['vendorList'];
            this.p = 0;

          }
        }, error => {
          console.log(error);
        });
    }
  }
  closePopup(modalRef) {
    this.addVendorForm.reset();
    this.submitted = false;
    modalRef.hide();
  }
  getDetail(id, template: TemplateRef<any>) {
    this.isEditVendor = true;
    let tData = this.data.filter(function (vendor) {
      return vendor.id == id;
    });
    tData = tData[0];
    this.addVendorForm.patchValue({
      "id": tData.id,
      "name": tData.name,
      "username": tData.username,
      "password": tData.password,
      "phone": tData.phone,
      "url": tData.url,
      "page": tData.page
    });
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  updateVendor() {
    var data = this.addVendorForm.value;
    this.authenticationService.updateVendor(data.id, data)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Vendor updated successfully!'))
          this.addVendorForm.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.getPage(this.p);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error(error.error.msg))
          //   this.error = error;
          //   this.loading = false;
        });
  }
}
