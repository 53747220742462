import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-lostfound',
  templateUrl: './lostfound.component.html',
  styleUrls: ['./lostfound.component.scss']
})
export class LostfoundComponent implements OnInit {
  addProductCat: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  data: any = [];
  modalRef: BsModalRef;
  loading: boolean = false;
  response;
  p: number = 1;
  total: number;
  emptyData: boolean = false;
  showLoader: boolean = true;
  dateFormat;
  search: string = "";
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  get r() { return this.addProductCat.controls; }

  ngOnInit() {
    this.dateFormat = localStorage.getItem("date_time_format");
    this.dateFormat = this.dateFormat.replace(/^"(.*)"$/, '$1');
    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addProductCat = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      image_url: ['', Validators.required],
    });
    this.lostfoundlist(1);
  }
  lostfoundlist(page: number) {
    this.showLoader = true;
    this.authenticationService.lostfoundlist().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
        if (response['LostFoundList'].data.length != 0) {
          this.data = response['LostFoundList'].data;
          this.total = response['LostFoundList'].total;
          this.response = response['LostFoundList'];
          this.p = page;
          console.log(this.data);

        } else {
          this.emptyData = true;
        }
        //}
      }, error => {
        console.log(error);
      });
  }
  deleteLostfound(id) {

    if (confirm("Are you sure you want to delete Lost found entry")) {
      this.authenticationService.deleteLostfound(id).subscribe(
        response => {
          //this.getUsers();
          if (response['status'] == 200) {

            setTimeout(() => this.toastr.success('Lost found deleted successfully!'));
            this.lostfoundlist(1);
          }
        }, error => {
          console.log('errorrrrr');

          setTimeout(() => this.toastr.success('Something went wrong'))
        });
    }
  }
  logout() {
    this.nav.hide();
    this.authenticationService.logout();
  }

  addCat() {
    this.submitted = true;
    if (this.addProductCat.invalid) {
      return false;
    }
    var data = this.addProductCat.value;
    //return false;
    var myPostObject = {
      name: data.name,
      description: data.description,
      image_url: data.image_url,
    }
    this.authenticationService.addCategory(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //console.log(data);
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Category added successfully!'))
          this.addProductCat.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.lostfoundlist(1);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  Edituser(id) {
    this.router.navigate(['admin/adduser'], { queryParams: { user_id: id } });
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['LostFoundList'] !== {}) {
          this.data = response['LostFoundList'].data;
          this.total = response['LostFoundList'].total;
          this.p = page;

        }
      }, error => {
        console.log(error);
      });
  }
  updateFilter() {
    if (this.search.trim() == "") {
      this.lostfoundlist(1);

    } else {
      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchLostFound";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          this.showLoader = false;
          if (response['LostFoundList'] !== {}) {
            this.data = response['LostFoundList'].data;
            this.total = response['LostFoundList'].total;
            this.response = response['LostFoundList'];
            this.p = 0;

          }
        }, error => {
          console.log(error);
        });
    }
  }
}
