import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PetTypesComponent } from '../pet-types/pet-types.component';

const now = new Date();
@Component({
  selector: 'app-addchipset',
  templateUrl: './addchipset.component.html',
  styleUrls: ['./addchipset.component.scss']
})
export class AddchipsetComponent implements OnInit {
  config = {
    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 20
  };
  addChipsetForm: FormGroup;
  param2;
  users: any = [];
  options: any = [];
  showLoader: boolean = false;
  pet_types: any = [];
  submitted: Boolean = false;
  constructor(config: NgbTabsetConfig, private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }

  get r() { return this.addChipsetForm.controls; }
  ngOnInit() {
    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      console.log('in null');
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }
    this.addChipsetForm = this.formBuilder.group({
      chipset_owner: ['', Validators.required],
      serial_no: ['', Validators.required],
      no_of_chips: ['', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(8),
      ]],
      type: ['', Validators.required],
      notes: [''],
      hide_from_api: [''],
      chips_free: ['', Validators.required],
      first_chip_no: ['', [Validators.required, Validators.pattern('^[1-9][0-9]+$'), Validators.minLength(15), Validators.maxLength(15)]],
      last_chip_no: ['', Validators.required],
    });
    this.getallusers();
    this.getPetTypes();
  }
  getallusers(term = '') {
    let postData = {
      term: term
    };
    this.showLoader = true;
    this.authenticationService.userslist(postData).subscribe(
      response => {
        if (response['userList'] !== {}) {
          this.users = response['userList'];
          this.options = [];
          this.users.forEach(element => {
            this.options = [...this.options, { 'name': element.firstname + ' ' + element.lastname + ' (' + element.email + ')', 'id': element.id }];

            setTimeout(function () {
              if (document.getElementsByName("search-text").length > 0) {
                document.getElementsByName("search-text")[0]['value'] = term;
              }
            }, 10);
          });
          this.showLoader = false;
        }
      }, error => {
        console.log(error);
      });
  }

  searchChange(data) {
    this.getallusers(data);
  }

  addnewChipset() {
    this.submitted = true;
    if (this.addChipsetForm.invalid) {
      console.log(this.addChipsetForm);
      return false;
    }
    let userid = this.addChipsetForm.value.chipset_owner.id;
    this.addChipsetForm.patchValue({ 'chipset_owner': userid });
    this.authenticationService.addnewChipset(this.addChipsetForm.value)
      .pipe(first())
      .subscribe(
        data => {
          setTimeout(() => this.toastr.success('Chipset added successfully!'))
          this.addChipsetForm.reset();
          this.submitted = false;
        },
        error => {
          setTimeout(() => this.toastr.error(error.error.msg))
        });
  }
  values = '';
  onKeyUp(event: any) {
    let firstchip = event.target.value;
    let totalchips = this.addChipsetForm.value.no_of_chips;
    let newtotalchips = totalchips - 1;
    let lastchip = Number(firstchip) + Number(newtotalchips);
    this.addChipsetForm.patchValue({ last_chip_no: lastchip })
    console.log(lastchip);
  };


  getPetTypes() {
    this.authenticationService.getallpettypes().subscribe(
      response => {
        //if (response['status'] == 200) { 
        if (response['petTypeList'] !== {}) {
          this.pet_types = response['petTypeList'];
        }
        //}
      }, error => {
        console.log(error);
      });
  }
}
