import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';
@Component({
  selector: 'app-pet-types',
  templateUrl: './pet-types.component.html',
  styleUrls: ['./pet-types.component.scss']
})
export class PetTypesComponent implements OnInit {
  petTypeEdit: boolean = false;
  addPetForm: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  param2;
  showLoader: boolean = true;
  //data :any = [];
  modalRef: BsModalRef;
  response;
  loading: boolean = false;
  search: string = "";
  //data :any = [];
  @Input('data') data: string[] = [];
  users: Observable<string[]>;
  p: number = 1;
  total: number;
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.petTypeEdit = false;
    this.addPetForm.reset();
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  get r() { return this.addPetForm.controls; }

  ngOnInit() {

    this.param2 = localStorage.getItem("currentUser");


    if (this.param2 == null) {
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addPetForm = this.formBuilder.group({
      petname: ['', Validators.required],
      petId: ""
    });
    this.getPetTypes(1);
  }
  getPetTypes(page: number) {
    this.showLoader = true;
    this.authenticationService.getPetTypes().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
        if (response['petTypeList'] !== {}) {
          this.data = response['petTypeList'].data;
          this.total = response['petTypeList'].total;
          this.response = response['petTypeList'];
          this.p = page;

        }
        //}
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }
  deletePetType(id) {
    if (confirm("Are you sure you want to delete Pet Type")) {
      this.authenticationService.deletePetType(id).subscribe(
        response => {
          //this.getUsers();
          if (response['status'] == 200) {
            this.getPetTypes(1);
            setTimeout(() => this.toastr.success('Pet deleted successfully!'))
          }
        }, error => {

          setTimeout(() => this.toastr.success(error.error.msg))
        });
    }
  }
  logout() {
    this.nav.hide();
    this.authenticationService.logout();
  }

  addPet() {
    this.submitted = true;
    if (this.addPetForm.invalid) {
      return false;
    }
    var data = this.addPetForm.value;
    //return false;
    var myPostObject = {
      name: data.petname,

    }
    this.authenticationService.addPetname(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Pet type added successfully!'))
          this.addPetForm.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.data = [];
          this.total = 0;
          this.p = 0;
          this.getPetTypes(1);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['petTypeList'] !== {}) {
          this.data = response['petTypeList'].data;
          this.total = response['petTypeList'].total;
          this.p = page;

        }
      }, error => {
        console.log(error);
      });
  }
  editPetType(index, template: TemplateRef<any>) {
    this.petTypeEdit = true;
    let petDetail = this.data[index];
    this.addPetForm.patchValue({ petname: petDetail['name'], petId: petDetail['id'] });
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  editPet() {
    this.submitted = true;
    if (this.addPetForm.invalid) {
      return false;
    }
    var data = this.addPetForm.value;
    //return false;
    var myPostObject = {
      name: data.petname,
      id: data.petId

    }
    let outThis = this;
    this.authenticationService.updatePetname(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Pet type edit successfully!'))
          this.petTypeEdit = false;
          this.addPetForm.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.data = [];
          this.total = 0;
          this.p = 0;
          this.getPetTypes(outThis.p);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  updateFilter() {
    if (this.search.trim() == "") {
      this.getPetTypes(1);

    } else {
      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchPetType";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          this.showLoader = false;
          if (response['petTypeList'] !== {}) {
            this.data = response['petTypeList'].data;
            this.total = response['petTypeList'].total;
            this.response = response['petTypeList'];
            this.p = 0;

          }
        }, error => {
          console.log(error);
        });
    }
  }
  closePopup(modalRef) {
    this.addPetForm.reset();
    this.submitted = false;
    modalRef.hide();
  }
}
