import { Component, OnInit,TemplateRef,ElementRef, ViewChild  } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['../../assets/css/style.css','./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  updatePasswordForm: FormGroup;
  formsubmitted: boolean = false;
  constructor(private formBuilder: FormBuilder,private modalService: BsModalService,private toastr: ToastrService,public nav: NavbarService,private authenticationService: AuthenticationService,private router: Router) { }
  get f() { return this.updatePasswordForm.controls; }

  ngOnInit() {
    this.updatePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }); 
  }

  onSubmit() {
    this.formsubmitted = true;
    if (this.updatePasswordForm.invalid) {
      return;
    }
    var data = this.updatePasswordForm.value;


    var myPostObject = {
      current_password : data.password,
      new_password :data.newPassword,
      new_password_confirmation :data.confirmPassword,
    }      
      this.authenticationService.userchangepassword(myPostObject)
      .pipe(first())
      .subscribe(
          data => {
            console.log(data);
           setTimeout(() => this.toastr.success('Password updated Successfully')
            );
           this.updatePasswordForm.reset();
             this.formsubmitted = false;
          },
          error => {
            console.log(error.error);
            
            setTimeout(() => this.toastr.error(error.error.msg, 'Oops!'));

          });        
  }  
}
