import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homelayout',
  templateUrl: './homelayout.component.html',
  // styleUrls: ['./homelayout.component.scss']
  styleUrls: ['../../assets/css/style.css']
})

export class HomelayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
