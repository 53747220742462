import { Component, OnInit, TemplateRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { NavbarService } from '../services/navbar.service';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-changerequest',
  templateUrl: './changerequest.component.html',
  styleUrls: ['./changerequest.component.scss']
})
export class ChangerequestComponent implements OnInit {
  //requestownerlist;
  addProductCat: FormGroup;
  submitted: boolean = false;
  usersubmitted: boolean = false;
  loginsubmitted: boolean = false;
  invalidLogin: boolean = false;
  showLoader: boolean = true;
  emptyData: boolean = false;
  param2;
  data: any = [];
  modalRef: BsModalRef;
  loading: boolean = false;
  response;
  p: number = 1;
  dateFormat;
  total: number;
  search: string = "";
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, public nav: NavbarService, private authenticationService: AuthenticationService, private router: Router) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }
  get r() { return this.addProductCat.controls; }

  ngOnInit() {

    this.dateFormat = localStorage.getItem("date_time_format");
    this.dateFormat = this.dateFormat.replace(/^"(.*)"$/, '$1');
    this.param2 = localStorage.getItem("currentUser");

    if (this.param2 == null) {
      console.log('in null');
      this.router.navigate(['/login']);
    } else {
      this.nav.show();
    }

    this.addProductCat = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      image_url: ['', Validators.required],
    });
    this.requestownerlist(1);
  }
  requestownerlist(page: number) {
    this.showLoader = true;
    this.authenticationService.requestownerlist().subscribe(
      response => {
        this.showLoader = false;
        //if (response['status'] == 200) { 
        if (response['requestOwnerList'] !== {}) {
          this.data = response['requestOwnerList'].data;
          this.total = response['requestOwnerList'].total;
          this.response = response['requestOwnerList'];
          this.p = page;
          if (this.data.length == 0) {
            this.emptyData = true;
          }
          console.log(this.data);

        }
        //}
      }, error => {
        setTimeout(() => this.toastr.error(error.error.message))
        console.log(error);
        if (error.error.message == "Unauthenticated") {
          localStorage.clear();
          this.authenticationService.loggedOut();
          this.router.navigate(['/login']);
        }
      });
  }
  approove(id) {
    var myPostObject = {
    }
    this.authenticationService.requestownerapprove(id)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          setTimeout(() => this.toastr.success('approoved successfully!'))
          this.requestownerlist(1);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  disapproove(id) {
    var myPostObject = {
    }
    this.authenticationService.requestownerdecline(id)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          setTimeout(() => this.toastr.success('disapprooved successfully!'))
          this.requestownerlist(1);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }
  logout() {
    console.log('inlogout');
    this.nav.hide();
    this.authenticationService.logout();
  }

  addCat() {
    this.submitted = true;
    if (this.addProductCat.invalid) {
      return false;
    }
    var data = this.addProductCat.value;
    //return false;
    var myPostObject = {
      name: data.name,
      description: data.description,
      image_url: data.image_url,
    }
    this.authenticationService.addCategory(myPostObject)
      .pipe(first())
      .subscribe(
        data => {
          //console.log(data);
          //this.saveNotification.reset
          setTimeout(() => this.toastr.success('Category added successfully!'))
          this.addProductCat.reset();
          this.submitted = false;
          this.modalRef.hide()
          this.requestownerlist(1);
          //this.router.navigate(['/dashboard']);

        },
        error => {
          setTimeout(() => this.toastr.error('Something went wrong!'))
          //   this.error = error;
          //   this.loading = false;
        });
  }

  getPage(page: number) {
    this.loading = true;
    let url = this.response.first_page_url.split("page=");
    url = url[0] + "&page=" + page;
    this.authenticationService.getalluserspagination(url).subscribe(
      response => {
        if (response['requestOwnerList'] !== {}) {
          this.data = response['requestOwnerList'].data;
          this.total = response['requestOwnerList'].total;
          this.p = page;
          console.log(this.data);

        }
      }, error => {
        console.log(error);
      });
  }
  Edituser(id) {
    this.router.navigate(['admin/adduser'], { queryParams: { user_id: id } });
  }

  updateFilter() {
    if (this.search.trim() == "") {
      this.requestownerlist(1);

    } else {
      this.showLoader = true;
      this.data = [];
      this.total = 0;
      this.p = 0;
      let api = "searchrequestowners";
      this.authenticationService.searchByQueryString(api, this.search).subscribe(
        response => {
          this.showLoader = false;
          if (response['requestOwnerList'] !== {}) {
            this.data = response['requestOwnerList'].data;
            this.total = response['requestOwnerList'].total;
            this.response = response['requestOwnerList'];
            this.p = 0;
            if (this.data.length == 0) {
              this.emptyData = true;
            }
          }
        }, error => {
          console.log(error);
        });
    }
  }
}
