import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NavbarService } from '../services/navbar.service';
import {AuthenticationService} from '../services/authentication.service'
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public sidebarOpened = false;
  $:any;
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    }
    else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }
  constructor(private toastr: ToastrService,private activatedRoute: ActivatedRoute,private authenticationService: AuthenticationService,config: NgbDropdownConfig,public nav: NavbarService,private router: Router) {
    config.placement = 'bottom-right';
  }
  ngOnInit() {
    this.router.events
    .filter(event => event instanceof NavigationEnd)
    .map(() => this.activatedRoute)
    .subscribe((event) => {

    });
  }
  logout(){
    setTimeout(() => this.toastr.success('Logged Out Successfully')
            );
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user_type');
    localStorage.removeItem('userid');
    this.authenticationService.loggedOut();
    this.router.navigate(['/login']);
  }
}
